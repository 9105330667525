import {
  Instance, resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { Product } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Product';
import { GroupModifier } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/GroupModifier';
import { TimeLimit } from '@app/domain/store/CoreStore/AppStore/entities/Menu/TimeLimit';

export const MenuItem = t
  .model('MenuItem', {
    id: t.identifier,
    productId: t.string,
    isAvailable: t.boolean,
    price: t.refinement(t.number, (value) => value >= 0),
    groupModifiers: t.optional(t.array(GroupModifier), []),
    timeLimit: t.maybeNull(TimeLimit),
  })
  .views((self) => ({
    get product() {
      return resolveIdentifier(Product, self, self.productId);
    },
  }))
  .views((self) => ({
    get hasModifiers() {
      return self.groupModifiers.length > 0;
    },
    // Покрывает кейс когда групповой модификатор имеет минимальное кол-во,
    // но его модификаторов нет в меню, тогда надо запретить покупку товара.
    get groupModifiersIsCorrect() {
      let gmIsCorrect = true;
      self.groupModifiers.reduce((map, gm) => {
        gm.childModifiers.forEach((cm) => {
          const value = Math.max(cm.minAmount, cm.defaultAmount);
          if (value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            map[cm.modifierId] = {
              modifierId: cm.modifierId,
              amount: value,
            };
          }
        });

        const amountInGroup = gm.childModifiers
          .reduce((sum, cm) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const cmAmount = map[cm.modifierId]?.amount || 0;

            return sum + cmAmount;
          }, 0);

        // TODO: Не учитывается куча кейсов, смотрим пока на базу Академии.
        // Если выставлено минимальное кол-во для группы, но не насчиталось модификаторов, то выберем первый попавшийся.
        if (gm.minAmount === 1 && amountInGroup === 0) {
          if (gm.childModifiers.length === 0) {
            // TODO: Обязательного модификатора нет, отмечаем товар как недоступный
            gmIsCorrect = false;
            return map;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map[gm.childModifiers[0].modifierId] = {
            modifierId: gm.childModifiers[0].modifierId,
            amount: 1,
          };
        }

        return map;
      }, {});

      return gmIsCorrect;
    },
  }));

export interface MenuItemInstance extends Instance<typeof MenuItem> {
}

export interface MenuItemSnapshotIn extends SnapshotIn<typeof MenuItem> {
}

export interface MenuItemSnapshotOut extends SnapshotOut<typeof MenuItem> {
}
