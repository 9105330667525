import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  View, Text, StyleSheet, ScrollView,
  Pressable, ActivityIndicator, Linking,
} from 'react-native';
import {
  Enum_Paymenttype_Paymenttypecode,
  useGetMyFavoritesQuery,
  useGetMyOrdersQuery,
} from '@app/infrastructureLayer/generated/graphql';
import { RootStackScreenProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import getOrderCookingStatusName from '@utils/mappers/getOrderCookingStatusName';
import DeliveryAddressTypeSvg from '@svg/DeliveryAddressTypeSvg';
import getOrderStatusName from '@utils/mappers/getOrderStatusName';
import getOrderParamName from '@utils/mappers/getOrderParamName';
import OrderInfoBanner from '@components/OrderInfoBanner/OrderInfoBanner';
import OrderInfosRow from '@screens/OrderScreen/includes/OrderInfosRow';
import ButtonOutline from '@components/common/ButtonOutline';
import generalConfig from '@constants/generalConfig';
import ubuntuFont from '@constants/ubuntuFont';
import DashedLine from '@components/common/DashedLine';
import crossAlert from '@utils/crossAlert';
import OrderItem from '@screens/OrderScreen/includes/OrderItem';
import useStore from '@app/domain/store/useStore';
import Screen from '@components/layout/Screen';
import colors from '@constants/colors';
import moment from 'moment/moment';
import Back from '@components/layout/Back';

const s = StyleSheet.create({
  scroll: {
    paddingHorizontal: 15,
  },
  header1: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    marginTop: 10,
  },
  header2: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    marginBottom: 15,
  },
  infoRows: {
    gap: 5,
    paddingBottom: 10,
    backgroundColor: colors.lightGrey,
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
  },
  infoRow: {
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // flex: 1,
  },
  infoRowText: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
  },
  infoRowTextPhone: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
  },
  action: {
    backgroundColor: colors.lightGrey,
    height: 60,
    borderRadius: 10,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftSide: {
    rowGap: 5,
  },
  title: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
  },
  date: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  workIconWrapper: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
    borderRadius: 10,
  },
  infoBox: {
    marginTop: 15,
    justifyContent: 'space-between',
  },
  buttons: {
    marginTop: 15,
    marginBottom: 20,
  },
  flex: {
    gap: 15,
  },
  paymentRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  totalPrice: {
    fontFamily: ubuntuFont.regular,
    fontSize: 20,
    color: colors.text,
  },
  scrollContentRoot: {
    // flex: 1,
  },
  scrollOrdersRoot: {
    flexGrow: 1,
  },
  scrollOrders: {
    flexGrow: 1,
  },
  total: {
    gap: 10,
    marginTop: 15,
  },
  result: {
    backgroundColor: colors.lightGrey,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginTop: 10,
    paddingVertical: 5,
  },
});

const OrderScreen: FC<RootStackScreenProps<'OrderScreen'>> = (
  {
    route,
    navigation: localNavigation,
  },
) => {
  const {
    appStore: {
      globalSettings,
    },
  } = useStore();

  const isHotelMode = globalSettings?.isHotelMode || false;

  const orderId = route.params?.orderId;

  useEffect(() => {
    if (!orderId) {
      localNavigation.replace('OrdersHistoryScreen');
    }
  }, [orderId, localNavigation]);

  const navigation = useNavigation();

  const [needExtraPolling, setNeedExtraPolling] = useState(false);

  const {
    data,
    loading,
  } = useGetMyOrdersQuery({
    variables: {
      filters: {
        id: {
          eq: orderId,
        },
      },
    },
    // Раз в 1 сек переполучаем данные чтобы на странице статус заказа показывался всегда актуальный
    pollInterval: needExtraPolling ? 1000 : undefined,
  });

  const { data: favoritesData, refetch: refetchFavorites } = useGetMyFavoritesQuery({
    fetchPolicy: 'no-cache',
  });

  const favorites = favoritesData?.myFavorites.data || [];

  const order = data?.myOrders.data[0];

  useEffect(() => {
    if (order && order.paymentType?.paymentTypeCode === 'CARD') {
      if (!order.billUrl || order.status !== 'PAID') {
        setNeedExtraPolling(true);

        return;
      }
    }

    setNeedExtraPolling(false);
  }, [order]);

  useEffect(() => {
    if (data && !order) {
      crossAlert('Такого заказа нет');
      localNavigation.replace('OrdersHistoryScreen');
    }
  }, [data, localNavigation, order]);

  const onGetBillPress = useCallback(() => {
    if (order?.billUrl) {
      Linking.openURL(order.billUrl);
    }
  }, [order?.billUrl]);

  const onPressCallToShop = useCallback(() => {
    if (order?.shop?.phoneForClients) {
      Linking.openURL(`tel:${order?.shop?.phoneForClients}`);
    }
  }, [order?.shop?.phoneForClients]);

  // const onRepeatOrderPress = useCallback(() => {
  //   // TODO: Сделать повторение заказа
  // }, []);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }
    navigation.navigate('IndexScreen');
  }, [navigation]);

  // const onCancelOrder = useCallback(() => {
  //   // TODO: Сделать отмену заказа
  // }, []);

  const productImagesUrl = useMemo(() => (
    order?.orderItems
      ?.map((item) => item.snapshot.product.imageUrl_100
        || item.snapshot.product.imageUrl) || []
  ), [order]);

  const orderStatusName = useMemo(() => {
    if (order?.orderCookingStatus === null || typeof order?.orderCookingStatus === 'undefined') {
      return null;
    }

    return getOrderCookingStatusName(order.orderCookingStatus);
  }, [order?.orderCookingStatus]);

  const onPhonePress = useCallback(() => {
    if (!order || !order.shop || !order.shop.phoneForClients) {
      return;
    }

    Linking.openURL(`tel:${order.shop.phoneForClients}`);
  }, [order]);

  return (
    <Screen>
      <Back
        title="Назад"
        onPress={onBack}
        // onDelete={onCancelOrder}
        // onDeleteTitle="Отменить"
      />
      <ScrollView
        contentContainerStyle={s.scrollContentRoot}
        style={s.scrollOrdersRoot}
      >
        <View style={s.scroll}>
          {loading && (
            <ActivityIndicator
              color={colors.text}
            />
          )}

          {!!order && (
            <>
              {order.status !== 'CANCELED'
                && order.orderCookingStatus !== null
                && typeof order.orderCookingStatus !== 'undefined'
                && (
                  <OrderInfoBanner
                    orderCookingStatus={order.orderCookingStatus}
                    productImagesUrl={productImagesUrl}
                    orderId={order.id}
                  />
                )}
              <Text style={s.header1}>
                {`№ ${order.id} (${order.orderType?.title})`}
              </Text>
              <Text style={s.header2}>
                {'\n'}
                {`${moment(order.date)
                  .format('DD.MM.YYYY')} к ${moment(order.time, 'HH:mm:ss')
                  .format('HH:mm')}`}
              </Text>

              <View style={s.infoRows}>
                {order.params?.map((param) => (
                  <Text style={s.infoRow} key={param.id}>
                    {`${getOrderParamName(param.type)}: ${param.value}`}
                  </Text>
                ))}
                <View style={s.infoRow}>
                  <Text style={s.infoRowText}>Статус оплаты</Text>
                  <Text style={s.infoRowText}>{getOrderStatusName(order.status)}</Text>
                </View>
                {!!orderStatusName && (
                  <View style={s.infoRow}>
                    <Text style={s.infoRowText}>Статус заказа</Text>
                    <Text style={s.infoRowText}>{orderStatusName}</Text>
                  </View>
                )}
                <View style={s.infoRow}>
                  <Text style={s.infoRowText}>Способ оплаты</Text>
                  <Text style={s.infoRowText}>{order.paymentType?.title}</Text>
                </View>
                {!isHotelMode && (
                  <>
                    <DashedLine />
                    <View style={s.infoRow}>
                      <Text style={s.infoRowText}>
                        {`Адрес\u00A0ресторана: ${order.shop?.title}, ${order.shop?.city?.title}, ${order.shop?.address}`}
                      </Text>
                    </View>
                    {!!order?.shop?.phoneForClients && (
                      <Pressable onPress={onPressCallToShop}>
                        <View style={s.infoRow}>
                          <Text style={s.infoRowText}>
                            {'Телефон\u00A0ресторана: '}
                            <Text style={s.infoRowTextPhone} onPress={onPhonePress}>
                              {order.shop.phoneForClients}
                            </Text>
                          </Text>
                        </View>
                      </Pressable>
                    )}
                  </>
                )}
                {isHotelMode && (
                  <Text style={s.infoRow}>
                    {order.shop?.title}
                  </Text>
                )}
              </View>

              <View style={s.scrollOrders}>
                {!!order.deliveryAddressSnapshot && (
                  <View style={s.action}>
                    <View style={s.row}>
                      <View style={s.workIconWrapper}>
                        <DeliveryAddressTypeSvg type={order.deliveryAddressSnapshot.type} />
                      </View>
                      <View style={s.leftSide}>
                        {!!order.deliveryAddressSnapshot.title && (
                          <Text style={s.date}>{order.deliveryAddressSnapshot.title}</Text>
                        )}
                        <Text style={s.title}>{order.deliveryAddressSnapshot.address}</Text>
                      </View>
                    </View>
                  </View>
                )}

                <View style={s.infoBox}>
                  <View style={s.flex}>

                    {order.orderItems?.map((item) => (
                      <OrderItem
                        key={item.id}
                        item={item}
                        favorites={favorites}
                        onRefetchFavorites={refetchFavorites}
                      />
                    ))}
                  </View>
                  <View style={s.result}>
                    <View style={s.total}>
                      <OrderInfosRow title="Заказ на сумму" price={order.totalItemsCost} />
                      {order.itemsDiscount > 0 && (
                        <OrderInfosRow
                          title="Скидка"
                          price={-order.itemsDiscount}
                          color={colors.primary}
                        />
                      )}
                      {order.deliveryCost > 0 && (
                        <OrderInfosRow
                          title="Доставка"
                          price={order.deliveryCost}
                        />
                      )}
                      {!!order.bonuses && (
                        <OrderInfosRow
                          title="Использованные бонусы"
                          price={-order.bonuses}
                          color={colors.primary}
                        />
                      )}
                      <DashedLine />
                    </View>
                    <View style={s.paymentRow}>
                      <Text style={s.totalPrice}>ИТОГО</Text>
                      <Text style={s.totalPrice}>
                        {order.total}
                        {' '}
                        {generalConfig.currencyLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={s.buttons}>
                {order.paymentType?.paymentTypeCode === Enum_Paymenttype_Paymenttypecode.Card && (
                  <ButtonOutline
                    title={order.billUrl ? 'СКАЧАТЬ ЧЕК' : 'ЧЕК СКОРО БУДЕТ ДОСТУПЕН'}
                    onPress={onGetBillPress}
                  />
                )}
                {/* <Button */}
                {/*   title="ПОВТОРИТЬ ЗАКАЗ" */}
                {/*   onPress={onRepeatOrderPress} */}
                {/* /> */}
              </View>
            </>
          )}
        </View>
      </ScrollView>
    </Screen>
  );
};

export default observer(OrderScreen);
