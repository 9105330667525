import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import React, { FC, useCallback, useMemo } from 'react';
import Back from '@components/layout/Back';
import Button from '@components/common/Button';
import Screen from '@components/layout/Screen';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

import CreditCard from '@components/CreditCard';
import { useDeleteMyCardMutation, useGetMyCreditCardsQuery } from '@app/infrastructureLayer/generated/graphql';
import compact from 'lodash/compact';
import { SheetManager } from 'react-native-actions-sheet';
import { RootStackScreenProps } from '@navigation/types';
import BinaryAlert from '@utils/BinaryAlert';

const s = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  scrollContent: {
    flex: 1,
  },
  cards: {
    gap: 10,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    marginBottom: 15,
    marginTop: 5,
  },
});

const MyCardsScreen: FC<RootStackScreenProps<'MyCardsScreen'>> = () => {
  const {
    data,
    refetch,
    loading,
  } = useGetMyCreditCardsQuery({
    pollInterval: 1000,
  });
  const [deleteMyCard, { loading: deletePending }] = useDeleteMyCardMutation();

  const cards = useMemo(() => compact(data?.myCreditCards?.data.map(
    (card) => {
      console.info('card: ', card);
      return (
        {
          id: card.id,
          maskedPan: card.maskedPan,
          expiryDate: card.expiryDate,
        }
      );
    },
  )), [data?.myCreditCards?.data]);

  const onCardPress = useCallback((cardId: string) => () => {
    BinaryAlert({
      title: 'Удалить карту?',
      desc: 'Восстановить карту можно будет только повторно добавив её',
      onPositivePress: () => {
        deleteMyCard({
          variables: {
            bindingId: cardId,
          },
          onCompleted: async () => {
            await refetch();
          },
          onError: async () => {
            await refetch();
          },
        });
      },
      onNegativePress: () => {
      },
    });
  }, [deleteMyCard, refetch]);

  const onCardAddPress = useCallback(() => {
    SheetManager.show('add-new-card');
  }, []);

  return (
    <Screen>
      <Back title="Назад" />
      <View style={s.root}>
        <ScrollView contentContainerStyle={s.scrollContent}>
          <Text style={s.header}>Мои Карты</Text>
          {(loading || deletePending) && <ActivityIndicator color={colors.text} />}
          {(!loading && !deletePending) && (
            <View style={s.cards}>
              {cards.length === 0 && (
                <Text>У вас нет сохранённых карт</Text>
              )}
              {cards.map((card) => (
                <Pressable onPress={onCardPress(card.id)} key={card.id}>
                  <CreditCard
                    useDelete
                    maskedPan={card.maskedPan}
                    expiryDate={card.expiryDate}
                  />
                </Pressable>
              ))}
            </View>
          )}
        </ScrollView>
        <Button
          title="Добавить новую карту"
          onPress={onCardAddPress}
        />
      </View>
    </Screen>
  );
};

export default MyCardsScreen;
