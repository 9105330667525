import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';
import React, { FC, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import ModifierRow from '@screens/MenuItemScreen/includes/Modifier/ModifierRow';

interface IProps {
  menuItemId: string,
}

const s = StyleSheet.create({
  mainHeader: {
    fontFamily: ubuntuFont.medium,
    color: colors.text,
    fontSize: 14,
    marginBottom: 5,
  },
  root: {
    gap: 20,
  },
  itemRow: {
    gap: 5,
  },
});

const ModifierBlock: FC<IProps> = (
  {
    menuItemId,
  },
) => {
  const {
    appStore: {
      activeMenu,
      getModifierIsAvailableInActiveMenuByModifierId,
    },
  } = useStore();

  const menuItem = activeMenu?.menuItems.get(menuItemId);

  const gmReplace = useMemo(() => {
    if (!menuItem) {
      return [];
    }

    return menuItem.groupModifiers
      .filter((gm) => gm.isOneOf && gm.minAmount > 0)
      .filter((gm) => {
        const cmSize = gm.childModifiers.filter(
          (cm) => !!cm.modifier?.id && getModifierIsAvailableInActiveMenuByModifierId(cm.modifier.id),
        ).length;

        // debugger;
        return cmSize !== 0;
      });
  }, [menuItem, getModifierIsAvailableInActiveMenuByModifierId]);

  const gmOptionalSwitches = useMemo(() => {
    if (!menuItem) {
      return [];
    }

    return menuItem.groupModifiers
      .filter((gm) => gm.isOneOf && gm.minAmount === 0)
      .filter((gm) => {
        const cmSize = gm.childModifiers.filter(
          (cm) => !!cm.modifier?.id && getModifierIsAvailableInActiveMenuByModifierId(cm.modifier.id),
        ).length;

        // debugger;
        return cmSize !== 0;
      });
  }, [menuItem, getModifierIsAvailableInActiveMenuByModifierId]);

  const gmOther = useMemo(() => {
    if (!menuItem) {
      return [];
    }

    return menuItem.groupModifiers
      .filter((gm) => !gm.isOneOf)
      .filter((gm) => {
        const cmSize = gm.childModifiers.filter(
          (cm) => !!cm.modifier?.id && getModifierIsAvailableInActiveMenuByModifierId(cm.modifier.id),
        ).length;

        // debugger;
        return cmSize !== 0;
      });
  }, [menuItem, getModifierIsAvailableInActiveMenuByModifierId]);

  if (!menuItem || menuItem.groupModifiers.length === 0) {
    return null;
  }

  return (
    <View style={s.root}>
      {gmReplace.length > 0 && (
        <View style={s.itemRow}>
          <Text style={s.mainHeader}>Можно заменить</Text>
          {gmReplace.map((gm) => (
            <ModifierRow
              mode="switch"
              gm={gm}
              key={gm.id}
            />
          ))}
        </View>
      )}
      {(gmOther.length > 0 || gmOptionalSwitches.length > 0) && (
        <View style={s.itemRow}>
          <Text style={s.mainHeader}>Можно добавить</Text>
          {gmOptionalSwitches.map((gm) => (
            <ModifierRow
              mode="add"
              gm={gm}
              key={gm.id}
            />
          ))}
          {gmOther.map((gm) => (
            <ModifierRow
              mode="add"
              gm={gm}
              key={gm.id}
            />
          ))}
        </View>
      )}
    </View>
  );
};

export default observer(ModifierBlock);
