import React, {
  FC,
  useCallback, useEffect,
} from 'react';
import {
  View, StyleSheet, Text, ScrollView, ActivityIndicator,
} from 'react-native';
import { useDeleteMyFavoriteItemMutation, useGetMyFavoritesQuery } from '@app/infrastructureLayer/generated/graphql';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { TabsScreenProps } from '@navigation/types';
import { observer } from 'mobx-react-lite';
import ubuntuFont from '@constants/ubuntuFont';
import TopBlock from '@screens/Tabs/MenuScreen/includes/TopBlock';
import useStore from '@app/domain/store/useStore';
import Screen from '@components/layout/Screen';
import Button from '@components/common/Button';
import colors from '@constants/colors';
import useShopIdSync from '@hooks/useShopIdSync';
import { computed } from 'mobx';
import FavoriteItem from '@screens/Tabs/includes/FavoriteItem';

const s = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: colors.background,
    paddingBottom: 30,
  },
  content: {
    flex: 1,
    paddingHorizontal: 15,
    paddingVertical: 20,
    rowGap: 15,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    marginBottom: 15,
    marginHorizontal: 15,
    marginTop: 5,
  },
  emptyTextView: {
    flex: 1,
    rowGap: 15,
    paddingHorizontal: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyListHeader: {
    fontFamily: ubuntuFont.regular,
    fontSize: 24,
    color: colors.text,
    textAlign: 'center',
  },
  emptyListDesc: {
    fontFamily: ubuntuFont.light,
    fontSize: 16,
    color: colors.text,
    textAlign: 'center',
  },
  cartBtn: {
    paddingTop: 10,
    paddingBottom: 16,
    paddingHorizontal: 15,
  },
});

const FavoritesScreen: FC<TabsScreenProps<'FavoritesScreen'>> = (
  {
    route,
    navigation: localNavigation,
  },
) => {
  const navigation = useNavigation();

  const {
    authStore: {
      auth: {
        hasAuthToken,
      },
    },
    appStore: {
      activeMenu,
      modifiers,
      products,
      selectedShopId,
    },
  } = useStore();

  useShopIdSync(route.params?.shopId || selectedShopId || undefined);

  const isActiveMenuReady = activeMenu?.ready || false;

  const {
    data,
    loading,
    refetch,
  } = useGetMyFavoritesQuery({
    fetchPolicy: 'no-cache',
    skip: !hasAuthToken,
  });

  const [deleteMyFavoriteItem] = useDeleteMyFavoriteItemMutation();

  useFocusEffect(useCallback(() => {
    (async () => refetch())();
  }, [refetch]));

  const list = computed(() => {
    if (!data?.myFavorites || data.myFavorites.resultCode === 'FAIL') {
      return [];
    }

    return data.myFavorites.data.filter((item) => {
      const hasProduct = products.has(item.productId);

      if (!hasProduct) {
        return hasProduct;
      }

      if (item.modifiersData.length === 0) {
        return hasProduct;
      }

      const hasUnknownModifiers = item.modifiersData
        .some((modifierItem) => !modifiers.has(modifierItem.modifierId));

      return !hasUnknownModifiers;
    });
  }).get();

  const shopId = selectedShopId;
  const isNotEmpty = Boolean(list.length);

  const onNavigateToHistory = useCallback(() => {
    navigation.navigate('OrdersHistoryScreen');
  }, [navigation]);

  const onNavigateToCart = useCallback(() => {
    if (!shopId) {
      return;
    }

    navigation.navigate('CartScreen', {
      shopId,
    });
  }, [navigation, shopId]);

  const onNavigateToProduct = useCallback((id: string) => {
    if (!shopId) {
      return;
    }

    navigation.navigate('MenuItemScreen', {
      menuItemId: id,
      shopId,
    });
  }, [navigation, shopId]);

  const onRemoveFromList = useCallback((id: string) => async () => {
    await deleteMyFavoriteItem({ variables: { id } });
    await refetch();
  }, [deleteMyFavoriteItem, refetch]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!hasAuthToken && isFocused) {
      localNavigation.replace('Auth', { screen: 'LoginScreen' });
    }
  }, [hasAuthToken, isFocused, localNavigation]);

  if (loading || !isActiveMenuReady) {
    return (
      <Screen inTabs>
        <View style={s.container}>
          <TopBlock hideLastOrder />
          <Text style={s.header}>Избранное</Text>
          <View style={s.emptyTextView}>
            <ActivityIndicator size={24} color={colors.primary} />
          </View>
        </View>
      </Screen>
    );
  }

  if (isNotEmpty) {
    return (
      <Screen inTabs>
        <ScrollView contentContainerStyle={s.container}>
          <TopBlock hideLastOrder />
          <Text style={s.header}>Избранное</Text>

          <View style={s.content}>
            {list.map((item) => (
              <FavoriteItem
                key={`favorite-${item.id}`}
                item={item}
                isFavorite
                onItemPress={onNavigateToProduct}
                onHeartPress={onRemoveFromList(item.id)}
              />
            ))}
          </View>
        </ScrollView>

        <View style={s.cartBtn}>
          <Button
            title="ПЕРЕЙТИ В КОРЗИНУ"
            onPress={onNavigateToCart}
          />
        </View>
      </Screen>
    );
  }

  return (
    <Screen inTabs>
      <View style={s.container}>
        <TopBlock hideLastOrder />
        <Text style={s.header}>Избранное</Text>

        <View style={s.emptyTextView}>
          <Text style={s.emptyListHeader}>Нет избранного</Text>
          <Text style={s.emptyListDesc}>
            {'Добавляйте сюда свои любимые блюда\nи напитки, чтобы делать заказ быстрее'}
          </Text>
          <Button
            title="ПЕРЕЙТИ К ИСТОРИИ ЗАКАЗОВ"
            onPress={onNavigateToHistory}
          />
        </View>
      </View>
    </Screen>
  );
};

export default observer(FavoritesScreen);
