// const primaryColor = '#79B5B6';
import { getCustomConfig } from '@utils/env';

const primaryColor = getCustomConfig().PRIMARY_COLOR;

const colors = {
  background: '#fff',
  categoriesBackground: 'rgba(255,255,255,0.70)',

  primary: primaryColor,

  text: '#0F0C0B',
  white: '#fff',
  grey: '#969696',
  darkGrey: '#2B353C',
  midGrey: '#ECECEC',
  lightGrey: '#F5F5F5',
  error: '#BB0000',
  tabBar: {
    bg: '#ECECEC',
    tabBarInactiveTintColor: '#969696',
    tabBarActiveTintColor: '#0F0C0B',
  },
  button: {
    bg: primaryColor,
    bgDisabled: '#969696',
    text: '#fff',
  },
  switch: {
    bg: '#fff',
    selected: {
      bg: primaryColor,
      text: '#fff',
    },
    notSelected: {
      bg: '#fff',
      text: '#969696',
    },
  },
  switch2: {
    iosBg: '#fff',
    trackColor: {
      true: primaryColor,
      false: '#969696',
    },
    thumbColor: {
      true: '#fff',
      false: '#F5F5F5',
    },
  },
  transparent: 'transparent',
};

export default colors;
