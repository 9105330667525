import React, {
  FC,
} from 'react';
import {
  StyleSheet,
  View,
  Text, ActivityIndicator,
} from 'react-native';
import ActionSheet, {
  SheetProps,
} from 'react-native-actions-sheet';
import { observer } from 'mobx-react-lite';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 80,
  },
  text: {
    fontSize: 20,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    textAlign: 'center',
    marginBottom: 40,
  },
});

const snapPoints = [100];

const OrderCreatePendingSheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => (
  <ActionSheet
    id={sheetId}
    snapPoints={snapPoints}
    initialSnapIndex={0}
    statusBarTranslucent
    drawUnderStatusBar
    gestureEnabled
    defaultOverlayOpacity={0.3}
  >
    <View style={s.root}>
      <Text style={s.text}>
        Идёт оформление заказа
      </Text>
      <ActivityIndicator
        color={colors.primary}
        size={50}
      />
    </View>
  </ActionSheet>
);

export default observer(OrderCreatePendingSheet);
