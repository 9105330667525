import React, {
  FC,
  useCallback,
  useEffect,
  useMemo, useRef,
} from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
  Pressable,
  Platform,
} from 'react-native';
import { RootStackScreenProps } from '@navigation/types';
import { observer } from 'mobx-react-lite';
import FullScreenLoading from '@components/common/FullScreenLoading';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import useStore from '@app/domain/store/useStore';
import Screen from '@components/layout/Screen';
import Back from '@components/layout/Back';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import Picture from '@components/common/Picture/Picture';
import CartIconSvg from '@svg/CartIconSvg';
import CartButton from '@screens/MenuItemScreen/includes/CartButton';
import ProductProperties from '@screens/MenuItemScreen/includes/ProductProperties/ProductProperties';
import ProductVolumeOrWeight from '@screens/MenuItemScreen/includes/ProductProperties/ProductVolume';
import { EnumProductParamCalculationType } from '@app/domain/store/CoreStore/AppStore/entities/Menu/BaseItemWithParams';
import ModifierBlock from '@screens/MenuItemScreen/includes/Modifier/ModifierBlock';
import { ModifiersStateModelContext } from '@screens/MenuItemScreen/model/ModifiersStateModelContext';
import { ModifiersStateModel, ModifiersStateModelInstance } from '@screens/MenuItemScreen/model/ModifiersStateModel';
import { useInstance } from 'react-ioc';
import Typograf from 'typograf';

const s = StyleSheet.create({
  scroll: {
    flex: 1,
    backgroundColor: colors.white,
  },
  scrollContent: {
    paddingHorizontal: 20,
    paddingBottom: Platform.OS === 'web' ? 0 : 80,
  },
  container: {
    flex: 1,
  },
  title: {
    fontFamily: ubuntuFont.bold,
    fontSize: 20,
    color: colors.text,
    marginVertical: 10,
  },
  description: {
    marginBottom: 30,
    color: colors.grey,
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
  volumeOrWeight: {
    marginBottom: 20,
  },
  scrollView: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 80,
  },
  buttons: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: 10,
    paddingTop: 10,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    columnGap: 10,
    paddingHorizontal: 20,
    backgroundColor: colors.categoriesBackground,
  },
  cartButton: {
    borderRadius: 10,
    backgroundColor: colors.primary,
    height: 40,
    paddingVertical: 11,
    paddingHorizontal: 10,
  },
  propTitle: {
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    marginTop: 30,
    marginBottom: 10,
    textAlign: 'center',
    color: colors.text,
  },
  notAvailable: {
    flex: 1,
    textAlign: 'center',
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    color: colors.text,
  },
});

const MenuItemScreen: FC<RootStackScreenProps<'MenuItemScreen'>> = (
  {
    route,
    navigation: localNavigation,
  },
) => {
  const scrollRef = useRef<ScrollView | null>(null);

  const scrollToTopOnWeb = useCallback(() => {
    // Чтобы при возвращении на экран товара мы были в начале страницы
    if (Platform.OS === 'web') {
      scrollRef.current?.scrollTo({
        x: 0,
        y: 0,
        animated: false,
      });
    }
  }, []);

  useFocusEffect(scrollToTopOnWeb);

  const tp = useInstance(Typograf);

  const menuItemId = route.params?.menuItemId;
  const shopId = route.params?.shopId;

  const navigation = useNavigation();

  const {
    appStore: {
      activeMenu,
      setSelectedShopId,
      totalAmount,
    },
  } = useStore();

  useFocusEffect(useCallback(() => {
    if (shopId) {
      setSelectedShopId(shopId);
    }
  }, [setSelectedShopId, shopId]));

  const menuItem = activeMenu?.menuItems.get(menuItemId);
  const productTitle = menuItem?.product?.title || null;
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused && productTitle) {
      localNavigation.setOptions({
        title: productTitle,
      });
    }
  }, [isFocused, productTitle, localNavigation]);

  const hasActiveMenu = !!activeMenu && activeMenu.shopId === shopId && activeMenu.ready;

  useFocusEffect(useCallback(() => {
    if (hasActiveMenu && !menuItem) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'IndexScreen' }],
      });
    }
  }, [hasActiveMenu, menuItem, navigation]));

  useFocusEffect(useCallback(() => {
    if (!menuItemId || !shopId) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'IndexScreen' }],
      });
    }
  }, [menuItemId, navigation, shopId]));

  const onBack = useCallback(() => {
    navigation.navigate('Tabs', {
      screen: 'MenuScreen',
      params: {
        shopId,
      },
    });
  }, [navigation, shopId]);

  const { width } = useWindowDimensions();

  const goToCart = useCallback(() => {
    navigation.navigate('CartScreen', {
      shopId,
    });
  }, [navigation, shopId]);

  const modifiersStateModel = useMemo((): undefined | ModifiersStateModelInstance => {
    if (!isFocused) {
      return undefined;
    }

    const productId = menuItem?.product?.id;

    if (!productId || !menuItem) {
      return undefined;
    }

    const initialState = menuItem.groupModifiers.reduce((map, gm) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // map[item.id] = MenuItem.create(item);
      //
      // return map;

      gm.childModifiers.forEach((cm) => {
        const value = Math.max(cm.minAmount, cm.defaultAmount);
        if (value) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map[cm.modifierId] = {
            modifierId: cm.modifierId,
            amount: value,
          };
        }
      });

      const amountInGroup = gm.childModifiers
        .reduce((sum, cm) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const cmAmount = map[cm.modifierId]?.amount || 0;

          return sum + cmAmount;
        }, 0);

      // TODO: Не учитывается куча кейсов, смотрим пока на базу Академии.
      // Если выставлено минимальное кол-во для группы, но не насчиталось модификаторов, то выберем первый попавшийся.
      if (gm.minAmount === 1 && amountInGroup === 0) {
        if (gm.childModifiers.length === 0) {
          // TODO: Обязательного модификатора нет, отмечаем товар как недоступный
          return map;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map[gm.childModifiers[0].modifierId] = {
          modifierId: gm.childModifiers[0].modifierId,
          amount: 1,
        };
      }

      return map;
    }, {});

    return ModifiersStateModel.create({
      productId,
      state: initialState,
    });
  }, [isFocused, menuItem]);

  // Render the screen only if it is focused
  if (!isFocused) {
    return null;
  }

  if (!menuItem || !menuItem.product) {
    return <FullScreenLoading />;
  }

  const isAvailable = menuItem.isAvailable && menuItem.groupModifiersIsCorrect;

  return (
    <ModifiersStateModelContext.Provider value={modifiersStateModel}>
      <Screen>
        <Back title="Назад в меню" onPress={onBack} />
        <View style={s.scroll}>
          <View style={s.container}>
            <ScrollView
              ref={scrollRef}
              style={s.scrollView}
              contentContainerStyle={s.scrollContent}
            >
              <Picture
                notAvailableItem={!isAvailable}
                image={menuItem.product.imageUrl_500}
                size={width - 40}
                maxSize={500}
              />

              <Text style={s.title}>
                {tp.execute(menuItem.product.title)}
              </Text>

              {
                menuItem.product.paramCalculationType === EnumProductParamCalculationType.Volume
                && menuItem.product.volumeParam && (
                  <View style={s.volumeOrWeight}>
                    <ProductVolumeOrWeight
                      property={menuItem.product.volumeParam}
                    />
                  </View>
                )
              }

              {
                menuItem.product.paramCalculationType === EnumProductParamCalculationType.Weight
                && menuItem.product.weightParam && (
                  <View style={s.volumeOrWeight}>
                    <ProductVolumeOrWeight
                      property={menuItem.product.weightParam}
                    />
                  </View>
                )
              }

              <Text style={s.description}>
                {tp.execute(menuItem.product.description)}
              </Text>

              {isAvailable && (
                <ModifierBlock
                  menuItemId={menuItemId}
                />
              )}

              {menuItem.product.params.length > 0 && (
                <>
                  {/* TODO: Это БЖУ на 100гр, скорее всего для обычных продуктов тоже надо выводить какие-то параметры */}
                  {/* {!!menuItem.product.paramTitle && ( */}
                  {/*   <Text style={s.propTitle}> */}
                  {/*     {menuItem.product.paramTitle} */}
                  {/*   </Text> */}
                  {/* )} */}
                  {/* <ProductProperties */}
                  {/*   product={menuItem.product} */}
                  {/* /> */}

                  {
                    menuItem.product.paramCalculationType !== EnumProductParamCalculationType.None
                    && menuItem.product.paramsFull.size > 0
                    && (
                      <>
                        {!!menuItem.product.fullParamTitle && (
                          <Text style={s.propTitle}>
                            {menuItem.product.fullParamTitle}
                          </Text>
                        )}
                        <ProductProperties
                          product={menuItem.product}
                          full
                        />
                      </>
                    )
                  }
                </>
              )}
            </ScrollView>

            <View style={s.buttons}>
              {!isAvailable && (
                <Text style={s.notAvailable}>
                  Нет в наличии
                </Text>
              )}
              {isAvailable && (
                <>
                  <CartButton menuItemId={menuItemId} />
                  <Pressable onPress={goToCart}>
                    <View style={s.cartButton}>
                      <CartIconSvg amount={totalAmount} />
                    </View>
                  </Pressable>
                </>
              )}
            </View>

          </View>
        </View>
      </Screen>
    </ModifiersStateModelContext.Provider>
  );
};

export default observer(MenuItemScreen);
