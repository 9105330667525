import {
  Instance, resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { Modifier } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/Modifier';

export const ModifierMenuItem = t
  .model('ModifierMenuItem', {
    id: t.identifier,
    modifierId: t.string,
    isAvailable: t.boolean,
    price: t.refinement(t.number, (value) => value >= 0),
  })
  .views((self) => ({
    get modifier() {
      return resolveIdentifier(Modifier, self, self.modifierId);
    },
  }));

export interface ModifierMenuItemInstance extends Instance<typeof ModifierMenuItem> {
}

export interface ModifierMenuItemSnapshotIn extends SnapshotIn<typeof ModifierMenuItem> {
}

export interface ModifierMenuItemSnapshotOut extends SnapshotOut<typeof ModifierMenuItem> {
}
