import {
  addDisposer,
  getParent,
  Instance,
  resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import generalConfig from '@constants/generalConfig';
import { Shop } from '@app/domain/store/CoreStore/AppStore/entities/Shop';
import compact from 'lodash/compact';
import { PaymentType } from '@app/domain/store/CoreStore/AppStore/entities/PaymentType';
import { Enum_Paymenttype_Paymenttypecode } from '@app/infrastructureLayer/generated/graphql';
import { DeviceVarsStore } from '@app/domain/store/CoreStore/DeviceVarsStore/DeviceVarsStore';
import type { AppStoreInstance } from '@app/domain/store/CoreStore/AppStore/AppStore';
import { comparer, reaction } from 'mobx';
import moment from 'moment-timezone';

export const CartState = t
  .model('CartState', {
    deviceVarsStore: t.optional(t.reference(DeviceVarsStore), 'DeviceVarsStore'),
    shopId: t.identifier,
    cutleryCount: t.optional(t.refinement(t.number, (value) => value >= 0), 1),
    isAsap: t.optional(t.boolean, true),
    selectedAsapTimeIndex: t.optional(t.refinement(t.number, (value) => value >= 0 && value <= 2), 0),
    asapTime: t.maybe(t.number), // unix timestamp
    date: t.maybeNull(t.string), // DD.MM.YYYY
    promoCode: t.maybeNull(t.string),
    comment: t.optional(t.string, ''),
    customerName: t.optional(t.string, ''),
    time: t.maybeNull(t.string), // HH:mm
    paymentTypeId: t.maybeNull(t.string),
    bonusesToPayAmount: t.optional(t.refinement(t.number, (value) => value >= 0), 0),
    cardId: t.maybeNull(t.string),
  })
  .views((self) => ({
    get timeParts() {
      if (!self.time) {
        return null;
      }

      const temp = self.time.split(':');

      return {
        hours: temp[0],
        minutes: temp[1],
      };
    },
    get shop() {
      return resolveIdentifier(Shop, self, self.shopId);
    },
    get paymentType() {
      if (!self.paymentTypeId) {
        return undefined;
      }

      return resolveIdentifier(PaymentType, self, self.paymentTypeId);
    },
  }))
  .views((self) => ({
    get selectedAsapOption() {
      const asapTimeOptions = self.shop?.workTime?.asapTimeOptions;
      if (!asapTimeOptions) {
        return undefined;
      }

      return asapTimeOptions.find((x) => x.index === self.selectedAsapTimeIndex);
    },
  }))
  .views((self) => ({
    get canMakeOrder() {
      const appStore: AppStoreInstance = getParent(self, 2);

      if (!appStore) {
        return false;
      }

      if (
        appStore.globalSettings?.isHotelMode
        && (
          typeof self.deviceVarsStore.roomNumber === 'undefined'
          || self.deviceVarsStore.roomNumber.trim().length === 0
        )
      ) {
        return false;
      }

      if (!self.paymentTypeId) {
        return false;
      }

      if (self.isAsap && !self.selectedAsapOption) {
        return false;
      }

      if (!self.isAsap && (!self.date || !self.time)) {
        return false;
      }

      if (!self.paymentTypeId) {
        return false;
      }

      if (!self.paymentType) {
        return false;
      }

      if (self.paymentType.type === Enum_Paymenttype_Paymenttypecode.Card && !self.cardId) {
        return false;
      }

      if (appStore.orderType?.type === 'DELIVERY' && !appStore.selectedDeliveryAddressId) {
        return false;
      }

      // Проверим что товары не лимитированы по времени
      const haveOutTimeLimitItems = appStore.activeCartItems.some((cartItem) => (
        !appStore.productTimeAvailability(cartItem?.menuItem?.id, self.time)
        || !appStore.categoryTimeAvailability(cartItem?.menuItem?.id, self.time)));

      if (haveOutTimeLimitItems) {
        return false;
      }

      const {
        cartStates,
        selectedShopId,
        profileStore: {
          profile,
        },
      } = appStore;

      const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;
      const haveNameForOrder = profile?.fio || cartState?.customerName;

      if (!haveNameForOrder) {
        return false;
      }

      return true;
    },
  }))
  .actions((self) => ({
    setCardId: (value: string | null) => {
      self.cardId = value;
    },
    setComment: (value: string) => {
      self.comment = value;
    },
    setCustomerName: (value: string) => {
      self.customerName = value;
    },
    setIsAsap: (value: boolean) => {
      self.isAsap = value;
    },
    setAsapTime: (value: number | undefined) => {
      self.asapTime = value;
    },
    setSelectedAsapTimeIndex: (value: number) => {
      self.selectedAsapTimeIndex = value;
    },
    setBonusesToPayAmount: (value: number) => {
      self.bonusesToPayAmount = value;
    },
    setPromoCode: (value: string | null) => {
      self.promoCode = value;
    },
    setDate: (value: string | null) => {
      self.date = value;
    },
    setTime: (value: string | null) => {
      self.time = value;
    },
    setPaymentTypeId: (value: string | null) => {
      self.paymentTypeId = value;
    },
    setCutleryCount: (value: number) => {
      if (value < 0) {
        return;
      }

      self.cutleryCount = value;
    },
    increaseCutleryCount: () => {
      if (self.cutleryCount === generalConfig.maxCutleryCountAmount) {
        return;
      }

      self.cutleryCount += 1;
    },
    reduceCutleryCount: () => {
      if (self.cutleryCount === 0) {
        return;
      }

      self.cutleryCount -= 1;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      /**
       * Выбираем первое доступное время ASAP если выбран ASAP а время нет.
       */
      addDisposer(
        self,
        reaction(
          () => ({
            shopLoaded: !!self.shop,
            asapTimeOptions: self.shop?.workTime.asapTimeOptions,
            isAsap: self.isAsap,
            asapTime: self.asapTime,
          }),
          (
            {
              shopLoaded,
              asapTimeOptions,
              isAsap,
              asapTime,
            },
          ) => {
            if (isAsap && shopLoaded && !asapTime && asapTimeOptions && asapTimeOptions?.length) {
              self.setAsapTime(asapTimeOptions[0].value);
            }
          },
          {
            equals: comparer.structural,
            fireImmediately: true,
          },
        ),
      );

      /**
       * Проверяем параметры магазина и если у магазина только один способ оплаты, то сразу выбираем его
       * Если выбран недоступны в магазине, то либо убираем, либо назначаем единственный, что есть
       */
      addDisposer(
        self,
        reaction(
          () => ({
            shopLoaded: !!self.shop,
            allowedPaymentTypes: self.shop?.allowedPaymentTypes,
          }),
          (
            {
              shopLoaded,
              allowedPaymentTypes,
            },
          ) => {
            if (!shopLoaded) {
              return;
            }

            const allowedPaymentTypesIsArray = Array.isArray(allowedPaymentTypes);
            if (!allowedPaymentTypesIsArray) {
              return;
            }

            const shopAllowedPaymentTypesIds = compact(allowedPaymentTypes.map((pa) => pa?.id));

            if (
              shopAllowedPaymentTypesIds.length === 1
              && self.paymentTypeId !== shopAllowedPaymentTypesIds[0]
            ) {
              self.setPaymentTypeId(shopAllowedPaymentTypesIds[0]);
            }

            if (
              shopAllowedPaymentTypesIds.length > 1
              && self.paymentTypeId
              && !shopAllowedPaymentTypesIds.includes(self.paymentTypeId)
            ) {
              self.setPaymentTypeId(null);
            }
          },
          {
            equals: comparer.structural,
            fireImmediately: true,
          },
        ),
      );

      /**
       * Если не выбранные часы и минуты, но выбрана дата, то выбираем самые первые
       */
      addDisposer(
        self,
        reaction(
          () => ({
            selectedDate: self.date,
            selectedTime: self.time,
            availableSlotsForWeek: self.shop?.workTime.availableSlotsForWeek,
          }),
          (
            {
              availableSlotsForWeek,
              selectedDate,
              selectedTime,
            },
          ) => {
            if (selectedTime || !selectedDate || !availableSlotsForWeek) {
              return;
            }

            const slots = availableSlotsForWeek.get(selectedDate);

            if (!slots || slots.length === 0) {
              return;
            }

            const firstSlot = slots[0];
            self.setTime(firstSlot.time.format('HH:mm'));
          },
          {
            equals: comparer.structural,
            fireImmediately: true,
          },
        ),
      );

      /**
       * Если выбранные часы и минуты не доступны в выбранной дате, то выбираем самые первые
       */
      addDisposer(
        self,
        reaction(
          () => ({
            selectedDate: self.date,
            selectedTime: self.time,
            availableSlotsForWeek: self.shop?.workTime.availableSlotsForWeek,
          }),
          (
            {
              availableSlotsForWeek,
              selectedDate,
              selectedTime,
            },
          ) => {
            if (!selectedTime || !selectedDate || !availableSlotsForWeek) {
              return;
            }

            const slots = availableSlotsForWeek.get(selectedDate);

            if (!slots || slots.length === 0) {
              self.setTime(null);

              return;
            }

            let isAvailable = false;
            slots.forEach((slot) => {
              if (!isAvailable) {
                const time = slot.time.format('HH:mm');

                if (time === selectedTime) {
                  isAvailable = true;
                }
              }
            });

            if (!isAvailable) {
              const firstSlot = slots[0];
              self.setTime(firstSlot.time.format('HH:mm'));
            }
          },
          {
            equals: comparer.structural,
            fireImmediately: true,
          },
        ),
      );

      /**
       * Если выбрана не доступная дата, то выбираем первую из доступных
       */
      addDisposer(
        self,
        reaction(
          () => ({
            availableSlotsForWeek: self.shop?.workTime.availableSlotsForWeek,
            selectedDate: self.date,
          }),
          (
            {
              availableSlotsForWeek,
              selectedDate,
            },
          ) => {
            if (!availableSlotsForWeek || !selectedDate) {
              return;
            }
            const dates = [...availableSlotsForWeek.keys()];
            if (!dates.includes(selectedDate)) {
              if (dates.length > 0) {
                self.setDate(dates[0]);
              } else {
                self.setDate(null);
                self.setTime(null);
              }
            }
          },
          {
            equals: comparer.structural,
            fireImmediately: true,
          },
        ),
      );

      /**
       * Если не указана дата доставки, то находим первую попавшуюся дату с доступными слотами и используем её
       */
      addDisposer(
        self,
        reaction(
          () => ({
            availableSlotsForWeek: self.shop?.workTime.availableSlotsForWeek,
            selectedDate: self.date,
          }),
          (
            {
              availableSlotsForWeek,
              selectedDate,
            },
          ) => {
            if (!availableSlotsForWeek || !!selectedDate) {
              return;
            }

            let day = moment();
            let isFinished = false;
            for (let i = 0; i < 7; i++) {
              if (isFinished) {
                return;
              }

              const key = day.format('DD.MM.YYYY');

              const slots = availableSlotsForWeek.get(key);

              if (slots) {
                isFinished = true;
                self.setDate(key);
              }

              day = day.clone()
                .add(1, 'day');
            }
          },
          {
            equals: comparer.structural,
            fireImmediately: true,
          },
        ),
      );
    },
  }));

export interface CartStateInstance extends Instance<typeof CartState> {
}

export interface CartStateSnapshotIn extends SnapshotIn<typeof CartState> {
}

export interface CartStateSnapshotOut extends SnapshotOut<typeof CartState> {
}
