import React, { useContext } from 'react';
import { CheckoutData } from '@hooks/useCheckout';

export const CheckoutContext = React
  .createContext<{
  data: CheckoutData | null,
  loading: boolean,
}>({ data: null, loading: false });

export const useCheckoutState = () => {
  const state = useContext(CheckoutContext);

  if (!state) {
    throw new Error('Вы пытаетесь получить состояние чекаут вне контекста, или значение в контекст не было передано');
  }

  return state;
};
