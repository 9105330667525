/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
  data?: object | object[] | null;
  error: {
    status?: number;
    name?: string;
    message?: string;
    details?: object;
  };
}

export interface AccountRemoveRequestRequest {
  data: {
    /** @example "string or id" */
    user?: number | string;
  };
}

export interface AccountRemoveRequestListResponseDataItem {
  id?: number;
  attributes?: AccountRemoveRequest;
}

export interface AccountRemoveRequestListResponse {
  data?: AccountRemoveRequestListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface AccountRemoveRequest {
  user?: {
    data?: {
      id?: number;
      attributes?: {
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              description?: string;
              type?: string;
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          firstname?: string;
                          lastname?: string;
                          username?: string;
                          /** @format email */
                          email?: string;
                          resetPasswordToken?: string;
                          registrationToken?: string;
                          isActive?: boolean;
                          roles?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                name?: string;
                                code?: string;
                                description?: string;
                                users?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  }[];
                                };
                                permissions?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      action?: string;
                                      actionParameters?: any;
                                      subject?: string;
                                      properties?: any;
                                      conditions?: any;
                                      role?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  }[];
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          blocked?: boolean;
                          preferedLanguage?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        expo_push_tokens?: {
          data?: {
            id?: number;
            attributes?: {
              token?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        phone?: string;
        sex?: "MALE" | "FEMALE" | "NOT_SELECTED";
        /** @format date */
        birthday?: string;
        fio?: string;
        broadcastOfferAccepted?: boolean;
        registrationCompleted?: boolean;
        alfaClientId?: string;
        orders?: {
          data?: {
            id?: number;
            attributes?: {
              status?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              total?: number;
              paymentType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    paymentTypeCode?: "CARD" | "CASH";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              orderType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    address?: string;
                    city?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          shops?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            }[];
                          };
                          iiko_id?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    coordinates?: {
                      id?: number;
                      /** @format float */
                      longitude?: number;
                      /** @format float */
                      latitude?: number;
                    };
                    workTime?: (
                      | {
                          id?: number;
                          __component?: string;
                          time?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                title?: string;
                                shortTitle?: string;
                                dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                        }
                      | {
                          id?: number;
                          __component?: string;
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        }
                    )[];
                    menu_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          product?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                imageUrl?: string;
                                title?: string;
                                description?: string;
                                category?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      title?: string;
                                      rank?: number;
                                      iiko_id?: string;
                                      override_title?: string;
                                      timeLimit?: {
                                        id?: number;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        startTime?: string;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        endTime?: string;
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  };
                                };
                                params?: {
                                  id?: number;
                                  type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                  /** @format float */
                                  value?: number;
                                }[];
                                paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                type?: "DISH" | "SERVICE" | "ITEM";
                                iiko_id?: string;
                                imageUrl_100?: string;
                                imageUrl_200?: string;
                                imageUrl_500?: string;
                                override_title?: string;
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          groupModifiers?: {
                            id?: number;
                            minAmount?: number;
                            maxAmount?: number;
                            required?: boolean;
                            defaultAmount?: number;
                            freeOfChargeAmount?: number;
                            childModifiers?: {
                              id?: number;
                              minAmount?: number;
                              maxAmount?: number;
                              defaultAmount?: number;
                              required?: boolean;
                              freeOfChargeAmount?: number;
                              modifier?: {
                                data?: {
                                  id?: number;
                                  attributes?: {
                                    title?: string;
                                    imageUrl?: string;
                                    description?: string;
                                    params?: {
                                      id?: number;
                                      type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                      /** @format float */
                                      value?: number;
                                    }[];
                                    paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                    iiko_id?: string;
                                    override_title?: string;
                                    /** @format date-time */
                                    createdAt?: string;
                                    /** @format date-time */
                                    updatedAt?: string;
                                    createdBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                    updatedBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                  };
                                };
                              };
                            }[];
                            modifier_category?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  title?: string;
                                  iiko_id?: string;
                                  override_title?: string;
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              };
                            };
                          }[];
                          isAvailable?: boolean;
                          timeLimit?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            startTime?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            endTime?: string;
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    useCutlery?: boolean;
                    useBonuses?: boolean;
                    usePromoCodes?: boolean;
                    allowedOrderTypes?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    allowedPaymentTypes?: {
                      id?: number;
                      payment_type?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    }[];
                    workTimeTitle?: string;
                    menu_modifier_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          modifier?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          isAvailable?: boolean;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    timezone?: string;
                    minOrderDelayInMinutes?: number;
                    firstOrderDelayInMinutes?: number;
                    asapStepInMinutes?: number;
                    specialWorkTimes?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
                          timeRange?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          /** @format date */
                          date?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    deliveryZones?: {
                      id?: number;
                      name?: string;
                      minSum?: number;
                      deliveryDurationInMinutes?: number;
                      /** @format float */
                      deliveryPrice?: number;
                      polygon?: string;
                      color?: string;
                    }[];
                    external_integration?: (
                      | {
                          id?: number;
                          __component?: string;
                          iiko_terminal_id?: string;
                          is_iiko_terminal_available?: boolean;
                          iiko_external_menu_id?: string;
                          iiko_organization_id?: string;
                          departmentId?: string;
                        }
                      | {
                          id?: number;
                          __component?: string;
                          someField?: string;
                        }
                    )[];
                    rank?: number;
                    supplier_info?: {
                      id?: number;
                      phones?: string;
                      name?: string;
                      inn?: string;
                    };
                    enabled?: boolean;
                    phoneForClients?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              /** @format date */
              date?: string;
              /**
               * @format time
               * @example "12:54.000"
               */
              time?: string;
              alfaOrderId?: string;
              billUrl?: string;
              orderItems?: {
                id?: number;
                menuItem?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                amount?: number;
                /** @format float */
                price?: number;
                snapshot?: any;
                orderItemModifiers?: {
                  id?: number;
                  menu_modifier_item?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  amount?: number;
                  /** @format float */
                  price?: number;
                  snapshot?: any;
                  modifier_category?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  modifier_category_snapshot?: any;
                }[];
              }[];
              creditCardId?: string;
              params?: {
                id?: number;
                value?: string;
                type?: "ROOM_NUMBER";
              }[];
              iiko_order_id?: string;
              /** @format float */
              bonuses?: number;
              promoCode?: string;
              deliveryAddressSnapshot?: any;
              deliveryInfoJson?: any;
              /** @format float */
              totalItemsCost?: number;
              /** @format float */
              totalItemCostForPay?: number;
              /** @format float */
              itemsDiscount?: number;
              /** @format float */
              deliveryCost?: number;
              order_request?: {
                data?: {
                  id?: number;
                  attributes?: {
                    request?: any;
                    userId?: number;
                    status?: "NEW" | "CREATED" | "ERROR";
                    order?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              comment?: string;
              orderCookingStatus?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryAddresses?: {
          id?: number;
          city?: {
            data?: {
              id?: number;
              attributes?: object;
            };
          };
          type?: "WORK" | "HOME" | "OTHER";
          title?: string;
          address?: string;
          room?: string;
          entrance?: string;
          floor?: string;
          intercomCode?: string;
          comment?: string;
          coordinates?: {
            id?: number;
            /** @format float */
            longitude?: number;
            /** @format float */
            latitude?: number;
          };
          geocodeDataSource?: any;
        }[];
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface AccountRemoveRequestResponseDataObject {
  id?: number;
  attributes?: AccountRemoveRequest;
}

export interface AccountRemoveRequestResponse {
  data?: AccountRemoveRequestResponseDataObject;
  meta?: object;
}

export interface CategoryRequest {
  data: {
    title: string;
    rank?: number;
    iiko_id?: string;
    override_title?: string;
    timeLimit?: CommonOrderTimeLimitComponent;
  };
}

export interface CategoryListResponseDataItem {
  id?: number;
  attributes?: Category;
}

export interface CategoryListResponse {
  data?: CategoryListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface Category {
  title: string;
  rank?: number;
  iiko_id?: string;
  override_title?: string;
  timeLimit?: CommonOrderTimeLimitComponent;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface CategoryResponseDataObject {
  id?: number;
  attributes?: Category;
}

export interface CategoryResponse {
  data?: CategoryResponseDataObject;
  meta?: object;
}

export interface CommonOrderTimeLimitComponent {
  id?: number;
  /**
   * @format time
   * @example "12:54.000"
   */
  startTime?: string;
  /**
   * @format time
   * @example "12:54.000"
   */
  endTime?: string;
}

export interface CityRequest {
  data: {
    title: string;
    shops?: (number | string)[];
    iiko_id?: string;
  };
}

export interface CityListResponseDataItem {
  id?: number;
  attributes?: City;
}

export interface CityListResponse {
  data?: CityListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface City {
  title: string;
  shops?: {
    data?: {
      id?: number;
      attributes?: {
        title?: string;
        address?: string;
        city?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              shops?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: {
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          name?: string;
                          code?: string;
                          description?: string;
                          users?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            }[];
                          };
                          permissions?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                action?: string;
                                actionParameters?: any;
                                subject?: string;
                                properties?: any;
                                conditions?: any;
                                role?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        coordinates?: {
          id?: number;
          /** @format float */
          longitude?: number;
          /** @format float */
          latitude?: number;
        };
        workTime?: (
          | {
              id?: number;
              __component?: string;
              time?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              day?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    shortTitle?: string;
                    dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
            }
          | {
              id?: number;
              __component?: string;
              day?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            }
        )[];
        menu_items?: {
          data?: {
            id?: number;
            attributes?: {
              product?: {
                data?: {
                  id?: number;
                  attributes?: {
                    imageUrl?: string;
                    title?: string;
                    description?: string;
                    category?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          rank?: number;
                          iiko_id?: string;
                          override_title?: string;
                          timeLimit?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            startTime?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            endTime?: string;
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    params?: {
                      id?: number;
                      type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                      /** @format float */
                      value?: number;
                    }[];
                    paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                    type?: "DISH" | "SERVICE" | "ITEM";
                    iiko_id?: string;
                    imageUrl_100?: string;
                    imageUrl_200?: string;
                    imageUrl_500?: string;
                    override_title?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              groupModifiers?: {
                id?: number;
                minAmount?: number;
                maxAmount?: number;
                required?: boolean;
                defaultAmount?: number;
                freeOfChargeAmount?: number;
                childModifiers?: {
                  id?: number;
                  minAmount?: number;
                  maxAmount?: number;
                  defaultAmount?: number;
                  required?: boolean;
                  freeOfChargeAmount?: number;
                  modifier?: {
                    data?: {
                      id?: number;
                      attributes?: {
                        title?: string;
                        imageUrl?: string;
                        description?: string;
                        params?: {
                          id?: number;
                          type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                          /** @format float */
                          value?: number;
                        }[];
                        paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                        iiko_id?: string;
                        override_title?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        createdBy?: {
                          data?: {
                            id?: number;
                            attributes?: object;
                          };
                        };
                        updatedBy?: {
                          data?: {
                            id?: number;
                            attributes?: object;
                          };
                        };
                      };
                    };
                  };
                }[];
                modifier_category?: {
                  data?: {
                    id?: number;
                    attributes?: {
                      title?: string;
                      iiko_id?: string;
                      override_title?: string;
                      /** @format date-time */
                      createdAt?: string;
                      /** @format date-time */
                      updatedAt?: string;
                      createdBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                      updatedBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    };
                  };
                };
              }[];
              isAvailable?: boolean;
              timeLimit?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                startTime?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                endTime?: string;
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        useCutlery?: boolean;
        useBonuses?: boolean;
        usePromoCodes?: boolean;
        allowedOrderTypes?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        allowedPaymentTypes?: {
          id?: number;
          payment_type?: {
            data?: {
              id?: number;
              attributes?: {
                title?: string;
                paymentTypeCode?: "CARD" | "CASH";
                iiko_id?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                createdBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                updatedBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
              };
            };
          };
        }[];
        workTimeTitle?: string;
        menu_modifier_items?: {
          data?: {
            id?: number;
            attributes?: {
              modifier?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              isAvailable?: boolean;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        timezone?: string;
        minOrderDelayInMinutes?: number;
        firstOrderDelayInMinutes?: number;
        asapStepInMinutes?: number;
        specialWorkTimes?: {
          data?: {
            id?: number;
            attributes?: {
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
              timeRange?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              /** @format date */
              date?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryZones?: {
          id?: number;
          name?: string;
          minSum?: number;
          deliveryDurationInMinutes?: number;
          /** @format float */
          deliveryPrice?: number;
          polygon?: string;
          color?: string;
        }[];
        external_integration?: (
          | {
              id?: number;
              __component?: string;
              iiko_terminal_id?: string;
              is_iiko_terminal_available?: boolean;
              iiko_external_menu_id?: string;
              iiko_organization_id?: string;
              departmentId?: string;
            }
          | {
              id?: number;
              __component?: string;
              someField?: string;
            }
        )[];
        rank?: number;
        supplier_info?: {
          id?: number;
          phones?: string;
          name?: string;
          inn?: string;
        };
        enabled?: boolean;
        phoneForClients?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    }[];
  };
  iiko_id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface CityResponseDataObject {
  id?: number;
  attributes?: City;
}

export interface CityResponse {
  data?: CityResponseDataObject;
  meta?: object;
}

export interface ConnectedItemGroupRequest {
  data: {
    connected_items: MenuItemConnectedItemComponent[];
    /** @example "string or id" */
    main?: number | string;
  };
}

export interface ConnectedItemGroupListResponseDataItem {
  id?: number;
  attributes?: ConnectedItemGroup;
}

export interface ConnectedItemGroupListResponse {
  data?: ConnectedItemGroupListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface ConnectedItemGroup {
  connected_items: MenuItemConnectedItemComponent[];
  main?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ConnectedItemGroupResponseDataObject {
  id?: number;
  attributes?: ConnectedItemGroup;
}

export interface ConnectedItemGroupResponse {
  data?: ConnectedItemGroupResponseDataObject;
  meta?: object;
}

export interface MenuItemConnectedItemComponent {
  id?: number;
  menu_item?: {
    data?: {
      id?: number;
      attributes?: {
        product?: {
          data?: {
            id?: number;
            attributes?: {
              imageUrl?: string;
              title?: string;
              description?: string;
              category?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    rank?: number;
                    iiko_id?: string;
                    override_title?: string;
                    timeLimit?: {
                      id?: number;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      startTime?: string;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      endTime?: string;
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          firstname?: string;
                          lastname?: string;
                          username?: string;
                          /** @format email */
                          email?: string;
                          resetPasswordToken?: string;
                          registrationToken?: string;
                          isActive?: boolean;
                          roles?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                name?: string;
                                code?: string;
                                description?: string;
                                users?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  }[];
                                };
                                permissions?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      action?: string;
                                      actionParameters?: any;
                                      subject?: string;
                                      properties?: any;
                                      conditions?: any;
                                      role?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  }[];
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          blocked?: boolean;
                          preferedLanguage?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              params?: {
                id?: number;
                type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                /** @format float */
                value?: number;
              }[];
              paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
              type?: "DISH" | "SERVICE" | "ITEM";
              iiko_id?: string;
              imageUrl_100?: string;
              imageUrl_200?: string;
              imageUrl_500?: string;
              override_title?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        shop?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              address?: string;
              city?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    shops?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              coordinates?: {
                id?: number;
                /** @format float */
                longitude?: number;
                /** @format float */
                latitude?: number;
              };
              workTime?: (
                | {
                    id?: number;
                    __component?: string;
                    time?: {
                      id?: number;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      start?: string;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      end?: string;
                    };
                    day?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          shortTitle?: string;
                          dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                  }
                | {
                    id?: number;
                    __component?: string;
                    day?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  }
              )[];
              menu_items?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              useCutlery?: boolean;
              useBonuses?: boolean;
              usePromoCodes?: boolean;
              allowedOrderTypes?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              allowedPaymentTypes?: {
                id?: number;
                payment_type?: {
                  data?: {
                    id?: number;
                    attributes?: {
                      title?: string;
                      paymentTypeCode?: "CARD" | "CASH";
                      iiko_id?: string;
                      /** @format date-time */
                      createdAt?: string;
                      /** @format date-time */
                      updatedAt?: string;
                      createdBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                      updatedBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    };
                  };
                };
              }[];
              workTimeTitle?: string;
              menu_modifier_items?: {
                data?: {
                  id?: number;
                  attributes?: {
                    modifier?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          imageUrl?: string;
                          description?: string;
                          params?: {
                            id?: number;
                            type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                            /** @format float */
                            value?: number;
                          }[];
                          paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                          iiko_id?: string;
                          override_title?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    shop?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format float */
                    price?: number;
                    iiko_organization_id?: string;
                    iiko_product_id?: string;
                    isAvailable?: boolean;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              timezone?: string;
              minOrderDelayInMinutes?: number;
              firstOrderDelayInMinutes?: number;
              asapStepInMinutes?: number;
              specialWorkTimes?: {
                data?: {
                  id?: number;
                  attributes?: {
                    shop?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
                    timeRange?: {
                      id?: number;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      start?: string;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      end?: string;
                    };
                    /** @format date */
                    date?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              deliveryZones?: {
                id?: number;
                name?: string;
                minSum?: number;
                deliveryDurationInMinutes?: number;
                /** @format float */
                deliveryPrice?: number;
                polygon?: string;
                color?: string;
              }[];
              external_integration?: (
                | {
                    id?: number;
                    __component?: string;
                    iiko_terminal_id?: string;
                    is_iiko_terminal_available?: boolean;
                    iiko_external_menu_id?: string;
                    iiko_organization_id?: string;
                    departmentId?: string;
                  }
                | {
                    id?: number;
                    __component?: string;
                    someField?: string;
                  }
              )[];
              rank?: number;
              supplier_info?: {
                id?: number;
                phones?: string;
                name?: string;
                inn?: string;
              };
              enabled?: boolean;
              phoneForClients?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        /** @format float */
        price?: number;
        iiko_organization_id?: string;
        iiko_product_id?: string;
        groupModifiers?: {
          id?: number;
          minAmount?: number;
          maxAmount?: number;
          required?: boolean;
          defaultAmount?: number;
          freeOfChargeAmount?: number;
          childModifiers?: {
            id?: number;
            minAmount?: number;
            maxAmount?: number;
            defaultAmount?: number;
            required?: boolean;
            freeOfChargeAmount?: number;
            modifier?: {
              data?: {
                id?: number;
                attributes?: object;
              };
            };
          }[];
          modifier_category?: {
            data?: {
              id?: number;
              attributes?: {
                title?: string;
                iiko_id?: string;
                override_title?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                createdBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                updatedBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
              };
            };
          };
        }[];
        isAvailable?: boolean;
        timeLimit?: {
          id?: number;
          /**
           * @format time
           * @example "12:54.000"
           */
          startTime?: string;
          /**
           * @format time
           * @example "12:54.000"
           */
          endTime?: string;
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  title?: string;
}

export interface CustomScreenRequest {
  data: {
    title: string;
    content: string;
  };
}

export interface CustomScreenListResponseDataItem {
  id?: number;
  attributes?: CustomScreen;
}

export interface CustomScreenListResponse {
  data?: CustomScreenListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface CustomScreen {
  title: string;
  content: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface CustomScreenResponseDataObject {
  id?: number;
  attributes?: CustomScreen;
}

export interface CustomScreenResponse {
  data?: CustomScreenResponseDataObject;
  meta?: object;
}

export interface DayRequest {
  data: {
    title: string;
    shortTitle: string;
    dayCode: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
  };
}

export interface DayListResponseDataItem {
  id?: number;
  attributes?: Day;
}

export interface DayListResponse {
  data?: DayListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface Day {
  title: string;
  shortTitle: string;
  dayCode: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface DayResponseDataObject {
  id?: number;
  attributes?: Day;
}

export interface DayResponse {
  data?: DayResponseDataObject;
  meta?: object;
}

export interface ExpoPushTokenRequest {
  data: {
    token: string;
    /** @example "string or id" */
    user?: number | string;
  };
}

export interface ExpoPushTokenListResponseDataItem {
  id?: number;
  attributes?: ExpoPushToken;
}

export interface ExpoPushTokenListResponse {
  data?: ExpoPushTokenListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface ExpoPushToken {
  token: string;
  user?: {
    data?: {
      id?: number;
      attributes?: {
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              description?: string;
              type?: string;
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          firstname?: string;
                          lastname?: string;
                          username?: string;
                          /** @format email */
                          email?: string;
                          resetPasswordToken?: string;
                          registrationToken?: string;
                          isActive?: boolean;
                          roles?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                name?: string;
                                code?: string;
                                description?: string;
                                users?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  }[];
                                };
                                permissions?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      action?: string;
                                      actionParameters?: any;
                                      subject?: string;
                                      properties?: any;
                                      conditions?: any;
                                      role?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  }[];
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          blocked?: boolean;
                          preferedLanguage?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        expo_push_tokens?: {
          data?: {
            id?: number;
            attributes?: {
              token?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        phone?: string;
        sex?: "MALE" | "FEMALE" | "NOT_SELECTED";
        /** @format date */
        birthday?: string;
        fio?: string;
        broadcastOfferAccepted?: boolean;
        registrationCompleted?: boolean;
        alfaClientId?: string;
        orders?: {
          data?: {
            id?: number;
            attributes?: {
              status?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              total?: number;
              paymentType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    paymentTypeCode?: "CARD" | "CASH";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              orderType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    address?: string;
                    city?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          shops?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            }[];
                          };
                          iiko_id?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    coordinates?: {
                      id?: number;
                      /** @format float */
                      longitude?: number;
                      /** @format float */
                      latitude?: number;
                    };
                    workTime?: (
                      | {
                          id?: number;
                          __component?: string;
                          time?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                title?: string;
                                shortTitle?: string;
                                dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                        }
                      | {
                          id?: number;
                          __component?: string;
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        }
                    )[];
                    menu_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          product?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                imageUrl?: string;
                                title?: string;
                                description?: string;
                                category?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      title?: string;
                                      rank?: number;
                                      iiko_id?: string;
                                      override_title?: string;
                                      timeLimit?: {
                                        id?: number;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        startTime?: string;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        endTime?: string;
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  };
                                };
                                params?: {
                                  id?: number;
                                  type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                  /** @format float */
                                  value?: number;
                                }[];
                                paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                type?: "DISH" | "SERVICE" | "ITEM";
                                iiko_id?: string;
                                imageUrl_100?: string;
                                imageUrl_200?: string;
                                imageUrl_500?: string;
                                override_title?: string;
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          groupModifiers?: {
                            id?: number;
                            minAmount?: number;
                            maxAmount?: number;
                            required?: boolean;
                            defaultAmount?: number;
                            freeOfChargeAmount?: number;
                            childModifiers?: {
                              id?: number;
                              minAmount?: number;
                              maxAmount?: number;
                              defaultAmount?: number;
                              required?: boolean;
                              freeOfChargeAmount?: number;
                              modifier?: {
                                data?: {
                                  id?: number;
                                  attributes?: {
                                    title?: string;
                                    imageUrl?: string;
                                    description?: string;
                                    params?: {
                                      id?: number;
                                      type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                      /** @format float */
                                      value?: number;
                                    }[];
                                    paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                    iiko_id?: string;
                                    override_title?: string;
                                    /** @format date-time */
                                    createdAt?: string;
                                    /** @format date-time */
                                    updatedAt?: string;
                                    createdBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                    updatedBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                  };
                                };
                              };
                            }[];
                            modifier_category?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  title?: string;
                                  iiko_id?: string;
                                  override_title?: string;
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              };
                            };
                          }[];
                          isAvailable?: boolean;
                          timeLimit?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            startTime?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            endTime?: string;
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    useCutlery?: boolean;
                    useBonuses?: boolean;
                    usePromoCodes?: boolean;
                    allowedOrderTypes?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    allowedPaymentTypes?: {
                      id?: number;
                      payment_type?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    }[];
                    workTimeTitle?: string;
                    menu_modifier_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          modifier?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          isAvailable?: boolean;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    timezone?: string;
                    minOrderDelayInMinutes?: number;
                    firstOrderDelayInMinutes?: number;
                    asapStepInMinutes?: number;
                    specialWorkTimes?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
                          timeRange?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          /** @format date */
                          date?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    deliveryZones?: {
                      id?: number;
                      name?: string;
                      minSum?: number;
                      deliveryDurationInMinutes?: number;
                      /** @format float */
                      deliveryPrice?: number;
                      polygon?: string;
                      color?: string;
                    }[];
                    external_integration?: (
                      | {
                          id?: number;
                          __component?: string;
                          iiko_terminal_id?: string;
                          is_iiko_terminal_available?: boolean;
                          iiko_external_menu_id?: string;
                          iiko_organization_id?: string;
                          departmentId?: string;
                        }
                      | {
                          id?: number;
                          __component?: string;
                          someField?: string;
                        }
                    )[];
                    rank?: number;
                    supplier_info?: {
                      id?: number;
                      phones?: string;
                      name?: string;
                      inn?: string;
                    };
                    enabled?: boolean;
                    phoneForClients?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              /** @format date */
              date?: string;
              /**
               * @format time
               * @example "12:54.000"
               */
              time?: string;
              alfaOrderId?: string;
              billUrl?: string;
              orderItems?: {
                id?: number;
                menuItem?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                amount?: number;
                /** @format float */
                price?: number;
                snapshot?: any;
                orderItemModifiers?: {
                  id?: number;
                  menu_modifier_item?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  amount?: number;
                  /** @format float */
                  price?: number;
                  snapshot?: any;
                  modifier_category?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  modifier_category_snapshot?: any;
                }[];
              }[];
              creditCardId?: string;
              params?: {
                id?: number;
                value?: string;
                type?: "ROOM_NUMBER";
              }[];
              iiko_order_id?: string;
              /** @format float */
              bonuses?: number;
              promoCode?: string;
              deliveryAddressSnapshot?: any;
              deliveryInfoJson?: any;
              /** @format float */
              totalItemsCost?: number;
              /** @format float */
              totalItemCostForPay?: number;
              /** @format float */
              itemsDiscount?: number;
              /** @format float */
              deliveryCost?: number;
              order_request?: {
                data?: {
                  id?: number;
                  attributes?: {
                    request?: any;
                    userId?: number;
                    status?: "NEW" | "CREATED" | "ERROR";
                    order?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              comment?: string;
              orderCookingStatus?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryAddresses?: {
          id?: number;
          city?: {
            data?: {
              id?: number;
              attributes?: object;
            };
          };
          type?: "WORK" | "HOME" | "OTHER";
          title?: string;
          address?: string;
          room?: string;
          entrance?: string;
          floor?: string;
          intercomCode?: string;
          comment?: string;
          coordinates?: {
            id?: number;
            /** @format float */
            longitude?: number;
            /** @format float */
            latitude?: number;
          };
          geocodeDataSource?: any;
        }[];
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ExpoPushTokenResponseDataObject {
  id?: number;
  attributes?: ExpoPushToken;
}

export interface ExpoPushTokenResponse {
  data?: ExpoPushTokenResponseDataObject;
  meta?: object;
}

export interface FaqRequest {
  data: {
    title: string;
    content: string;
  };
}

export interface FaqListResponseDataItem {
  id?: number;
  attributes?: Faq;
}

export interface FaqListResponse {
  data?: FaqListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface Faq {
  title: string;
  content: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface FaqResponseDataObject {
  id?: number;
  attributes?: Faq;
}

export interface FaqResponse {
  data?: FaqResponseDataObject;
  meta?: object;
}

export interface GlobalSettingRequest {
  data: {
    broadcastOfferAcceptedRequired: boolean;
    useBroadcastOfferAccept: boolean;
    policyUrl: string;
    termUrl: string;
    isHotelMode: boolean;
    useBonuses: boolean;
    useFaq: boolean;
  };
}

export interface GlobalSettingListResponseDataItem {
  id?: number;
  attributes?: GlobalSetting;
}

export interface GlobalSettingListResponse {
  data?: GlobalSettingListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface GlobalSetting {
  broadcastOfferAcceptedRequired: boolean;
  useBroadcastOfferAccept: boolean;
  policyUrl: string;
  termUrl: string;
  isHotelMode: boolean;
  useBonuses: boolean;
  useFaq: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface GlobalSettingResponseDataObject {
  id?: number;
  attributes?: GlobalSetting;
}

export interface GlobalSettingResponse {
  data?: GlobalSettingResponseDataObject;
  meta?: object;
}

export interface IgnoredMenuItemModifierRequest {
  data: {
    iiko_id: string;
    description?: string;
  };
}

export interface IgnoredMenuItemModifierListResponseDataItem {
  id?: number;
  attributes?: IgnoredMenuItemModifier;
}

export interface IgnoredMenuItemModifierListResponse {
  data?: IgnoredMenuItemModifierListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface IgnoredMenuItemModifier {
  iiko_id: string;
  description?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface IgnoredMenuItemModifierResponseDataObject {
  id?: number;
  attributes?: IgnoredMenuItemModifier;
}

export interface IgnoredMenuItemModifierResponse {
  data?: IgnoredMenuItemModifierResponseDataObject;
  meta?: object;
}

export interface Initial {
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface InitialResponseDataObject {
  id?: number;
  attributes?: Initial;
}

export interface InitialResponse {
  data?: InitialResponseDataObject;
  meta?: object;
}

export interface MenuItemRequest {
  data: {
    /** @example "string or id" */
    product: number | string;
    /** @example "string or id" */
    shop: number | string;
    /** @format float */
    price: number;
    iiko_organization_id?: string;
    iiko_product_id?: string;
    groupModifiers?: MenuItemGroupModifierComponent[];
    isAvailable: boolean;
    timeLimit?: CommonOrderTimeLimitComponent;
  };
}

export interface MenuItemListResponseDataItem {
  id?: number;
  attributes?: MenuItem;
}

export interface MenuItemListResponse {
  data?: MenuItemListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface MenuItem {
  product: {
    data?: {
      id?: number;
      attributes?: {
        imageUrl?: string;
        title?: string;
        description?: string;
        category?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              rank?: number;
              iiko_id?: string;
              override_title?: string;
              timeLimit?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                startTime?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                endTime?: string;
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: {
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          name?: string;
                          code?: string;
                          description?: string;
                          users?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            }[];
                          };
                          permissions?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                action?: string;
                                actionParameters?: any;
                                subject?: string;
                                properties?: any;
                                conditions?: any;
                                role?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        params?: {
          id?: number;
          type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
          /** @format float */
          value?: number;
        }[];
        paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
        type?: "DISH" | "SERVICE" | "ITEM";
        iiko_id?: string;
        imageUrl_100?: string;
        imageUrl_200?: string;
        imageUrl_500?: string;
        override_title?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  shop: {
    data?: {
      id?: number;
      attributes?: {
        title?: string;
        address?: string;
        city?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              shops?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        coordinates?: {
          id?: number;
          /** @format float */
          longitude?: number;
          /** @format float */
          latitude?: number;
        };
        workTime?: (
          | {
              id?: number;
              __component?: string;
              time?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              day?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    shortTitle?: string;
                    dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
            }
          | {
              id?: number;
              __component?: string;
              day?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            }
        )[];
        menu_items?: {
          data?: {
            id?: number;
            attributes?: {
              product?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              groupModifiers?: {
                id?: number;
                minAmount?: number;
                maxAmount?: number;
                required?: boolean;
                defaultAmount?: number;
                freeOfChargeAmount?: number;
                childModifiers?: {
                  id?: number;
                  minAmount?: number;
                  maxAmount?: number;
                  defaultAmount?: number;
                  required?: boolean;
                  freeOfChargeAmount?: number;
                  modifier?: {
                    data?: {
                      id?: number;
                      attributes?: {
                        title?: string;
                        imageUrl?: string;
                        description?: string;
                        params?: {
                          id?: number;
                          type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                          /** @format float */
                          value?: number;
                        }[];
                        paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                        iiko_id?: string;
                        override_title?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        createdBy?: {
                          data?: {
                            id?: number;
                            attributes?: object;
                          };
                        };
                        updatedBy?: {
                          data?: {
                            id?: number;
                            attributes?: object;
                          };
                        };
                      };
                    };
                  };
                }[];
                modifier_category?: {
                  data?: {
                    id?: number;
                    attributes?: {
                      title?: string;
                      iiko_id?: string;
                      override_title?: string;
                      /** @format date-time */
                      createdAt?: string;
                      /** @format date-time */
                      updatedAt?: string;
                      createdBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                      updatedBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    };
                  };
                };
              }[];
              isAvailable?: boolean;
              timeLimit?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                startTime?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                endTime?: string;
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        useCutlery?: boolean;
        useBonuses?: boolean;
        usePromoCodes?: boolean;
        allowedOrderTypes?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        allowedPaymentTypes?: {
          id?: number;
          payment_type?: {
            data?: {
              id?: number;
              attributes?: {
                title?: string;
                paymentTypeCode?: "CARD" | "CASH";
                iiko_id?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                createdBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                updatedBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
              };
            };
          };
        }[];
        workTimeTitle?: string;
        menu_modifier_items?: {
          data?: {
            id?: number;
            attributes?: {
              modifier?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              isAvailable?: boolean;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        timezone?: string;
        minOrderDelayInMinutes?: number;
        firstOrderDelayInMinutes?: number;
        asapStepInMinutes?: number;
        specialWorkTimes?: {
          data?: {
            id?: number;
            attributes?: {
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
              timeRange?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              /** @format date */
              date?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryZones?: {
          id?: number;
          name?: string;
          minSum?: number;
          deliveryDurationInMinutes?: number;
          /** @format float */
          deliveryPrice?: number;
          polygon?: string;
          color?: string;
        }[];
        external_integration?: (
          | {
              id?: number;
              __component?: string;
              iiko_terminal_id?: string;
              is_iiko_terminal_available?: boolean;
              iiko_external_menu_id?: string;
              iiko_organization_id?: string;
              departmentId?: string;
            }
          | {
              id?: number;
              __component?: string;
              someField?: string;
            }
        )[];
        rank?: number;
        supplier_info?: {
          id?: number;
          phones?: string;
          name?: string;
          inn?: string;
        };
        enabled?: boolean;
        phoneForClients?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  /** @format float */
  price: number;
  iiko_organization_id?: string;
  iiko_product_id?: string;
  groupModifiers?: MenuItemGroupModifierComponent[];
  isAvailable: boolean;
  timeLimit?: CommonOrderTimeLimitComponent;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface MenuItemResponseDataObject {
  id?: number;
  attributes?: MenuItem;
}

export interface MenuItemResponse {
  data?: MenuItemResponseDataObject;
  meta?: object;
}

export interface MenuItemGroupModifierComponent {
  id?: number;
  minAmount?: number;
  maxAmount?: number;
  required?: boolean;
  defaultAmount?: number;
  freeOfChargeAmount?: number;
  childModifiers?: {
    id?: number;
    minAmount?: number;
    maxAmount?: number;
    defaultAmount?: number;
    required?: boolean;
    freeOfChargeAmount?: number;
    modifier?: {
      data?: {
        id?: number;
        attributes?: object;
      };
    };
  }[];
  modifier_category?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface MenuModifierItemRequest {
  data: {
    /** @example "string or id" */
    modifier: number | string;
    /** @example "string or id" */
    shop: number | string;
    /** @format float */
    price: number;
    iiko_organization_id?: string;
    iiko_product_id?: string;
    isAvailable: boolean;
  };
}

export interface MenuModifierItemListResponseDataItem {
  id?: number;
  attributes?: MenuModifierItem;
}

export interface MenuModifierItemListResponse {
  data?: MenuModifierItemListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface MenuModifierItem {
  modifier: {
    data?: {
      id?: number;
      attributes?: {
        title?: string;
        imageUrl?: string;
        description?: string;
        params?: {
          id?: number;
          type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
          /** @format float */
          value?: number;
        }[];
        paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
        iiko_id?: string;
        override_title?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: {
              firstname?: string;
              lastname?: string;
              username?: string;
              /** @format email */
              email?: string;
              resetPasswordToken?: string;
              registrationToken?: string;
              isActive?: boolean;
              roles?: {
                data?: {
                  id?: number;
                  attributes?: {
                    name?: string;
                    code?: string;
                    description?: string;
                    users?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    permissions?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          action?: string;
                          actionParameters?: any;
                          subject?: string;
                          properties?: any;
                          conditions?: any;
                          role?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              blocked?: boolean;
              preferedLanguage?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  shop: {
    data?: {
      id?: number;
      attributes?: {
        title?: string;
        address?: string;
        city?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              shops?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        coordinates?: {
          id?: number;
          /** @format float */
          longitude?: number;
          /** @format float */
          latitude?: number;
        };
        workTime?: (
          | {
              id?: number;
              __component?: string;
              time?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              day?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    shortTitle?: string;
                    dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
            }
          | {
              id?: number;
              __component?: string;
              day?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            }
        )[];
        menu_items?: {
          data?: {
            id?: number;
            attributes?: {
              product?: {
                data?: {
                  id?: number;
                  attributes?: {
                    imageUrl?: string;
                    title?: string;
                    description?: string;
                    category?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          rank?: number;
                          iiko_id?: string;
                          override_title?: string;
                          timeLimit?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            startTime?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            endTime?: string;
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    params?: {
                      id?: number;
                      type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                      /** @format float */
                      value?: number;
                    }[];
                    paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                    type?: "DISH" | "SERVICE" | "ITEM";
                    iiko_id?: string;
                    imageUrl_100?: string;
                    imageUrl_200?: string;
                    imageUrl_500?: string;
                    override_title?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              groupModifiers?: {
                id?: number;
                minAmount?: number;
                maxAmount?: number;
                required?: boolean;
                defaultAmount?: number;
                freeOfChargeAmount?: number;
                childModifiers?: {
                  id?: number;
                  minAmount?: number;
                  maxAmount?: number;
                  defaultAmount?: number;
                  required?: boolean;
                  freeOfChargeAmount?: number;
                  modifier?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                }[];
                modifier_category?: {
                  data?: {
                    id?: number;
                    attributes?: {
                      title?: string;
                      iiko_id?: string;
                      override_title?: string;
                      /** @format date-time */
                      createdAt?: string;
                      /** @format date-time */
                      updatedAt?: string;
                      createdBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                      updatedBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    };
                  };
                };
              }[];
              isAvailable?: boolean;
              timeLimit?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                startTime?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                endTime?: string;
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        useCutlery?: boolean;
        useBonuses?: boolean;
        usePromoCodes?: boolean;
        allowedOrderTypes?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        allowedPaymentTypes?: {
          id?: number;
          payment_type?: {
            data?: {
              id?: number;
              attributes?: {
                title?: string;
                paymentTypeCode?: "CARD" | "CASH";
                iiko_id?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                createdBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                updatedBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
              };
            };
          };
        }[];
        workTimeTitle?: string;
        menu_modifier_items?: {
          data?: {
            id?: number;
            attributes?: {
              modifier?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              isAvailable?: boolean;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        timezone?: string;
        minOrderDelayInMinutes?: number;
        firstOrderDelayInMinutes?: number;
        asapStepInMinutes?: number;
        specialWorkTimes?: {
          data?: {
            id?: number;
            attributes?: {
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
              timeRange?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              /** @format date */
              date?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryZones?: {
          id?: number;
          name?: string;
          minSum?: number;
          deliveryDurationInMinutes?: number;
          /** @format float */
          deliveryPrice?: number;
          polygon?: string;
          color?: string;
        }[];
        external_integration?: (
          | {
              id?: number;
              __component?: string;
              iiko_terminal_id?: string;
              is_iiko_terminal_available?: boolean;
              iiko_external_menu_id?: string;
              iiko_organization_id?: string;
              departmentId?: string;
            }
          | {
              id?: number;
              __component?: string;
              someField?: string;
            }
        )[];
        rank?: number;
        supplier_info?: {
          id?: number;
          phones?: string;
          name?: string;
          inn?: string;
        };
        enabled?: boolean;
        phoneForClients?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  /** @format float */
  price: number;
  iiko_organization_id?: string;
  iiko_product_id?: string;
  isAvailable: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface MenuModifierItemResponseDataObject {
  id?: number;
  attributes?: MenuModifierItem;
}

export interface MenuModifierItemResponse {
  data?: MenuModifierItemResponseDataObject;
  meta?: object;
}

export interface ModifierRequest {
  data: {
    title: string;
    imageUrl?: string;
    description?: string;
    params?: ProductProductParamsComponent[];
    paramCalculationType: "WEIGHT" | "VOLUME" | "NONE";
    iiko_id?: string;
    override_title?: string;
  };
}

export interface ModifierListResponseDataItem {
  id?: number;
  attributes?: Modifier;
}

export interface ModifierListResponse {
  data?: ModifierListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface Modifier {
  title: string;
  imageUrl?: string;
  description?: string;
  params?: ProductProductParamsComponent[];
  paramCalculationType: "WEIGHT" | "VOLUME" | "NONE";
  iiko_id?: string;
  override_title?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ModifierResponseDataObject {
  id?: number;
  attributes?: Modifier;
}

export interface ModifierResponse {
  data?: ModifierResponseDataObject;
  meta?: object;
}

export interface ProductProductParamsComponent {
  id?: number;
  type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
  /** @format float */
  value?: number;
}

export interface ModifierCategoryRequest {
  data: {
    title: string;
    iiko_id?: string;
    override_title?: string;
  };
}

export interface ModifierCategoryListResponseDataItem {
  id?: number;
  attributes?: ModifierCategory;
}

export interface ModifierCategoryListResponse {
  data?: ModifierCategoryListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface ModifierCategory {
  title: string;
  iiko_id?: string;
  override_title?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ModifierCategoryResponseDataObject {
  id?: number;
  attributes?: ModifierCategory;
}

export interface ModifierCategoryResponse {
  data?: ModifierCategoryResponseDataObject;
  meta?: object;
}

export interface OrderRequest {
  request: any;
  userId: number;
  status?: "NEW" | "CREATED" | "ERROR";
  order?: {
    data?: {
      id?: number;
      attributes?: {
        status?: string;
        user?: {
          data?: {
            id?: number;
            attributes?: {
              username?: string;
              /** @format email */
              email?: string;
              provider?: string;
              resetPasswordToken?: string;
              confirmationToken?: string;
              confirmed?: boolean;
              blocked?: boolean;
              role?: {
                data?: {
                  id?: number;
                  attributes?: {
                    name?: string;
                    description?: string;
                    type?: string;
                    permissions?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          action?: string;
                          role?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                firstname?: string;
                                lastname?: string;
                                username?: string;
                                /** @format email */
                                email?: string;
                                resetPasswordToken?: string;
                                registrationToken?: string;
                                isActive?: boolean;
                                roles?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      name?: string;
                                      code?: string;
                                      description?: string;
                                      users?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        }[];
                                      };
                                      permissions?: {
                                        data?: {
                                          id?: number;
                                          attributes?: {
                                            action?: string;
                                            actionParameters?: any;
                                            subject?: string;
                                            properties?: any;
                                            conditions?: any;
                                            role?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                            /** @format date-time */
                                            createdAt?: string;
                                            /** @format date-time */
                                            updatedAt?: string;
                                            createdBy?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                            updatedBy?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                          };
                                        }[];
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  }[];
                                };
                                blocked?: boolean;
                                preferedLanguage?: string;
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    users?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              expo_push_tokens?: {
                data?: {
                  id?: number;
                  attributes?: {
                    token?: string;
                    user?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              phone?: string;
              sex?: "MALE" | "FEMALE" | "NOT_SELECTED";
              /** @format date */
              birthday?: string;
              fio?: string;
              broadcastOfferAccepted?: boolean;
              registrationCompleted?: boolean;
              alfaClientId?: string;
              orders?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              deliveryAddresses?: {
                id?: number;
                city?: {
                  data?: {
                    id?: number;
                    attributes?: {
                      title?: string;
                      shops?: {
                        data?: {
                          id?: number;
                          attributes?: {
                            title?: string;
                            address?: string;
                            city?: {
                              data?: {
                                id?: number;
                                attributes?: object;
                              };
                            };
                            coordinates?: {
                              id?: number;
                              /** @format float */
                              longitude?: number;
                              /** @format float */
                              latitude?: number;
                            };
                            workTime?: (
                              | {
                                  id?: number;
                                  __component?: string;
                                  time?: {
                                    id?: number;
                                    /**
                                     * @format time
                                     * @example "12:54.000"
                                     */
                                    start?: string;
                                    /**
                                     * @format time
                                     * @example "12:54.000"
                                     */
                                    end?: string;
                                  };
                                  day?: {
                                    data?: {
                                      id?: number;
                                      attributes?: {
                                        title?: string;
                                        shortTitle?: string;
                                        dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                                        /** @format date-time */
                                        createdAt?: string;
                                        /** @format date-time */
                                        updatedAt?: string;
                                        createdBy?: {
                                          data?: {
                                            id?: number;
                                            attributes?: object;
                                          };
                                        };
                                        updatedBy?: {
                                          data?: {
                                            id?: number;
                                            attributes?: object;
                                          };
                                        };
                                      };
                                    };
                                  };
                                }
                              | {
                                  id?: number;
                                  __component?: string;
                                  day?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                }
                            )[];
                            menu_items?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  product?: {
                                    data?: {
                                      id?: number;
                                      attributes?: {
                                        imageUrl?: string;
                                        title?: string;
                                        description?: string;
                                        category?: {
                                          data?: {
                                            id?: number;
                                            attributes?: {
                                              title?: string;
                                              rank?: number;
                                              iiko_id?: string;
                                              override_title?: string;
                                              timeLimit?: {
                                                id?: number;
                                                /**
                                                 * @format time
                                                 * @example "12:54.000"
                                                 */
                                                startTime?: string;
                                                /**
                                                 * @format time
                                                 * @example "12:54.000"
                                                 */
                                                endTime?: string;
                                              };
                                              /** @format date-time */
                                              createdAt?: string;
                                              /** @format date-time */
                                              updatedAt?: string;
                                              createdBy?: {
                                                data?: {
                                                  id?: number;
                                                  attributes?: object;
                                                };
                                              };
                                              updatedBy?: {
                                                data?: {
                                                  id?: number;
                                                  attributes?: object;
                                                };
                                              };
                                            };
                                          };
                                        };
                                        params?: {
                                          id?: number;
                                          type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                          /** @format float */
                                          value?: number;
                                        }[];
                                        paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                        type?: "DISH" | "SERVICE" | "ITEM";
                                        iiko_id?: string;
                                        imageUrl_100?: string;
                                        imageUrl_200?: string;
                                        imageUrl_500?: string;
                                        override_title?: string;
                                        /** @format date-time */
                                        createdAt?: string;
                                        /** @format date-time */
                                        updatedAt?: string;
                                        createdBy?: {
                                          data?: {
                                            id?: number;
                                            attributes?: object;
                                          };
                                        };
                                        updatedBy?: {
                                          data?: {
                                            id?: number;
                                            attributes?: object;
                                          };
                                        };
                                      };
                                    };
                                  };
                                  shop?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  /** @format float */
                                  price?: number;
                                  iiko_organization_id?: string;
                                  iiko_product_id?: string;
                                  groupModifiers?: {
                                    id?: number;
                                    minAmount?: number;
                                    maxAmount?: number;
                                    required?: boolean;
                                    defaultAmount?: number;
                                    freeOfChargeAmount?: number;
                                    childModifiers?: {
                                      id?: number;
                                      minAmount?: number;
                                      maxAmount?: number;
                                      defaultAmount?: number;
                                      required?: boolean;
                                      freeOfChargeAmount?: number;
                                      modifier?: {
                                        data?: {
                                          id?: number;
                                          attributes?: {
                                            title?: string;
                                            imageUrl?: string;
                                            description?: string;
                                            params?: {
                                              id?: number;
                                              type?:
                                                | "PROTEIN"
                                                | "FAT"
                                                | "CARBOHYDRATE"
                                                | "CALORIES"
                                                | "WEIGHT"
                                                | "VOLUME";
                                              /** @format float */
                                              value?: number;
                                            }[];
                                            paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                            iiko_id?: string;
                                            override_title?: string;
                                            /** @format date-time */
                                            createdAt?: string;
                                            /** @format date-time */
                                            updatedAt?: string;
                                            createdBy?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                            updatedBy?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                          };
                                        };
                                      };
                                    }[];
                                    modifier_category?: {
                                      data?: {
                                        id?: number;
                                        attributes?: {
                                          title?: string;
                                          iiko_id?: string;
                                          override_title?: string;
                                          /** @format date-time */
                                          createdAt?: string;
                                          /** @format date-time */
                                          updatedAt?: string;
                                          createdBy?: {
                                            data?: {
                                              id?: number;
                                              attributes?: object;
                                            };
                                          };
                                          updatedBy?: {
                                            data?: {
                                              id?: number;
                                              attributes?: object;
                                            };
                                          };
                                        };
                                      };
                                    };
                                  }[];
                                  isAvailable?: boolean;
                                  timeLimit?: {
                                    id?: number;
                                    /**
                                     * @format time
                                     * @example "12:54.000"
                                     */
                                    startTime?: string;
                                    /**
                                     * @format time
                                     * @example "12:54.000"
                                     */
                                    endTime?: string;
                                  };
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              }[];
                            };
                            useCutlery?: boolean;
                            useBonuses?: boolean;
                            usePromoCodes?: boolean;
                            allowedOrderTypes?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  title?: string;
                                  orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
                                  iiko_id?: string;
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              }[];
                            };
                            allowedPaymentTypes?: {
                              id?: number;
                              payment_type?: {
                                data?: {
                                  id?: number;
                                  attributes?: {
                                    title?: string;
                                    paymentTypeCode?: "CARD" | "CASH";
                                    iiko_id?: string;
                                    /** @format date-time */
                                    createdAt?: string;
                                    /** @format date-time */
                                    updatedAt?: string;
                                    createdBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                    updatedBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                  };
                                };
                              };
                            }[];
                            workTimeTitle?: string;
                            menu_modifier_items?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  modifier?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  shop?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  /** @format float */
                                  price?: number;
                                  iiko_organization_id?: string;
                                  iiko_product_id?: string;
                                  isAvailable?: boolean;
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              }[];
                            };
                            timezone?: string;
                            minOrderDelayInMinutes?: number;
                            firstOrderDelayInMinutes?: number;
                            asapStepInMinutes?: number;
                            specialWorkTimes?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  shop?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
                                  timeRange?: {
                                    id?: number;
                                    /**
                                     * @format time
                                     * @example "12:54.000"
                                     */
                                    start?: string;
                                    /**
                                     * @format time
                                     * @example "12:54.000"
                                     */
                                    end?: string;
                                  };
                                  /** @format date */
                                  date?: string;
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              }[];
                            };
                            deliveryZones?: {
                              id?: number;
                              name?: string;
                              minSum?: number;
                              deliveryDurationInMinutes?: number;
                              /** @format float */
                              deliveryPrice?: number;
                              polygon?: string;
                              color?: string;
                            }[];
                            external_integration?: (
                              | {
                                  id?: number;
                                  __component?: string;
                                  iiko_terminal_id?: string;
                                  is_iiko_terminal_available?: boolean;
                                  iiko_external_menu_id?: string;
                                  iiko_organization_id?: string;
                                  departmentId?: string;
                                }
                              | {
                                  id?: number;
                                  __component?: string;
                                  someField?: string;
                                }
                            )[];
                            rank?: number;
                            supplier_info?: {
                              id?: number;
                              phones?: string;
                              name?: string;
                              inn?: string;
                            };
                            enabled?: boolean;
                            phoneForClients?: string;
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            createdBy?: {
                              data?: {
                                id?: number;
                                attributes?: object;
                              };
                            };
                            updatedBy?: {
                              data?: {
                                id?: number;
                                attributes?: object;
                              };
                            };
                          };
                        }[];
                      };
                      iiko_id?: string;
                      /** @format date-time */
                      createdAt?: string;
                      /** @format date-time */
                      updatedAt?: string;
                      createdBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                      updatedBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    };
                  };
                };
                type?: "WORK" | "HOME" | "OTHER";
                title?: string;
                address?: string;
                room?: string;
                entrance?: string;
                floor?: string;
                intercomCode?: string;
                comment?: string;
                coordinates?: {
                  id?: number;
                  /** @format float */
                  longitude?: number;
                  /** @format float */
                  latitude?: number;
                };
                geocodeDataSource?: any;
              }[];
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        /** @format float */
        total?: number;
        paymentType?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        orderType?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        shop?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        /** @format date */
        date?: string;
        /**
         * @format time
         * @example "12:54.000"
         */
        time?: string;
        alfaOrderId?: string;
        billUrl?: string;
        orderItems?: {
          id?: number;
          menuItem?: {
            data?: {
              id?: number;
              attributes?: object;
            };
          };
          amount?: number;
          /** @format float */
          price?: number;
          snapshot?: any;
          orderItemModifiers?: {
            id?: number;
            menu_modifier_item?: {
              data?: {
                id?: number;
                attributes?: object;
              };
            };
            amount?: number;
            /** @format float */
            price?: number;
            snapshot?: any;
            modifier_category?: {
              data?: {
                id?: number;
                attributes?: object;
              };
            };
            modifier_category_snapshot?: any;
          }[];
        }[];
        creditCardId?: string;
        params?: {
          id?: number;
          value?: string;
          type?: "ROOM_NUMBER";
        }[];
        iiko_order_id?: string;
        /** @format float */
        bonuses?: number;
        promoCode?: string;
        deliveryAddressSnapshot?: any;
        deliveryInfoJson?: any;
        /** @format float */
        totalItemsCost?: number;
        /** @format float */
        totalItemCostForPay?: number;
        /** @format float */
        itemsDiscount?: number;
        /** @format float */
        deliveryCost?: number;
        order_request?: {
          data?: {
            id?: number;
            attributes?: {
              request?: any;
              userId?: number;
              status?: "NEW" | "CREATED" | "ERROR";
              order?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        comment?: string;
        orderCookingStatus?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface OrderListResponseDataItem {
  id?: number;
  attributes?: Order;
}

export interface OrderListResponse {
  data?: OrderListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface Order {
  status: string;
  user?: {
    data?: {
      id?: number;
      attributes?: {
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              description?: string;
              type?: string;
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          firstname?: string;
                          lastname?: string;
                          username?: string;
                          /** @format email */
                          email?: string;
                          resetPasswordToken?: string;
                          registrationToken?: string;
                          isActive?: boolean;
                          roles?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                name?: string;
                                code?: string;
                                description?: string;
                                users?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  }[];
                                };
                                permissions?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      action?: string;
                                      actionParameters?: any;
                                      subject?: string;
                                      properties?: any;
                                      conditions?: any;
                                      role?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  }[];
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          blocked?: boolean;
                          preferedLanguage?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        expo_push_tokens?: {
          data?: {
            id?: number;
            attributes?: {
              token?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        phone?: string;
        sex?: "MALE" | "FEMALE" | "NOT_SELECTED";
        /** @format date */
        birthday?: string;
        fio?: string;
        broadcastOfferAccepted?: boolean;
        registrationCompleted?: boolean;
        alfaClientId?: string;
        orders?: {
          data?: {
            id?: number;
            attributes?: {
              status?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              total?: number;
              paymentType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    paymentTypeCode?: "CARD" | "CASH";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              orderType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    address?: string;
                    city?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          shops?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            }[];
                          };
                          iiko_id?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    coordinates?: {
                      id?: number;
                      /** @format float */
                      longitude?: number;
                      /** @format float */
                      latitude?: number;
                    };
                    workTime?: (
                      | {
                          id?: number;
                          __component?: string;
                          time?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                title?: string;
                                shortTitle?: string;
                                dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                        }
                      | {
                          id?: number;
                          __component?: string;
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        }
                    )[];
                    menu_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          product?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                imageUrl?: string;
                                title?: string;
                                description?: string;
                                category?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      title?: string;
                                      rank?: number;
                                      iiko_id?: string;
                                      override_title?: string;
                                      timeLimit?: {
                                        id?: number;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        startTime?: string;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        endTime?: string;
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  };
                                };
                                params?: {
                                  id?: number;
                                  type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                  /** @format float */
                                  value?: number;
                                }[];
                                paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                type?: "DISH" | "SERVICE" | "ITEM";
                                iiko_id?: string;
                                imageUrl_100?: string;
                                imageUrl_200?: string;
                                imageUrl_500?: string;
                                override_title?: string;
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          groupModifiers?: {
                            id?: number;
                            minAmount?: number;
                            maxAmount?: number;
                            required?: boolean;
                            defaultAmount?: number;
                            freeOfChargeAmount?: number;
                            childModifiers?: {
                              id?: number;
                              minAmount?: number;
                              maxAmount?: number;
                              defaultAmount?: number;
                              required?: boolean;
                              freeOfChargeAmount?: number;
                              modifier?: {
                                data?: {
                                  id?: number;
                                  attributes?: {
                                    title?: string;
                                    imageUrl?: string;
                                    description?: string;
                                    params?: {
                                      id?: number;
                                      type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                      /** @format float */
                                      value?: number;
                                    }[];
                                    paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                    iiko_id?: string;
                                    override_title?: string;
                                    /** @format date-time */
                                    createdAt?: string;
                                    /** @format date-time */
                                    updatedAt?: string;
                                    createdBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                    updatedBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                  };
                                };
                              };
                            }[];
                            modifier_category?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  title?: string;
                                  iiko_id?: string;
                                  override_title?: string;
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              };
                            };
                          }[];
                          isAvailable?: boolean;
                          timeLimit?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            startTime?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            endTime?: string;
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    useCutlery?: boolean;
                    useBonuses?: boolean;
                    usePromoCodes?: boolean;
                    allowedOrderTypes?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    allowedPaymentTypes?: {
                      id?: number;
                      payment_type?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    }[];
                    workTimeTitle?: string;
                    menu_modifier_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          modifier?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          isAvailable?: boolean;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    timezone?: string;
                    minOrderDelayInMinutes?: number;
                    firstOrderDelayInMinutes?: number;
                    asapStepInMinutes?: number;
                    specialWorkTimes?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
                          timeRange?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          /** @format date */
                          date?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    deliveryZones?: {
                      id?: number;
                      name?: string;
                      minSum?: number;
                      deliveryDurationInMinutes?: number;
                      /** @format float */
                      deliveryPrice?: number;
                      polygon?: string;
                      color?: string;
                    }[];
                    external_integration?: (
                      | {
                          id?: number;
                          __component?: string;
                          iiko_terminal_id?: string;
                          is_iiko_terminal_available?: boolean;
                          iiko_external_menu_id?: string;
                          iiko_organization_id?: string;
                          departmentId?: string;
                        }
                      | {
                          id?: number;
                          __component?: string;
                          someField?: string;
                        }
                    )[];
                    rank?: number;
                    supplier_info?: {
                      id?: number;
                      phones?: string;
                      name?: string;
                      inn?: string;
                    };
                    enabled?: boolean;
                    phoneForClients?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              /** @format date */
              date?: string;
              /**
               * @format time
               * @example "12:54.000"
               */
              time?: string;
              alfaOrderId?: string;
              billUrl?: string;
              orderItems?: {
                id?: number;
                menuItem?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                amount?: number;
                /** @format float */
                price?: number;
                snapshot?: any;
                orderItemModifiers?: {
                  id?: number;
                  menu_modifier_item?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  amount?: number;
                  /** @format float */
                  price?: number;
                  snapshot?: any;
                  modifier_category?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  modifier_category_snapshot?: any;
                }[];
              }[];
              creditCardId?: string;
              params?: {
                id?: number;
                value?: string;
                type?: "ROOM_NUMBER";
              }[];
              iiko_order_id?: string;
              /** @format float */
              bonuses?: number;
              promoCode?: string;
              deliveryAddressSnapshot?: any;
              deliveryInfoJson?: any;
              /** @format float */
              totalItemsCost?: number;
              /** @format float */
              totalItemCostForPay?: number;
              /** @format float */
              itemsDiscount?: number;
              /** @format float */
              deliveryCost?: number;
              order_request?: {
                data?: {
                  id?: number;
                  attributes?: {
                    request?: any;
                    userId?: number;
                    status?: "NEW" | "CREATED" | "ERROR";
                    order?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              comment?: string;
              orderCookingStatus?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryAddresses?: {
          id?: number;
          city?: {
            data?: {
              id?: number;
              attributes?: object;
            };
          };
          type?: "WORK" | "HOME" | "OTHER";
          title?: string;
          address?: string;
          room?: string;
          entrance?: string;
          floor?: string;
          intercomCode?: string;
          comment?: string;
          coordinates?: {
            id?: number;
            /** @format float */
            longitude?: number;
            /** @format float */
            latitude?: number;
          };
          geocodeDataSource?: any;
        }[];
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  /** @format float */
  total: number;
  paymentType?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  orderType?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  shop?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  /** @format date */
  date: string;
  /**
   * @format time
   * @example "12:54.000"
   */
  time: string;
  alfaOrderId?: string;
  billUrl?: string;
  orderItems: OrderOrderItemComponent[];
  creditCardId?: string;
  params?: OrderOrderParamComponent[];
  iiko_order_id?: string;
  /** @format float */
  bonuses?: number;
  promoCode?: string;
  deliveryAddressSnapshot?: any;
  deliveryInfoJson?: any;
  /** @format float */
  totalItemsCost: number;
  /** @format float */
  totalItemCostForPay: number;
  /** @format float */
  itemsDiscount: number;
  /** @format float */
  deliveryCost: number;
  order_request?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  comment?: string;
  orderCookingStatus?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface OrderResponseDataObject {
  id?: number;
  attributes?: Order;
}

export interface OrderResponse {
  data?: OrderResponseDataObject;
  meta?: object;
}

export interface OrderOrderItemComponent {
  id?: number;
  menuItem?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  amount?: number;
  /** @format float */
  price?: number;
  snapshot?: any;
  orderItemModifiers?: {
    id?: number;
    menu_modifier_item?: {
      data?: {
        id?: number;
        attributes?: object;
      };
    };
    amount?: number;
    /** @format float */
    price?: number;
    snapshot?: any;
    modifier_category?: {
      data?: {
        id?: number;
        attributes?: object;
      };
    };
    modifier_category_snapshot?: any;
  }[];
}

export interface OrderOrderParamComponent {
  id?: number;
  value?: string;
  type?: "ROOM_NUMBER";
}

export interface OrderRequestRequest {
  data: {
    request: any;
    userId: number;
    status?: "NEW" | "CREATED" | "ERROR";
    /** @example "string or id" */
    order?: number | string;
  };
}

export interface OrderRequestListResponseDataItem {
  id?: number;
  attributes?: OrderRequest;
}

export interface OrderRequestListResponse {
  data?: OrderRequestListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface OrderRequestResponseDataObject {
  id?: number;
  attributes?: OrderRequest;
}

export interface OrderRequestResponse {
  data?: OrderRequestResponseDataObject;
  meta?: object;
}

export interface OrderTypeRequest {
  data: {
    title: string;
    orderTypeCode: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
    iiko_id?: string;
  };
}

export interface OrderTypeListResponseDataItem {
  id?: number;
  attributes?: OrderType;
}

export interface OrderTypeListResponse {
  data?: OrderTypeListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface OrderType {
  title: string;
  orderTypeCode: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
  iiko_id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface OrderTypeResponseDataObject {
  id?: number;
  attributes?: OrderType;
}

export interface OrderTypeResponse {
  data?: OrderTypeResponseDataObject;
  meta?: object;
}

export interface PaymentTypeRequest {
  data: {
    title: string;
    paymentTypeCode: "CARD" | "CASH";
    iiko_id?: string;
  };
}

export interface PaymentTypeListResponseDataItem {
  id?: number;
  attributes?: PaymentType;
}

export interface PaymentTypeListResponse {
  data?: PaymentTypeListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface PaymentType {
  title: string;
  paymentTypeCode: "CARD" | "CASH";
  iiko_id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface PaymentTypeResponseDataObject {
  id?: number;
  attributes?: PaymentType;
}

export interface PaymentTypeResponse {
  data?: PaymentTypeResponseDataObject;
  meta?: object;
}

export interface ProductRequest {
  data: {
    imageUrl: string;
    title: string;
    description?: string;
    /** @example "string or id" */
    category: number | string;
    params?: ProductProductParamsComponent[];
    paramCalculationType: "WEIGHT" | "VOLUME" | "NONE";
    type: "DISH" | "SERVICE" | "ITEM";
    iiko_id?: string;
    imageUrl_100?: string;
    imageUrl_200?: string;
    imageUrl_500?: string;
    override_title?: string;
  };
}

export interface ProductListResponseDataItem {
  id?: number;
  attributes?: Product;
}

export interface ProductListResponse {
  data?: ProductListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface Product {
  imageUrl: string;
  title: string;
  description?: string;
  category: {
    data?: {
      id?: number;
      attributes?: {
        title?: string;
        rank?: number;
        iiko_id?: string;
        override_title?: string;
        timeLimit?: {
          id?: number;
          /**
           * @format time
           * @example "12:54.000"
           */
          startTime?: string;
          /**
           * @format time
           * @example "12:54.000"
           */
          endTime?: string;
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: {
              firstname?: string;
              lastname?: string;
              username?: string;
              /** @format email */
              email?: string;
              resetPasswordToken?: string;
              registrationToken?: string;
              isActive?: boolean;
              roles?: {
                data?: {
                  id?: number;
                  attributes?: {
                    name?: string;
                    code?: string;
                    description?: string;
                    users?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    permissions?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          action?: string;
                          actionParameters?: any;
                          subject?: string;
                          properties?: any;
                          conditions?: any;
                          role?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              blocked?: boolean;
              preferedLanguage?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  params?: ProductProductParamsComponent[];
  paramCalculationType: "WEIGHT" | "VOLUME" | "NONE";
  type: "DISH" | "SERVICE" | "ITEM";
  iiko_id?: string;
  imageUrl_100?: string;
  imageUrl_200?: string;
  imageUrl_500?: string;
  override_title?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ProductResponseDataObject {
  id?: number;
  attributes?: Product;
}

export interface ProductResponse {
  data?: ProductResponseDataObject;
  meta?: object;
}

export interface ShopRequest {
  data: {
    title: string;
    address: string;
    /** @example "string or id" */
    city: number | string;
    coordinates?: CommonCoordinatesComponent;
    workTime: (
      | CommonShopWorkDayTimedComponent
      | CommonShopWorkDayAroundTheClockComponent
      | CommonShopWorkDayOffComponent
    )[];
    menu_items?: (number | string)[];
    useCutlery: boolean;
    useBonuses: boolean;
    usePromoCodes: boolean;
    allowedOrderTypes?: (number | string)[];
    allowedPaymentTypes?: ShopAllowedPaymentTypeComponent[];
    workTimeTitle: string;
    menu_modifier_items?: (number | string)[];
    timezone: string;
    minOrderDelayInMinutes: number;
    firstOrderDelayInMinutes: number;
    asapStepInMinutes: number;
    specialWorkTimes?: (number | string)[];
    deliveryZones?: ShopDeliveryZoneComponent[];
    external_integration?: (ShopIikoIntegrationComponent | ShopRkeeperIntegrationComponent)[];
    rank?: number;
    supplier_info: ShopSupplierInfoComponent;
    enabled: boolean;
    phoneForClients: string;
  };
}

export interface ShopListResponseDataItem {
  id?: number;
  attributes?: Shop;
}

export interface ShopListResponse {
  data?: ShopListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface Shop {
  title: string;
  address: string;
  city: {
    data?: {
      id?: number;
      attributes?: {
        title?: string;
        shops?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              address?: string;
              city?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              coordinates?: {
                id?: number;
                /** @format float */
                longitude?: number;
                /** @format float */
                latitude?: number;
              };
              workTime?: (
                | {
                    id?: number;
                    __component?: string;
                    time?: {
                      id?: number;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      start?: string;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      end?: string;
                    };
                    day?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          shortTitle?: string;
                          dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                firstname?: string;
                                lastname?: string;
                                username?: string;
                                /** @format email */
                                email?: string;
                                resetPasswordToken?: string;
                                registrationToken?: string;
                                isActive?: boolean;
                                roles?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      name?: string;
                                      code?: string;
                                      description?: string;
                                      users?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        }[];
                                      };
                                      permissions?: {
                                        data?: {
                                          id?: number;
                                          attributes?: {
                                            action?: string;
                                            actionParameters?: any;
                                            subject?: string;
                                            properties?: any;
                                            conditions?: any;
                                            role?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                            /** @format date-time */
                                            createdAt?: string;
                                            /** @format date-time */
                                            updatedAt?: string;
                                            createdBy?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                            updatedBy?: {
                                              data?: {
                                                id?: number;
                                                attributes?: object;
                                              };
                                            };
                                          };
                                        }[];
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  }[];
                                };
                                blocked?: boolean;
                                preferedLanguage?: string;
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                  }
                | {
                    id?: number;
                    __component?: string;
                    day?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  }
              )[];
              menu_items?: {
                data?: {
                  id?: number;
                  attributes?: {
                    product?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          imageUrl?: string;
                          title?: string;
                          description?: string;
                          category?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                title?: string;
                                rank?: number;
                                iiko_id?: string;
                                override_title?: string;
                                timeLimit?: {
                                  id?: number;
                                  /**
                                   * @format time
                                   * @example "12:54.000"
                                   */
                                  startTime?: string;
                                  /**
                                   * @format time
                                   * @example "12:54.000"
                                   */
                                  endTime?: string;
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                          params?: {
                            id?: number;
                            type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                            /** @format float */
                            value?: number;
                          }[];
                          paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                          type?: "DISH" | "SERVICE" | "ITEM";
                          iiko_id?: string;
                          imageUrl_100?: string;
                          imageUrl_200?: string;
                          imageUrl_500?: string;
                          override_title?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    shop?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format float */
                    price?: number;
                    iiko_organization_id?: string;
                    iiko_product_id?: string;
                    groupModifiers?: {
                      id?: number;
                      minAmount?: number;
                      maxAmount?: number;
                      required?: boolean;
                      defaultAmount?: number;
                      freeOfChargeAmount?: number;
                      childModifiers?: {
                        id?: number;
                        minAmount?: number;
                        maxAmount?: number;
                        defaultAmount?: number;
                        required?: boolean;
                        freeOfChargeAmount?: number;
                        modifier?: {
                          data?: {
                            id?: number;
                            attributes?: {
                              title?: string;
                              imageUrl?: string;
                              description?: string;
                              params?: {
                                id?: number;
                                type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                /** @format float */
                                value?: number;
                              }[];
                              paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                              iiko_id?: string;
                              override_title?: string;
                              /** @format date-time */
                              createdAt?: string;
                              /** @format date-time */
                              updatedAt?: string;
                              createdBy?: {
                                data?: {
                                  id?: number;
                                  attributes?: object;
                                };
                              };
                              updatedBy?: {
                                data?: {
                                  id?: number;
                                  attributes?: object;
                                };
                              };
                            };
                          };
                        };
                      }[];
                      modifier_category?: {
                        data?: {
                          id?: number;
                          attributes?: {
                            title?: string;
                            iiko_id?: string;
                            override_title?: string;
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            createdBy?: {
                              data?: {
                                id?: number;
                                attributes?: object;
                              };
                            };
                            updatedBy?: {
                              data?: {
                                id?: number;
                                attributes?: object;
                              };
                            };
                          };
                        };
                      };
                    }[];
                    isAvailable?: boolean;
                    timeLimit?: {
                      id?: number;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      startTime?: string;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      endTime?: string;
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              useCutlery?: boolean;
              useBonuses?: boolean;
              usePromoCodes?: boolean;
              allowedOrderTypes?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              allowedPaymentTypes?: {
                id?: number;
                payment_type?: {
                  data?: {
                    id?: number;
                    attributes?: {
                      title?: string;
                      paymentTypeCode?: "CARD" | "CASH";
                      iiko_id?: string;
                      /** @format date-time */
                      createdAt?: string;
                      /** @format date-time */
                      updatedAt?: string;
                      createdBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                      updatedBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    };
                  };
                };
              }[];
              workTimeTitle?: string;
              menu_modifier_items?: {
                data?: {
                  id?: number;
                  attributes?: {
                    modifier?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    shop?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format float */
                    price?: number;
                    iiko_organization_id?: string;
                    iiko_product_id?: string;
                    isAvailable?: boolean;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              timezone?: string;
              minOrderDelayInMinutes?: number;
              firstOrderDelayInMinutes?: number;
              asapStepInMinutes?: number;
              specialWorkTimes?: {
                data?: {
                  id?: number;
                  attributes?: {
                    shop?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
                    timeRange?: {
                      id?: number;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      start?: string;
                      /**
                       * @format time
                       * @example "12:54.000"
                       */
                      end?: string;
                    };
                    /** @format date */
                    date?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              deliveryZones?: {
                id?: number;
                name?: string;
                minSum?: number;
                deliveryDurationInMinutes?: number;
                /** @format float */
                deliveryPrice?: number;
                polygon?: string;
                color?: string;
              }[];
              external_integration?: (
                | {
                    id?: number;
                    __component?: string;
                    iiko_terminal_id?: string;
                    is_iiko_terminal_available?: boolean;
                    iiko_external_menu_id?: string;
                    iiko_organization_id?: string;
                    departmentId?: string;
                  }
                | {
                    id?: number;
                    __component?: string;
                    someField?: string;
                  }
              )[];
              rank?: number;
              supplier_info?: {
                id?: number;
                phones?: string;
                name?: string;
                inn?: string;
              };
              enabled?: boolean;
              phoneForClients?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        iiko_id?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  coordinates?: CommonCoordinatesComponent;
  workTime: (
    | CommonShopWorkDayTimedComponent
    | CommonShopWorkDayAroundTheClockComponent
    | CommonShopWorkDayOffComponent
  )[];
  menu_items?: {
    data?: {
      id?: number;
      attributes?: object;
    }[];
  };
  useCutlery: boolean;
  useBonuses: boolean;
  usePromoCodes: boolean;
  allowedOrderTypes?: {
    data?: {
      id?: number;
      attributes?: object;
    }[];
  };
  allowedPaymentTypes?: ShopAllowedPaymentTypeComponent[];
  workTimeTitle: string;
  menu_modifier_items?: {
    data?: {
      id?: number;
      attributes?: object;
    }[];
  };
  timezone: string;
  minOrderDelayInMinutes: number;
  firstOrderDelayInMinutes: number;
  asapStepInMinutes: number;
  specialWorkTimes?: {
    data?: {
      id?: number;
      attributes?: object;
    }[];
  };
  deliveryZones?: ShopDeliveryZoneComponent[];
  external_integration?: (ShopIikoIntegrationComponent | ShopRkeeperIntegrationComponent)[];
  rank?: number;
  supplier_info: ShopSupplierInfoComponent;
  enabled: boolean;
  phoneForClients: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ShopResponseDataObject {
  id?: number;
  attributes?: Shop;
}

export interface ShopResponse {
  data?: ShopResponseDataObject;
  meta?: object;
}

export interface CommonCoordinatesComponent {
  id?: number;
  /** @format float */
  longitude?: number;
  /** @format float */
  latitude?: number;
}

export interface CommonTimeRangeComponent {
  id?: number;
  /**
   * @format time
   * @example "12:54.000"
   */
  start?: string;
  /**
   * @format time
   * @example "12:54.000"
   */
  end?: string;
}

export interface CommonShopWorkDayTimedComponent {
  id?: number;
  __component?: string;
  time?: CommonTimeRangeComponent;
  day?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface CommonShopWorkDayAroundTheClockComponent {
  id?: number;
  __component?: string;
  day?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface CommonShopWorkDayOffComponent {
  id?: number;
  __component?: string;
  day?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ShopAllowedPaymentTypeComponent {
  id?: number;
  payment_type?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface ShopDeliveryZoneComponent {
  id?: number;
  name?: string;
  minSum?: number;
  deliveryDurationInMinutes?: number;
  /** @format float */
  deliveryPrice?: number;
  polygon?: string;
  color?: string;
}

export interface ShopIikoIntegrationComponent {
  id?: number;
  __component?: string;
  iiko_terminal_id?: string;
  is_iiko_terminal_available?: boolean;
  iiko_external_menu_id?: string;
  iiko_organization_id?: string;
  departmentId?: string;
}

export interface ShopRkeeperIntegrationComponent {
  id?: number;
  __component?: string;
  someField?: string;
}

export interface ShopSupplierInfoComponent {
  id?: number;
  phones?: string;
  name?: string;
  inn?: string;
}

export interface SpecialShopWorkTimeRequest {
  data: {
    /** @example "string or id" */
    shop?: number | string;
    type: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
    timeRange?: CommonTimeRangeComponent;
    /** @format date */
    date: string;
  };
}

export interface SpecialShopWorkTimeListResponseDataItem {
  id?: number;
  attributes?: SpecialShopWorkTime;
}

export interface SpecialShopWorkTimeListResponse {
  data?: SpecialShopWorkTimeListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface SpecialShopWorkTime {
  shop?: {
    data?: {
      id?: number;
      attributes?: {
        title?: string;
        address?: string;
        city?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              shops?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: {
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          name?: string;
                          code?: string;
                          description?: string;
                          users?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            }[];
                          };
                          permissions?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                action?: string;
                                actionParameters?: any;
                                subject?: string;
                                properties?: any;
                                conditions?: any;
                                role?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        coordinates?: {
          id?: number;
          /** @format float */
          longitude?: number;
          /** @format float */
          latitude?: number;
        };
        workTime?: (
          | {
              id?: number;
              __component?: string;
              time?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              day?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    shortTitle?: string;
                    dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
            }
          | {
              id?: number;
              __component?: string;
              day?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            }
        )[];
        menu_items?: {
          data?: {
            id?: number;
            attributes?: {
              product?: {
                data?: {
                  id?: number;
                  attributes?: {
                    imageUrl?: string;
                    title?: string;
                    description?: string;
                    category?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          rank?: number;
                          iiko_id?: string;
                          override_title?: string;
                          timeLimit?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            startTime?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            endTime?: string;
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    params?: {
                      id?: number;
                      type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                      /** @format float */
                      value?: number;
                    }[];
                    paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                    type?: "DISH" | "SERVICE" | "ITEM";
                    iiko_id?: string;
                    imageUrl_100?: string;
                    imageUrl_200?: string;
                    imageUrl_500?: string;
                    override_title?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              groupModifiers?: {
                id?: number;
                minAmount?: number;
                maxAmount?: number;
                required?: boolean;
                defaultAmount?: number;
                freeOfChargeAmount?: number;
                childModifiers?: {
                  id?: number;
                  minAmount?: number;
                  maxAmount?: number;
                  defaultAmount?: number;
                  required?: boolean;
                  freeOfChargeAmount?: number;
                  modifier?: {
                    data?: {
                      id?: number;
                      attributes?: {
                        title?: string;
                        imageUrl?: string;
                        description?: string;
                        params?: {
                          id?: number;
                          type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                          /** @format float */
                          value?: number;
                        }[];
                        paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                        iiko_id?: string;
                        override_title?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        createdBy?: {
                          data?: {
                            id?: number;
                            attributes?: object;
                          };
                        };
                        updatedBy?: {
                          data?: {
                            id?: number;
                            attributes?: object;
                          };
                        };
                      };
                    };
                  };
                }[];
                modifier_category?: {
                  data?: {
                    id?: number;
                    attributes?: {
                      title?: string;
                      iiko_id?: string;
                      override_title?: string;
                      /** @format date-time */
                      createdAt?: string;
                      /** @format date-time */
                      updatedAt?: string;
                      createdBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                      updatedBy?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    };
                  };
                };
              }[];
              isAvailable?: boolean;
              timeLimit?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                startTime?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                endTime?: string;
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        useCutlery?: boolean;
        useBonuses?: boolean;
        usePromoCodes?: boolean;
        allowedOrderTypes?: {
          data?: {
            id?: number;
            attributes?: {
              title?: string;
              orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
              iiko_id?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        allowedPaymentTypes?: {
          id?: number;
          payment_type?: {
            data?: {
              id?: number;
              attributes?: {
                title?: string;
                paymentTypeCode?: "CARD" | "CASH";
                iiko_id?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                createdBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                updatedBy?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
              };
            };
          };
        }[];
        workTimeTitle?: string;
        menu_modifier_items?: {
          data?: {
            id?: number;
            attributes?: {
              modifier?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              price?: number;
              iiko_organization_id?: string;
              iiko_product_id?: string;
              isAvailable?: boolean;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        timezone?: string;
        minOrderDelayInMinutes?: number;
        firstOrderDelayInMinutes?: number;
        asapStepInMinutes?: number;
        specialWorkTimes?: {
          data?: {
            id?: number;
            attributes?: {
              shop?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
              timeRange?: {
                id?: number;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                start?: string;
                /**
                 * @format time
                 * @example "12:54.000"
                 */
                end?: string;
              };
              /** @format date */
              date?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryZones?: {
          id?: number;
          name?: string;
          minSum?: number;
          deliveryDurationInMinutes?: number;
          /** @format float */
          deliveryPrice?: number;
          polygon?: string;
          color?: string;
        }[];
        external_integration?: (
          | {
              id?: number;
              __component?: string;
              iiko_terminal_id?: string;
              is_iiko_terminal_available?: boolean;
              iiko_external_menu_id?: string;
              iiko_organization_id?: string;
              departmentId?: string;
            }
          | {
              id?: number;
              __component?: string;
              someField?: string;
            }
        )[];
        rank?: number;
        supplier_info?: {
          id?: number;
          phones?: string;
          name?: string;
          inn?: string;
        };
        enabled?: boolean;
        phoneForClients?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  type: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
  timeRange?: CommonTimeRangeComponent;
  /** @format date */
  date: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface SpecialShopWorkTimeResponseDataObject {
  id?: number;
  attributes?: SpecialShopWorkTime;
}

export interface SpecialShopWorkTimeResponse {
  data?: SpecialShopWorkTimeResponseDataObject;
  meta?: object;
}

export interface UserCreditCardRequest {
  data: {
    bindingId: string;
    maskedPan: string;
    expiryDate: string;
    paymentWay: string;
    displayLabel: string;
    clientId: string;
  };
}

export interface UserCreditCardListResponseDataItem {
  id?: number;
  attributes?: UserCreditCard;
}

export interface UserCreditCardListResponse {
  data?: UserCreditCardListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface UserCreditCard {
  bindingId: string;
  maskedPan: string;
  expiryDate: string;
  paymentWay: string;
  displayLabel: string;
  clientId: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: {
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              code?: string;
              description?: string;
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    actionParameters?: any;
                    subject?: string;
                    properties?: any;
                    conditions?: any;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface UserCreditCardResponseDataObject {
  id?: number;
  attributes?: UserCreditCard;
}

export interface UserCreditCardResponse {
  data?: UserCreditCardResponseDataObject;
  meta?: object;
}

export interface UserFavoriteListRequest {
  data: {
    /** @example "string or id" */
    user?: number | string;
    productId: string;
    productAmount: string;
    modifiersData?: OrderModifierDataComponent[];
  };
}

export interface UserFavoriteListListResponseDataItem {
  id?: number;
  attributes?: UserFavoriteList;
}

export interface UserFavoriteListListResponse {
  data?: UserFavoriteListListResponseDataItem[];
  meta?: {
    pagination?: {
      page?: number;
      /** @min 25 */
      pageSize?: number;
      /** @max 1 */
      pageCount?: number;
      total?: number;
    };
  };
}

export interface UserFavoriteList {
  user?: {
    data?: {
      id?: number;
      attributes?: {
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
          data?: {
            id?: number;
            attributes?: {
              name?: string;
              description?: string;
              type?: string;
              permissions?: {
                data?: {
                  id?: number;
                  attributes?: {
                    action?: string;
                    role?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          firstname?: string;
                          lastname?: string;
                          username?: string;
                          /** @format email */
                          email?: string;
                          resetPasswordToken?: string;
                          registrationToken?: string;
                          isActive?: boolean;
                          roles?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                name?: string;
                                code?: string;
                                description?: string;
                                users?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  }[];
                                };
                                permissions?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      action?: string;
                                      actionParameters?: any;
                                      subject?: string;
                                      properties?: any;
                                      conditions?: any;
                                      role?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  }[];
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            }[];
                          };
                          blocked?: boolean;
                          preferedLanguage?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                }[];
              };
              users?: {
                data?: {
                  id?: number;
                  attributes?: object;
                }[];
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          };
        };
        expo_push_tokens?: {
          data?: {
            id?: number;
            attributes?: {
              token?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        phone?: string;
        sex?: "MALE" | "FEMALE" | "NOT_SELECTED";
        /** @format date */
        birthday?: string;
        fio?: string;
        broadcastOfferAccepted?: boolean;
        registrationCompleted?: boolean;
        alfaClientId?: string;
        orders?: {
          data?: {
            id?: number;
            attributes?: {
              status?: string;
              user?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              /** @format float */
              total?: number;
              paymentType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    paymentTypeCode?: "CARD" | "CASH";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              orderType?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    orderTypeCode?: "TAKE_AWAY" | "DINE_IN" | "DELIVERY" | "IN_ROOM";
                    iiko_id?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              shop?: {
                data?: {
                  id?: number;
                  attributes?: {
                    title?: string;
                    address?: string;
                    city?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          title?: string;
                          shops?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            }[];
                          };
                          iiko_id?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      };
                    };
                    coordinates?: {
                      id?: number;
                      /** @format float */
                      longitude?: number;
                      /** @format float */
                      latitude?: number;
                    };
                    workTime?: (
                      | {
                          id?: number;
                          __component?: string;
                          time?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                title?: string;
                                shortTitle?: string;
                                dayCode?: "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                        }
                      | {
                          id?: number;
                          __component?: string;
                          day?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        }
                    )[];
                    menu_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          product?: {
                            data?: {
                              id?: number;
                              attributes?: {
                                imageUrl?: string;
                                title?: string;
                                description?: string;
                                category?: {
                                  data?: {
                                    id?: number;
                                    attributes?: {
                                      title?: string;
                                      rank?: number;
                                      iiko_id?: string;
                                      override_title?: string;
                                      timeLimit?: {
                                        id?: number;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        startTime?: string;
                                        /**
                                         * @format time
                                         * @example "12:54.000"
                                         */
                                        endTime?: string;
                                      };
                                      /** @format date-time */
                                      createdAt?: string;
                                      /** @format date-time */
                                      updatedAt?: string;
                                      createdBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                      updatedBy?: {
                                        data?: {
                                          id?: number;
                                          attributes?: object;
                                        };
                                      };
                                    };
                                  };
                                };
                                params?: {
                                  id?: number;
                                  type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                  /** @format float */
                                  value?: number;
                                }[];
                                paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                type?: "DISH" | "SERVICE" | "ITEM";
                                iiko_id?: string;
                                imageUrl_100?: string;
                                imageUrl_200?: string;
                                imageUrl_500?: string;
                                override_title?: string;
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                createdBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                                updatedBy?: {
                                  data?: {
                                    id?: number;
                                    attributes?: object;
                                  };
                                };
                              };
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          groupModifiers?: {
                            id?: number;
                            minAmount?: number;
                            maxAmount?: number;
                            required?: boolean;
                            defaultAmount?: number;
                            freeOfChargeAmount?: number;
                            childModifiers?: {
                              id?: number;
                              minAmount?: number;
                              maxAmount?: number;
                              defaultAmount?: number;
                              required?: boolean;
                              freeOfChargeAmount?: number;
                              modifier?: {
                                data?: {
                                  id?: number;
                                  attributes?: {
                                    title?: string;
                                    imageUrl?: string;
                                    description?: string;
                                    params?: {
                                      id?: number;
                                      type?: "PROTEIN" | "FAT" | "CARBOHYDRATE" | "CALORIES" | "WEIGHT" | "VOLUME";
                                      /** @format float */
                                      value?: number;
                                    }[];
                                    paramCalculationType?: "WEIGHT" | "VOLUME" | "NONE";
                                    iiko_id?: string;
                                    override_title?: string;
                                    /** @format date-time */
                                    createdAt?: string;
                                    /** @format date-time */
                                    updatedAt?: string;
                                    createdBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                    updatedBy?: {
                                      data?: {
                                        id?: number;
                                        attributes?: object;
                                      };
                                    };
                                  };
                                };
                              };
                            }[];
                            modifier_category?: {
                              data?: {
                                id?: number;
                                attributes?: {
                                  title?: string;
                                  iiko_id?: string;
                                  override_title?: string;
                                  /** @format date-time */
                                  createdAt?: string;
                                  /** @format date-time */
                                  updatedAt?: string;
                                  createdBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                  updatedBy?: {
                                    data?: {
                                      id?: number;
                                      attributes?: object;
                                    };
                                  };
                                };
                              };
                            };
                          }[];
                          isAvailable?: boolean;
                          timeLimit?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            startTime?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            endTime?: string;
                          };
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    useCutlery?: boolean;
                    useBonuses?: boolean;
                    usePromoCodes?: boolean;
                    allowedOrderTypes?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      }[];
                    };
                    allowedPaymentTypes?: {
                      id?: number;
                      payment_type?: {
                        data?: {
                          id?: number;
                          attributes?: object;
                        };
                      };
                    }[];
                    workTimeTitle?: string;
                    menu_modifier_items?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          modifier?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          /** @format float */
                          price?: number;
                          iiko_organization_id?: string;
                          iiko_product_id?: string;
                          isAvailable?: boolean;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    timezone?: string;
                    minOrderDelayInMinutes?: number;
                    firstOrderDelayInMinutes?: number;
                    asapStepInMinutes?: number;
                    specialWorkTimes?: {
                      data?: {
                        id?: number;
                        attributes?: {
                          shop?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          type?: "TIMED" | "DAY_OFF" | "AROUNT_THE_CLOCK";
                          timeRange?: {
                            id?: number;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            start?: string;
                            /**
                             * @format time
                             * @example "12:54.000"
                             */
                            end?: string;
                          };
                          /** @format date */
                          date?: string;
                          /** @format date-time */
                          createdAt?: string;
                          /** @format date-time */
                          updatedAt?: string;
                          createdBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                          updatedBy?: {
                            data?: {
                              id?: number;
                              attributes?: object;
                            };
                          };
                        };
                      }[];
                    };
                    deliveryZones?: {
                      id?: number;
                      name?: string;
                      minSum?: number;
                      deliveryDurationInMinutes?: number;
                      /** @format float */
                      deliveryPrice?: number;
                      polygon?: string;
                      color?: string;
                    }[];
                    external_integration?: (
                      | {
                          id?: number;
                          __component?: string;
                          iiko_terminal_id?: string;
                          is_iiko_terminal_available?: boolean;
                          iiko_external_menu_id?: string;
                          iiko_organization_id?: string;
                          departmentId?: string;
                        }
                      | {
                          id?: number;
                          __component?: string;
                          someField?: string;
                        }
                    )[];
                    rank?: number;
                    supplier_info?: {
                      id?: number;
                      phones?: string;
                      name?: string;
                      inn?: string;
                    };
                    enabled?: boolean;
                    phoneForClients?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              /** @format date */
              date?: string;
              /**
               * @format time
               * @example "12:54.000"
               */
              time?: string;
              alfaOrderId?: string;
              billUrl?: string;
              orderItems?: {
                id?: number;
                menuItem?: {
                  data?: {
                    id?: number;
                    attributes?: object;
                  };
                };
                amount?: number;
                /** @format float */
                price?: number;
                snapshot?: any;
                orderItemModifiers?: {
                  id?: number;
                  menu_modifier_item?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  amount?: number;
                  /** @format float */
                  price?: number;
                  snapshot?: any;
                  modifier_category?: {
                    data?: {
                      id?: number;
                      attributes?: object;
                    };
                  };
                  modifier_category_snapshot?: any;
                }[];
              }[];
              creditCardId?: string;
              params?: {
                id?: number;
                value?: string;
                type?: "ROOM_NUMBER";
              }[];
              iiko_order_id?: string;
              /** @format float */
              bonuses?: number;
              promoCode?: string;
              deliveryAddressSnapshot?: any;
              deliveryInfoJson?: any;
              /** @format float */
              totalItemsCost?: number;
              /** @format float */
              totalItemCostForPay?: number;
              /** @format float */
              itemsDiscount?: number;
              /** @format float */
              deliveryCost?: number;
              order_request?: {
                data?: {
                  id?: number;
                  attributes?: {
                    request?: any;
                    userId?: number;
                    status?: "NEW" | "CREATED" | "ERROR";
                    order?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    createdBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                    updatedBy?: {
                      data?: {
                        id?: number;
                        attributes?: object;
                      };
                    };
                  };
                };
              };
              comment?: string;
              orderCookingStatus?: string;
              /** @format date-time */
              createdAt?: string;
              /** @format date-time */
              updatedAt?: string;
              createdBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
              updatedBy?: {
                data?: {
                  id?: number;
                  attributes?: object;
                };
              };
            };
          }[];
        };
        deliveryAddresses?: {
          id?: number;
          city?: {
            data?: {
              id?: number;
              attributes?: object;
            };
          };
          type?: "WORK" | "HOME" | "OTHER";
          title?: string;
          address?: string;
          room?: string;
          entrance?: string;
          floor?: string;
          intercomCode?: string;
          comment?: string;
          coordinates?: {
            id?: number;
            /** @format float */
            longitude?: number;
            /** @format float */
            latitude?: number;
          };
          geocodeDataSource?: any;
        }[];
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        createdBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
        updatedBy?: {
          data?: {
            id?: number;
            attributes?: object;
          };
        };
      };
    };
  };
  productId: string;
  productAmount: string;
  modifiersData?: OrderModifierDataComponent[];
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  createdBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
  updatedBy?: {
    data?: {
      id?: number;
      attributes?: object;
    };
  };
}

export interface UserFavoriteListResponseDataObject {
  id?: number;
  attributes?: UserFavoriteList;
}

export interface UserFavoriteListResponse {
  data?: UserFavoriteListResponseDataObject;
  meta?: object;
}

export interface OrderModifierDataComponent {
  id?: number;
  modifierId?: string;
  modifierAmount?: string;
}

export interface UploadFile {
  id?: number;
  name?: string;
  alternativeText?: string;
  caption?: string;
  /** @format integer */
  width?: number;
  /** @format integer */
  height?: number;
  formats?: number;
  hash?: string;
  ext?: string;
  mime?: string;
  /** @format double */
  size?: number;
  url?: string;
  previewUrl?: string;
  provider?: string;
  provider_metadata?: object;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export interface UsersPermissionsRole {
  id?: number;
  name?: string;
  description?: string;
  type?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export interface UsersPermissionsUser {
  /** @example 1 */
  id?: number;
  /** @example "foo.bar" */
  username?: string;
  /** @example "foo.bar@strapi.io" */
  email?: string;
  /** @example "local" */
  provider?: string;
  /** @example true */
  confirmed?: boolean;
  /** @example false */
  blocked?: boolean;
  /**
   * @format date-time
   * @example "2022-06-02T08:32:06.258Z"
   */
  createdAt?: string;
  /**
   * @format date-time
   * @example "2022-06-02T08:32:06.267Z"
   */
  updatedAt?: string;
}

export interface UsersPermissionsUserRegistration {
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" */
  jwt?: string;
  user?: UsersPermissionsUser;
}

export type UsersPermissionsPermissionsTree = Record<
  string,
  {
    /** every controller of the api */
    controllers?: Record<
      string,
      Record<
        string,
        {
          enabled?: boolean;
          policy?: string;
        }
      >
    >;
  }
>;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://localhost:1337/api" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title DOCUMENTATION
 * @version 1.0.0
 * @license Apache 2.0 (https://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService YOUR_TERMS_OF_SERVICE_URL
 * @baseUrl http://localhost:1337/api
 * @externalDocs https://docs.strapi.io/developer-docs/latest/getting-started/introduction.html
 * @contact TEAM <contact-email@something.io> (mywebsite.io)
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accountRemoveRequests = {
    /**
     * No description
     *
     * @tags Account-remove-request
     * @name GetAccountRemoveRequests
     * @request GET:/account-remove-requests
     * @secure
     */
    getAccountRemoveRequests: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AccountRemoveRequestListResponse, Error>({
        path: `/account-remove-requests`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account-remove-request
     * @name PostAccountRemoveRequests
     * @request POST:/account-remove-requests
     * @secure
     */
    postAccountRemoveRequests: (data: AccountRemoveRequestRequest, params: RequestParams = {}) =>
      this.request<AccountRemoveRequestResponse, Error>({
        path: `/account-remove-requests`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account-remove-request
     * @name GetAccountRemoveRequestsId
     * @request GET:/account-remove-requests/{id}
     * @secure
     */
    getAccountRemoveRequestsId: (id: number, params: RequestParams = {}) =>
      this.request<AccountRemoveRequestResponse, Error>({
        path: `/account-remove-requests/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account-remove-request
     * @name PutAccountRemoveRequestsId
     * @request PUT:/account-remove-requests/{id}
     * @secure
     */
    putAccountRemoveRequestsId: (id: number, data: AccountRemoveRequestRequest, params: RequestParams = {}) =>
      this.request<AccountRemoveRequestResponse, Error>({
        path: `/account-remove-requests/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account-remove-request
     * @name DeleteAccountRemoveRequestsId
     * @request DELETE:/account-remove-requests/{id}
     * @secure
     */
    deleteAccountRemoveRequestsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/account-remove-requests/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  categories = {
    /**
     * No description
     *
     * @tags Category
     * @name GetCategories
     * @request GET:/categories
     * @secure
     */
    getCategories: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CategoryListResponse, Error>({
        path: `/categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Category
     * @name PostCategories
     * @request POST:/categories
     * @secure
     */
    postCategories: (data: CategoryRequest, params: RequestParams = {}) =>
      this.request<CategoryResponse, Error>({
        path: `/categories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Category
     * @name GetCategoriesId
     * @request GET:/categories/{id}
     * @secure
     */
    getCategoriesId: (id: number, params: RequestParams = {}) =>
      this.request<CategoryResponse, Error>({
        path: `/categories/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Category
     * @name PutCategoriesId
     * @request PUT:/categories/{id}
     * @secure
     */
    putCategoriesId: (id: number, data: CategoryRequest, params: RequestParams = {}) =>
      this.request<CategoryResponse, Error>({
        path: `/categories/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Category
     * @name DeleteCategoriesId
     * @request DELETE:/categories/{id}
     * @secure
     */
    deleteCategoriesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/categories/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  cities = {
    /**
     * No description
     *
     * @tags City
     * @name GetCities
     * @request GET:/cities
     * @secure
     */
    getCities: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CityListResponse, Error>({
        path: `/cities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name PostCities
     * @request POST:/cities
     * @secure
     */
    postCities: (data: CityRequest, params: RequestParams = {}) =>
      this.request<CityResponse, Error>({
        path: `/cities`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name GetCitiesId
     * @request GET:/cities/{id}
     * @secure
     */
    getCitiesId: (id: number, params: RequestParams = {}) =>
      this.request<CityResponse, Error>({
        path: `/cities/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name PutCitiesId
     * @request PUT:/cities/{id}
     * @secure
     */
    putCitiesId: (id: number, data: CityRequest, params: RequestParams = {}) =>
      this.request<CityResponse, Error>({
        path: `/cities/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name DeleteCitiesId
     * @request DELETE:/cities/{id}
     * @secure
     */
    deleteCitiesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/cities/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  connectedItemGroups = {
    /**
     * No description
     *
     * @tags Connected-item-group
     * @name GetConnectedItemGroups
     * @request GET:/connected-item-groups
     * @secure
     */
    getConnectedItemGroups: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnectedItemGroupListResponse, Error>({
        path: `/connected-item-groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connected-item-group
     * @name PostConnectedItemGroups
     * @request POST:/connected-item-groups
     * @secure
     */
    postConnectedItemGroups: (data: ConnectedItemGroupRequest, params: RequestParams = {}) =>
      this.request<ConnectedItemGroupResponse, Error>({
        path: `/connected-item-groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connected-item-group
     * @name GetConnectedItemGroupsId
     * @request GET:/connected-item-groups/{id}
     * @secure
     */
    getConnectedItemGroupsId: (id: number, params: RequestParams = {}) =>
      this.request<ConnectedItemGroupResponse, Error>({
        path: `/connected-item-groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connected-item-group
     * @name PutConnectedItemGroupsId
     * @request PUT:/connected-item-groups/{id}
     * @secure
     */
    putConnectedItemGroupsId: (id: number, data: ConnectedItemGroupRequest, params: RequestParams = {}) =>
      this.request<ConnectedItemGroupResponse, Error>({
        path: `/connected-item-groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connected-item-group
     * @name DeleteConnectedItemGroupsId
     * @request DELETE:/connected-item-groups/{id}
     * @secure
     */
    deleteConnectedItemGroupsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/connected-item-groups/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  customScreens = {
    /**
     * No description
     *
     * @tags Custom-screen
     * @name GetCustomScreens
     * @request GET:/custom-screens
     * @secure
     */
    getCustomScreens: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomScreenListResponse, Error>({
        path: `/custom-screens`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Custom-screen
     * @name PostCustomScreens
     * @request POST:/custom-screens
     * @secure
     */
    postCustomScreens: (data: CustomScreenRequest, params: RequestParams = {}) =>
      this.request<CustomScreenResponse, Error>({
        path: `/custom-screens`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Custom-screen
     * @name GetCustomScreensId
     * @request GET:/custom-screens/{id}
     * @secure
     */
    getCustomScreensId: (id: number, params: RequestParams = {}) =>
      this.request<CustomScreenResponse, Error>({
        path: `/custom-screens/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Custom-screen
     * @name PutCustomScreensId
     * @request PUT:/custom-screens/{id}
     * @secure
     */
    putCustomScreensId: (id: number, data: CustomScreenRequest, params: RequestParams = {}) =>
      this.request<CustomScreenResponse, Error>({
        path: `/custom-screens/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Custom-screen
     * @name DeleteCustomScreensId
     * @request DELETE:/custom-screens/{id}
     * @secure
     */
    deleteCustomScreensId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/custom-screens/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  days = {
    /**
     * No description
     *
     * @tags Day
     * @name GetDays
     * @request GET:/days
     * @secure
     */
    getDays: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DayListResponse, Error>({
        path: `/days`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Day
     * @name PostDays
     * @request POST:/days
     * @secure
     */
    postDays: (data: DayRequest, params: RequestParams = {}) =>
      this.request<DayResponse, Error>({
        path: `/days`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Day
     * @name GetDaysId
     * @request GET:/days/{id}
     * @secure
     */
    getDaysId: (id: number, params: RequestParams = {}) =>
      this.request<DayResponse, Error>({
        path: `/days/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Day
     * @name PutDaysId
     * @request PUT:/days/{id}
     * @secure
     */
    putDaysId: (id: number, data: DayRequest, params: RequestParams = {}) =>
      this.request<DayResponse, Error>({
        path: `/days/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Day
     * @name DeleteDaysId
     * @request DELETE:/days/{id}
     * @secure
     */
    deleteDaysId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/days/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  expoPushTokens = {
    /**
     * No description
     *
     * @tags Expo-push-token
     * @name GetExpoPushTokens
     * @request GET:/expo-push-tokens
     * @secure
     */
    getExpoPushTokens: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ExpoPushTokenListResponse, Error>({
        path: `/expo-push-tokens`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expo-push-token
     * @name PostExpoPushTokens
     * @request POST:/expo-push-tokens
     * @secure
     */
    postExpoPushTokens: (data: ExpoPushTokenRequest, params: RequestParams = {}) =>
      this.request<ExpoPushTokenResponse, Error>({
        path: `/expo-push-tokens`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expo-push-token
     * @name GetExpoPushTokensId
     * @request GET:/expo-push-tokens/{id}
     * @secure
     */
    getExpoPushTokensId: (id: number, params: RequestParams = {}) =>
      this.request<ExpoPushTokenResponse, Error>({
        path: `/expo-push-tokens/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expo-push-token
     * @name PutExpoPushTokensId
     * @request PUT:/expo-push-tokens/{id}
     * @secure
     */
    putExpoPushTokensId: (id: number, data: ExpoPushTokenRequest, params: RequestParams = {}) =>
      this.request<ExpoPushTokenResponse, Error>({
        path: `/expo-push-tokens/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expo-push-token
     * @name DeleteExpoPushTokensId
     * @request DELETE:/expo-push-tokens/{id}
     * @secure
     */
    deleteExpoPushTokensId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/expo-push-tokens/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  faqs = {
    /**
     * No description
     *
     * @tags Faq
     * @name GetFaqs
     * @request GET:/faqs
     * @secure
     */
    getFaqs: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FaqListResponse, Error>({
        path: `/faqs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Faq
     * @name PostFaqs
     * @request POST:/faqs
     * @secure
     */
    postFaqs: (data: FaqRequest, params: RequestParams = {}) =>
      this.request<FaqResponse, Error>({
        path: `/faqs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Faq
     * @name GetFaqsId
     * @request GET:/faqs/{id}
     * @secure
     */
    getFaqsId: (id: number, params: RequestParams = {}) =>
      this.request<FaqResponse, Error>({
        path: `/faqs/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Faq
     * @name PutFaqsId
     * @request PUT:/faqs/{id}
     * @secure
     */
    putFaqsId: (id: number, data: FaqRequest, params: RequestParams = {}) =>
      this.request<FaqResponse, Error>({
        path: `/faqs/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Faq
     * @name DeleteFaqsId
     * @request DELETE:/faqs/{id}
     * @secure
     */
    deleteFaqsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/faqs/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  globalSetting = {
    /**
     * No description
     *
     * @tags Global-setting
     * @name GetGlobalSetting
     * @request GET:/global-setting
     * @secure
     */
    getGlobalSetting: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GlobalSettingResponse, Error>({
        path: `/global-setting`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global-setting
     * @name PutGlobalSetting
     * @request PUT:/global-setting
     * @secure
     */
    putGlobalSetting: (data: GlobalSettingRequest, params: RequestParams = {}) =>
      this.request<GlobalSettingResponse, Error>({
        path: `/global-setting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global-setting
     * @name DeleteGlobalSetting
     * @request DELETE:/global-setting
     * @secure
     */
    deleteGlobalSetting: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/global-setting`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  ignoredMenuItemModifiers = {
    /**
     * No description
     *
     * @tags Ignored-menu-item-modifier
     * @name GetIgnoredMenuItemModifiers
     * @request GET:/ignored-menu-item-modifiers
     * @secure
     */
    getIgnoredMenuItemModifiers: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IgnoredMenuItemModifierListResponse, Error>({
        path: `/ignored-menu-item-modifiers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ignored-menu-item-modifier
     * @name PostIgnoredMenuItemModifiers
     * @request POST:/ignored-menu-item-modifiers
     * @secure
     */
    postIgnoredMenuItemModifiers: (data: IgnoredMenuItemModifierRequest, params: RequestParams = {}) =>
      this.request<IgnoredMenuItemModifierResponse, Error>({
        path: `/ignored-menu-item-modifiers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ignored-menu-item-modifier
     * @name GetIgnoredMenuItemModifiersId
     * @request GET:/ignored-menu-item-modifiers/{id}
     * @secure
     */
    getIgnoredMenuItemModifiersId: (id: number, params: RequestParams = {}) =>
      this.request<IgnoredMenuItemModifierResponse, Error>({
        path: `/ignored-menu-item-modifiers/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ignored-menu-item-modifier
     * @name PutIgnoredMenuItemModifiersId
     * @request PUT:/ignored-menu-item-modifiers/{id}
     * @secure
     */
    putIgnoredMenuItemModifiersId: (id: number, data: IgnoredMenuItemModifierRequest, params: RequestParams = {}) =>
      this.request<IgnoredMenuItemModifierResponse, Error>({
        path: `/ignored-menu-item-modifiers/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ignored-menu-item-modifier
     * @name DeleteIgnoredMenuItemModifiersId
     * @request DELETE:/ignored-menu-item-modifiers/{id}
     * @secure
     */
    deleteIgnoredMenuItemModifiersId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/ignored-menu-item-modifiers/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  initial = {
    /**
     * No description
     *
     * @tags Initial
     * @name GetInitial
     * @request GET:/initial
     * @secure
     */
    getInitial: (params: RequestParams = {}) =>
      this.request<InitialResponse, Error>({
        path: `/initial`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  menuItems = {
    /**
     * No description
     *
     * @tags Menu-item
     * @name GetMenuItems
     * @request GET:/menu-items
     * @secure
     */
    getMenuItems: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MenuItemListResponse, Error>({
        path: `/menu-items`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-item
     * @name PostMenuItems
     * @request POST:/menu-items
     * @secure
     */
    postMenuItems: (data: MenuItemRequest, params: RequestParams = {}) =>
      this.request<MenuItemResponse, Error>({
        path: `/menu-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-item
     * @name GetMenuItemsId
     * @request GET:/menu-items/{id}
     * @secure
     */
    getMenuItemsId: (id: number, params: RequestParams = {}) =>
      this.request<MenuItemResponse, Error>({
        path: `/menu-items/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-item
     * @name PutMenuItemsId
     * @request PUT:/menu-items/{id}
     * @secure
     */
    putMenuItemsId: (id: number, data: MenuItemRequest, params: RequestParams = {}) =>
      this.request<MenuItemResponse, Error>({
        path: `/menu-items/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-item
     * @name DeleteMenuItemsId
     * @request DELETE:/menu-items/{id}
     * @secure
     */
    deleteMenuItemsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/menu-items/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  menuModifierItems = {
    /**
     * No description
     *
     * @tags Menu-modifier-item
     * @name GetMenuModifierItems
     * @request GET:/menu-modifier-items
     * @secure
     */
    getMenuModifierItems: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MenuModifierItemListResponse, Error>({
        path: `/menu-modifier-items`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-modifier-item
     * @name PostMenuModifierItems
     * @request POST:/menu-modifier-items
     * @secure
     */
    postMenuModifierItems: (data: MenuModifierItemRequest, params: RequestParams = {}) =>
      this.request<MenuModifierItemResponse, Error>({
        path: `/menu-modifier-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-modifier-item
     * @name GetMenuModifierItemsId
     * @request GET:/menu-modifier-items/{id}
     * @secure
     */
    getMenuModifierItemsId: (id: number, params: RequestParams = {}) =>
      this.request<MenuModifierItemResponse, Error>({
        path: `/menu-modifier-items/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-modifier-item
     * @name PutMenuModifierItemsId
     * @request PUT:/menu-modifier-items/{id}
     * @secure
     */
    putMenuModifierItemsId: (id: number, data: MenuModifierItemRequest, params: RequestParams = {}) =>
      this.request<MenuModifierItemResponse, Error>({
        path: `/menu-modifier-items/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Menu-modifier-item
     * @name DeleteMenuModifierItemsId
     * @request DELETE:/menu-modifier-items/{id}
     * @secure
     */
    deleteMenuModifierItemsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/menu-modifier-items/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  modifiers = {
    /**
     * No description
     *
     * @tags Modifier
     * @name GetModifiers
     * @request GET:/modifiers
     * @secure
     */
    getModifiers: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModifierListResponse, Error>({
        path: `/modifiers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier
     * @name PostModifiers
     * @request POST:/modifiers
     * @secure
     */
    postModifiers: (data: ModifierRequest, params: RequestParams = {}) =>
      this.request<ModifierResponse, Error>({
        path: `/modifiers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier
     * @name GetModifiersId
     * @request GET:/modifiers/{id}
     * @secure
     */
    getModifiersId: (id: number, params: RequestParams = {}) =>
      this.request<ModifierResponse, Error>({
        path: `/modifiers/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier
     * @name PutModifiersId
     * @request PUT:/modifiers/{id}
     * @secure
     */
    putModifiersId: (id: number, data: ModifierRequest, params: RequestParams = {}) =>
      this.request<ModifierResponse, Error>({
        path: `/modifiers/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier
     * @name DeleteModifiersId
     * @request DELETE:/modifiers/{id}
     * @secure
     */
    deleteModifiersId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/modifiers/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  modifierCategories = {
    /**
     * No description
     *
     * @tags Modifier-category
     * @name GetModifierCategories
     * @request GET:/modifier-categories
     * @secure
     */
    getModifierCategories: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModifierCategoryListResponse, Error>({
        path: `/modifier-categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier-category
     * @name PostModifierCategories
     * @request POST:/modifier-categories
     * @secure
     */
    postModifierCategories: (data: ModifierCategoryRequest, params: RequestParams = {}) =>
      this.request<ModifierCategoryResponse, Error>({
        path: `/modifier-categories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier-category
     * @name GetModifierCategoriesId
     * @request GET:/modifier-categories/{id}
     * @secure
     */
    getModifierCategoriesId: (id: number, params: RequestParams = {}) =>
      this.request<ModifierCategoryResponse, Error>({
        path: `/modifier-categories/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier-category
     * @name PutModifierCategoriesId
     * @request PUT:/modifier-categories/{id}
     * @secure
     */
    putModifierCategoriesId: (id: number, data: ModifierCategoryRequest, params: RequestParams = {}) =>
      this.request<ModifierCategoryResponse, Error>({
        path: `/modifier-categories/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Modifier-category
     * @name DeleteModifierCategoriesId
     * @request DELETE:/modifier-categories/{id}
     * @secure
     */
    deleteModifierCategoriesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/modifier-categories/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  orders = {
    /**
     * No description
     *
     * @tags Order
     * @name GetOrders
     * @request GET:/orders
     * @secure
     */
    getOrders: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderListResponse, Error>({
        path: `/orders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name PostOrders
     * @request POST:/orders
     * @secure
     */
    postOrders: (data: OrderRequest, params: RequestParams = {}) =>
      this.request<OrderResponse, Error>({
        path: `/orders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrdersId
     * @request GET:/orders/{id}
     * @secure
     */
    getOrdersId: (id: number, params: RequestParams = {}) =>
      this.request<OrderResponse, Error>({
        path: `/orders/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name PutOrdersId
     * @request PUT:/orders/{id}
     * @secure
     */
    putOrdersId: (id: number, data: OrderRequest, params: RequestParams = {}) =>
      this.request<OrderResponse, Error>({
        path: `/orders/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name DeleteOrdersId
     * @request DELETE:/orders/{id}
     * @secure
     */
    deleteOrdersId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/orders/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  orderRequests = {
    /**
     * No description
     *
     * @tags Order-request
     * @name GetOrderRequests
     * @request GET:/order-requests
     * @secure
     */
    getOrderRequests: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderRequestListResponse, Error>({
        path: `/order-requests`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-request
     * @name PostOrderRequests
     * @request POST:/order-requests
     * @secure
     */
    postOrderRequests: (data: OrderRequestRequest, params: RequestParams = {}) =>
      this.request<OrderRequestResponse, Error>({
        path: `/order-requests`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-request
     * @name GetOrderRequestsId
     * @request GET:/order-requests/{id}
     * @secure
     */
    getOrderRequestsId: (id: number, params: RequestParams = {}) =>
      this.request<OrderRequestResponse, Error>({
        path: `/order-requests/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-request
     * @name PutOrderRequestsId
     * @request PUT:/order-requests/{id}
     * @secure
     */
    putOrderRequestsId: (id: number, data: OrderRequestRequest, params: RequestParams = {}) =>
      this.request<OrderRequestResponse, Error>({
        path: `/order-requests/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-request
     * @name DeleteOrderRequestsId
     * @request DELETE:/order-requests/{id}
     * @secure
     */
    deleteOrderRequestsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/order-requests/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  orderTypes = {
    /**
     * No description
     *
     * @tags Order-type
     * @name GetOrderTypes
     * @request GET:/order-types
     * @secure
     */
    getOrderTypes: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderTypeListResponse, Error>({
        path: `/order-types`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-type
     * @name PostOrderTypes
     * @request POST:/order-types
     * @secure
     */
    postOrderTypes: (data: OrderTypeRequest, params: RequestParams = {}) =>
      this.request<OrderTypeResponse, Error>({
        path: `/order-types`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-type
     * @name GetOrderTypesId
     * @request GET:/order-types/{id}
     * @secure
     */
    getOrderTypesId: (id: number, params: RequestParams = {}) =>
      this.request<OrderTypeResponse, Error>({
        path: `/order-types/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-type
     * @name PutOrderTypesId
     * @request PUT:/order-types/{id}
     * @secure
     */
    putOrderTypesId: (id: number, data: OrderTypeRequest, params: RequestParams = {}) =>
      this.request<OrderTypeResponse, Error>({
        path: `/order-types/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order-type
     * @name DeleteOrderTypesId
     * @request DELETE:/order-types/{id}
     * @secure
     */
    deleteOrderTypesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/order-types/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  paymentTypes = {
    /**
     * No description
     *
     * @tags Payment-type
     * @name GetPaymentTypes
     * @request GET:/payment-types
     * @secure
     */
    getPaymentTypes: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentTypeListResponse, Error>({
        path: `/payment-types`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment-type
     * @name PostPaymentTypes
     * @request POST:/payment-types
     * @secure
     */
    postPaymentTypes: (data: PaymentTypeRequest, params: RequestParams = {}) =>
      this.request<PaymentTypeResponse, Error>({
        path: `/payment-types`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment-type
     * @name GetPaymentTypesId
     * @request GET:/payment-types/{id}
     * @secure
     */
    getPaymentTypesId: (id: number, params: RequestParams = {}) =>
      this.request<PaymentTypeResponse, Error>({
        path: `/payment-types/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment-type
     * @name PutPaymentTypesId
     * @request PUT:/payment-types/{id}
     * @secure
     */
    putPaymentTypesId: (id: number, data: PaymentTypeRequest, params: RequestParams = {}) =>
      this.request<PaymentTypeResponse, Error>({
        path: `/payment-types/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment-type
     * @name DeletePaymentTypesId
     * @request DELETE:/payment-types/{id}
     * @secure
     */
    deletePaymentTypesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/payment-types/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  products = {
    /**
     * No description
     *
     * @tags Product
     * @name GetProducts
     * @request GET:/products
     * @secure
     */
    getProducts: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductListResponse, Error>({
        path: `/products`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name PostProducts
     * @request POST:/products
     * @secure
     */
    postProducts: (data: ProductRequest, params: RequestParams = {}) =>
      this.request<ProductResponse, Error>({
        path: `/products`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductsId
     * @request GET:/products/{id}
     * @secure
     */
    getProductsId: (id: number, params: RequestParams = {}) =>
      this.request<ProductResponse, Error>({
        path: `/products/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name PutProductsId
     * @request PUT:/products/{id}
     * @secure
     */
    putProductsId: (id: number, data: ProductRequest, params: RequestParams = {}) =>
      this.request<ProductResponse, Error>({
        path: `/products/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name DeleteProductsId
     * @request DELETE:/products/{id}
     * @secure
     */
    deleteProductsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/products/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  shops = {
    /**
     * No description
     *
     * @tags Shop
     * @name GetShops
     * @request GET:/shops
     * @secure
     */
    getShops: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ShopListResponse, Error>({
        path: `/shops`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name PostShops
     * @request POST:/shops
     * @secure
     */
    postShops: (data: ShopRequest, params: RequestParams = {}) =>
      this.request<ShopResponse, Error>({
        path: `/shops`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name GetShopsId
     * @request GET:/shops/{id}
     * @secure
     */
    getShopsId: (id: number, params: RequestParams = {}) =>
      this.request<ShopResponse, Error>({
        path: `/shops/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name PutShopsId
     * @request PUT:/shops/{id}
     * @secure
     */
    putShopsId: (id: number, data: ShopRequest, params: RequestParams = {}) =>
      this.request<ShopResponse, Error>({
        path: `/shops/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name DeleteShopsId
     * @request DELETE:/shops/{id}
     * @secure
     */
    deleteShopsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/shops/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  specialShopWorkTimes = {
    /**
     * No description
     *
     * @tags Special-shop-work-time
     * @name GetSpecialShopWorkTimes
     * @request GET:/special-shop-work-times
     * @secure
     */
    getSpecialShopWorkTimes: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SpecialShopWorkTimeListResponse, Error>({
        path: `/special-shop-work-times`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Special-shop-work-time
     * @name PostSpecialShopWorkTimes
     * @request POST:/special-shop-work-times
     * @secure
     */
    postSpecialShopWorkTimes: (data: SpecialShopWorkTimeRequest, params: RequestParams = {}) =>
      this.request<SpecialShopWorkTimeResponse, Error>({
        path: `/special-shop-work-times`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Special-shop-work-time
     * @name GetSpecialShopWorkTimesId
     * @request GET:/special-shop-work-times/{id}
     * @secure
     */
    getSpecialShopWorkTimesId: (id: number, params: RequestParams = {}) =>
      this.request<SpecialShopWorkTimeResponse, Error>({
        path: `/special-shop-work-times/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Special-shop-work-time
     * @name PutSpecialShopWorkTimesId
     * @request PUT:/special-shop-work-times/{id}
     * @secure
     */
    putSpecialShopWorkTimesId: (id: number, data: SpecialShopWorkTimeRequest, params: RequestParams = {}) =>
      this.request<SpecialShopWorkTimeResponse, Error>({
        path: `/special-shop-work-times/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Special-shop-work-time
     * @name DeleteSpecialShopWorkTimesId
     * @request DELETE:/special-shop-work-times/{id}
     * @secure
     */
    deleteSpecialShopWorkTimesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/special-shop-work-times/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  userCreditCards = {
    /**
     * No description
     *
     * @tags User-credit-card
     * @name GetUserCreditCards
     * @request GET:/user-credit-cards
     * @secure
     */
    getUserCreditCards: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserCreditCardListResponse, Error>({
        path: `/user-credit-cards`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-credit-card
     * @name PostUserCreditCards
     * @request POST:/user-credit-cards
     * @secure
     */
    postUserCreditCards: (data: UserCreditCardRequest, params: RequestParams = {}) =>
      this.request<UserCreditCardResponse, Error>({
        path: `/user-credit-cards`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-credit-card
     * @name GetUserCreditCardsId
     * @request GET:/user-credit-cards/{id}
     * @secure
     */
    getUserCreditCardsId: (id: number, params: RequestParams = {}) =>
      this.request<UserCreditCardResponse, Error>({
        path: `/user-credit-cards/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-credit-card
     * @name PutUserCreditCardsId
     * @request PUT:/user-credit-cards/{id}
     * @secure
     */
    putUserCreditCardsId: (id: number, data: UserCreditCardRequest, params: RequestParams = {}) =>
      this.request<UserCreditCardResponse, Error>({
        path: `/user-credit-cards/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-credit-card
     * @name DeleteUserCreditCardsId
     * @request DELETE:/user-credit-cards/{id}
     * @secure
     */
    deleteUserCreditCardsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/user-credit-cards/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  userFavoriteLists = {
    /**
     * No description
     *
     * @tags User-favorite-list
     * @name GetUserFavoriteLists
     * @request GET:/user-favorite-lists
     * @secure
     */
    getUserFavoriteLists: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string;
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean;
        /** Page number (default: 0) */
        "pagination[page]"?: number;
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number;
        /** Offset value (default: 0) */
        "pagination[start]"?: number;
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number;
        /** Fields to return (ex: title,author) */
        fields?: string;
        /** Relations to return */
        populate?: string;
        /** Filters to apply */
        filters?: object;
        /** Locale to apply */
        locale?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserFavoriteListListResponse, Error>({
        path: `/user-favorite-lists`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-favorite-list
     * @name PostUserFavoriteLists
     * @request POST:/user-favorite-lists
     * @secure
     */
    postUserFavoriteLists: (data: UserFavoriteListRequest, params: RequestParams = {}) =>
      this.request<UserFavoriteListResponse, Error>({
        path: `/user-favorite-lists`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-favorite-list
     * @name GetUserFavoriteListsId
     * @request GET:/user-favorite-lists/{id}
     * @secure
     */
    getUserFavoriteListsId: (id: number, params: RequestParams = {}) =>
      this.request<UserFavoriteListResponse, Error>({
        path: `/user-favorite-lists/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-favorite-list
     * @name PutUserFavoriteListsId
     * @request PUT:/user-favorite-lists/{id}
     * @secure
     */
    putUserFavoriteListsId: (id: number, data: UserFavoriteListRequest, params: RequestParams = {}) =>
      this.request<UserFavoriteListResponse, Error>({
        path: `/user-favorite-lists/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User-favorite-list
     * @name DeleteUserFavoriteListsId
     * @request DELETE:/user-favorite-lists/{id}
     * @secure
     */
    deleteUserFavoriteListsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/user-favorite-lists/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  upload = {
    /**
     * @description Upload files
     *
     * @tags Upload - File
     * @name UploadCreate
     * @request POST:/upload
     * @secure
     */
    uploadCreate: (
      data: {
        /** The folder where the file(s) will be uploaded to (only supported on strapi-provider-upload-aws-s3). */
        path?: string;
        /** The ID of the entry which the file(s) will be linked to */
        refId?: string;
        /** The unique ID (uid) of the model which the file(s) will be linked to (api::restaurant.restaurant). */
        ref?: string;
        /** The field of the entry which the file(s) will be precisely linked to. */
        field?: string;
        files: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadFile[], any>({
        path: `/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload - File
     * @name FilesList
     * @request GET:/upload/files
     * @secure
     */
    filesList: (params: RequestParams = {}) =>
      this.request<UploadFile[], any>({
        path: `/upload/files`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload - File
     * @name FilesDetail
     * @request GET:/upload/files/{id}
     * @secure
     */
    filesDetail: (id: string, params: RequestParams = {}) =>
      this.request<UploadFile, any>({
        path: `/upload/files/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload - File
     * @name FilesDelete
     * @request DELETE:/upload/files/{id}
     * @secure
     */
    filesDelete: (id: string, params: RequestParams = {}) =>
      this.request<UploadFile, any>({
        path: `/upload/files/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  uploadIdId = {
    /**
     * @description Upload file information
     *
     * @tags Upload - File
     * @name UploadIdCreate
     * @request POST:/upload?id={id}
     * @secure
     */
    uploadIdCreate: (
      id: string,
      query: {
        /** File id */
        id: string;
      },
      data: {
        fileInfo?: {
          name?: string;
          alternativeText?: string;
          caption?: string;
        };
        /** @format binary */
        files?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadFile[], any>({
        path: `/upload?id=${id}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  connect = {
    /**
     * @description Redirects to provider login before being redirect to /auth/{provider}/callback
     *
     * @tags Users-Permissions - Auth
     * @name ConnectDetail
     * @summary Login with a provider
     * @request GET:/connect/{provider}
     * @secure
     */
    connectDetail: (provider: string, params: RequestParams = {}) =>
      this.request<any, void | Error>({
        path: `/connect/${provider}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  auth = {
    /**
     * @description Returns a jwt token and user info
     *
     * @tags Users-Permissions - Auth
     * @name LocalCreate
     * @summary Local login
     * @request POST:/auth/local
     * @secure
     */
    localCreate: (
      data: {
        identifier?: string;
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/local`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a jwt token and user info
     *
     * @tags Users-Permissions - Auth
     * @name LocalRegisterCreate
     * @summary Register a user
     * @request POST:/auth/local/register
     * @secure
     */
    localRegisterCreate: (
      data: {
        username?: string;
        email?: string;
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/local/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name CallbackDetail
     * @summary Default Callback from provider auth
     * @request GET:/auth/{provider}/callback
     * @secure
     */
    callbackDetail: (provider: string, params: RequestParams = {}) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/${provider}/callback`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name ForgotPasswordCreate
     * @summary Send rest password email
     * @request POST:/auth/forgot-password
     * @secure
     */
    forgotPasswordCreate: (
      data: {
        email?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          ok?: "true";
        },
        Error
      >({
        path: `/auth/forgot-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name ResetPasswordCreate
     * @summary Rest user password
     * @request POST:/auth/reset-password
     * @secure
     */
    resetPasswordCreate: (
      data: {
        password?: string;
        passwordConfirmation?: string;
        code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name ChangePasswordCreate
     * @summary Update user's own password
     * @request POST:/auth/change-password
     * @secure
     */
    changePasswordCreate: (
      data: {
        password: string;
        currentPassword: string;
        passwordConfirmation: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/change-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name EmailConfirmationList
     * @summary Confirm user email
     * @request GET:/auth/email-confirmation
     * @secure
     */
    emailConfirmationList: (
      query?: {
        /** confirmation token received by email */
        confirmation?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void | Error>({
        path: `/auth/email-confirmation`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name SendEmailConfirmationCreate
     * @summary Send confirmation email
     * @request POST:/auth/send-email-confirmation
     * @secure
     */
    sendEmailConfirmationCreate: (
      data: {
        email?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          email?: string;
          sent?: "true";
        },
        Error
      >({
        path: `/auth/send-email-confirmation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  usersPermissions = {
    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name PermissionsList
     * @summary Get default generated permissions
     * @request GET:/users-permissions/permissions
     * @secure
     */
    permissionsList: (params: RequestParams = {}) =>
      this.request<
        {
          permissions?: UsersPermissionsPermissionsTree;
        },
        Error
      >({
        path: `/users-permissions/permissions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesList
     * @summary List roles
     * @request GET:/users-permissions/roles
     * @secure
     */
    rolesList: (params: RequestParams = {}) =>
      this.request<
        {
          roles?: (UsersPermissionsRole & {
            nb_users?: number;
          })[];
        },
        Error
      >({
        path: `/users-permissions/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesCreate
     * @summary Create a role
     * @request POST:/users-permissions/roles
     * @secure
     */
    rolesCreate: (
      data: {
        name?: string;
        description?: string;
        type?: string;
        permissions?: UsersPermissionsPermissionsTree;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          ok?: "true";
        },
        Error
      >({
        path: `/users-permissions/roles`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesDetail
     * @summary Get a role
     * @request GET:/users-permissions/roles/{id}
     * @secure
     */
    rolesDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          role?: UsersPermissionsRole;
        },
        Error
      >({
        path: `/users-permissions/roles/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesUpdate
     * @summary Update a role
     * @request PUT:/users-permissions/roles/{role}
     * @secure
     */
    rolesUpdate: (
      role: string,
      data: {
        name?: string;
        description?: string;
        type?: string;
        permissions?: UsersPermissionsPermissionsTree;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          ok?: "true";
        },
        Error
      >({
        path: `/users-permissions/roles/${role}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesDelete
     * @summary Delete a role
     * @request DELETE:/users-permissions/roles/{role}
     * @secure
     */
    rolesDelete: (role: string, params: RequestParams = {}) =>
      this.request<
        {
          ok?: "true";
        },
        Error
      >({
        path: `/users-permissions/roles/${role}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersList
     * @summary Get list of users
     * @request GET:/users
     * @secure
     */
    usersList: (params: RequestParams = {}) =>
      this.request<UsersPermissionsUser[], Error>({
        path: `/users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersCreate
     * @summary Create a user
     * @request POST:/users
     * @secure
     */
    usersCreate: (
      data: {
        email: string;
        username: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        UsersPermissionsUser & {
          role?: UsersPermissionsRole;
        },
        Error
      >({
        path: `/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersDetail
     * @summary Get a user
     * @request GET:/users/{id}
     * @secure
     */
    usersDetail: (id: string, params: RequestParams = {}) =>
      this.request<UsersPermissionsUser, Error>({
        path: `/users/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersUpdate
     * @summary Update a user
     * @request PUT:/users/{id}
     * @secure
     */
    usersUpdate: (
      id: string,
      data: {
        email: string;
        username: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        UsersPermissionsUser & {
          role?: UsersPermissionsRole;
        },
        Error
      >({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersDelete
     * @summary Delete a user
     * @request DELETE:/users/{id}
     * @secure
     */
    usersDelete: (id: string, params: RequestParams = {}) =>
      this.request<UsersPermissionsUser, Error>({
        path: `/users/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name GetUsers
     * @summary Get authenticated user info
     * @request GET:/users/me
     * @secure
     */
    getUsers: (params: RequestParams = {}) =>
      this.request<UsersPermissionsUser, Error>({
        path: `/users/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name CountList
     * @summary Get user count
     * @request GET:/users/count
     * @secure
     */
    countList: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/users/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
