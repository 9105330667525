import React, {
  FC, useCallback, useMemo,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  Text, View, Pressable, StyleSheet,
  ScrollView, TouchableOpacity,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import useStore from '@app/domain/store/useStore';
import Picture from '@components/common/Picture/Picture';
import { useNavigation } from '@react-navigation/native';
import generalConfig from '@constants/generalConfig';
import { useInstance } from 'react-ioc';
import Typograf from 'typograf';

import Counter from './Counter';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
  },
  title: {
    fontFamily: ubuntuFont.medium,
    fontSize: 12,
    lineHeight: 14,
  },
  imageWrapper: {
    marginRight: 5,
  },
  info: {
    fontFamily: ubuntuFont.regular,
    fontSize: 12,
    color: colors.grey,
  },
  column: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    gap: 10,
  },
  left: {
    flex: 1,
  },
  button: {
    // position: 'absolute',
    // right: 0,
    // bottom: 0,
  },
  addonView: {
    marginTop: 5,
    marginBottom: 4,
  },
  addon: {
    color: colors.primary,
    fontSize: 12,
    fontFamily: ubuntuFont.light,
  },
  removeNotAvailable: {
    flexDirection: 'row',
    backgroundColor: colors.error,
    width: 130,
    height: 30,
    borderRadius: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  removeNotAvailableText: {
    flex: 1,
    color: colors.white,
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    textAlign: 'center',
  },
  notAvailable: {
    paddingTop: 5,
    flex: 1,
    color: colors.text,
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    textAlign: 'center',
    maxWidth: 110,
    marginHorizontal: 'auto',
  },
  titleNotAvailable: {
    opacity: 0.5,
    textDecorationLine: 'line-through',
  },
});

interface IProps {
  cartItemId: string,
  shopId: string,
}

const CartItem: FC<IProps> = (
  {
    cartItemId,
    shopId,
  },
) => {
  const {
    appStore: {
      activeMenu,
      getCartItemById,
      getCartItemTotal,
      reduceCartItemAmountById,
      removeCartItemById,
      productTimeAvailability,
      categoryTimeAvailability,
      cartStates,
      shop,
    },
  } = useStore();

  const tp = useInstance(Typograf);

  const cartItem = getCartItemById(cartItemId);
  const menuItem = cartItem?.menuItem;
  const cartTimeState = shop && cartStates.get(shop.id)?.time;
  const productTimeAvailable = useMemo(
    () => productTimeAvailability(cartItem?.menuItem?.id, cartTimeState),
    [productTimeAvailability, cartTimeState, cartItem?.menuItem?.id],
  );

  const categoryTimeAvailable = useMemo(
    () => categoryTimeAvailability(cartItem?.menuItem?.id, cartTimeState),
    [categoryTimeAvailability, cartTimeState, cartItem?.menuItem?.id],
  );

  const {
    increase,
    reduce,
  } = useMemo(() => ({
    increase: () => cartItem?.increaseAmount(),
    reduce: () => reduceCartItemAmountById(cartItemId),
  }), [cartItem, cartItemId, reduceCartItemAmountById]);

  const navigation = useNavigation();

  const goToMenuItem = useCallback(() => {
    if (!cartItem) {
      return;
    }

    if (!menuItem?.id) {
      return;
    }

    navigation.navigate('MenuItemScreen', {
      menuItemId: menuItem.id,
      shopId,
    });
  }, [cartItem, menuItem?.id, navigation, shopId]);

  const onRemovePress = useCallback(() => {
    if (cartItem?.id) {
      removeCartItemById(cartItem?.id);
    }
  }, [removeCartItemById, cartItem?.id]);

  const product = cartItem?.product;

  // TODO: Показывать не доступные в другом меню товары и добавки?

  // if (!activeMenu || !cartItem || !menuItem || !menuItem.product) {
  if (!activeMenu || !cartItem || !product) {
    return null;
  }

  // Убрал, т.к. если нет модификатора на витрине мы всё ещё можем что-то написать на тему товара в корзине
  // const notExistedModifiers = cartItem
  //   .modifiers
  //   .filter((m) => !m.menuModifierItem).length > 0;

  // if (notExistedModifiers) {
  //   return null;
  // }

  const isAvailable = !cartItem.haveNotAvailableModifiers
    && !!cartItem.menuItem?.isAvailable
    && productTimeAvailable
    && categoryTimeAvailable;

  return (
    <View
      key={cartItem.id}
      style={s.root}
    >
      <View style={s.imageWrapper}>
        <Pressable onPress={goToMenuItem}>
          <Picture
            notAvailableItem={!cartItem.menuItem?.isAvailable}
            image={product.imageUrl_100}
            size={80}
          />
        </Pressable>
      </View>

      <View style={s.column}>
        <View style={s.left}>
          <View>
            <Text
              style={StyleSheet.flatten([
                s.title,
                !cartItem.menuItem?.isAvailable && s.titleNotAvailable,
              ])}
            >
              {tp.execute(product.title)}
            </Text>
          </View>

          <View>
            {!!cartItem.menuItem && (
              <Text
                style={s.info}
              >
                {cartItem.menuItem?.price}
                {' '}
                {generalConfig.currencyLabel}
              </Text>
            )}
          </View>

          {cartItem.modifiers.length > 0 && (
            <ScrollView contentContainerStyle={s.addonView}>
              {cartItem.modifiers.map((addon) => {
                const modifierCartItem = addon.menuModifierItem;

                let price = modifierCartItem?.price;

                if (typeof price === 'undefined') {
                  console.error('Добавка в корзине без цены');

                  price = 0;
                }

                return (
                  <Text
                    style={StyleSheet.flatten([
                      s.addon,
                      !modifierCartItem?.isAvailable && s.titleNotAvailable,
                    ])}
                    key={addon.id}
                  >
                    {tp.execute(`+\u00A0${addon.modifier?.title}${price > 0 ? (` ${price}\u00A0${generalConfig.currencyLabel}`) : ''}${addon.amount > 1 ? ` x\u00A0${addon.amount}` : ''}`)}
                  </Text>
                );
              })}
            </ScrollView>
          )}
        </View>
        <View style={s.button}>
          {isAvailable && (
            <Counter
              increase={increase}
              reduce={reduce}
              amount={cartItem.amount}
              price={getCartItemTotal(cartItem.id).single}
            />
          )}
          {!isAvailable && (
            // Временное решение по отображению доступности пока не получаем данные с бэка
            <>
              <TouchableOpacity onPress={onRemovePress}>
                <View style={s.removeNotAvailable}>
                  <Text style={s.removeNotAvailableText}>убрать</Text>
                </View>
              </TouchableOpacity>
              <Text style={s.notAvailable}>
                {!isAvailable && productTimeAvailable && categoryTimeAvailable && 'Нет в наличии'}
                {!isAvailable && productTimeAvailable && !categoryTimeAvailable
                  && 'Сейчас недоступно для заказа'}
                {!isAvailable && !productTimeAvailable && categoryTimeAvailable
                  && 'Сейчас недоступно для заказа'}
              </Text>
            </>
          )}
        </View>
      </View>

    </View>
  );
};

export default observer(CartItem);
