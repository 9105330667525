import { observer } from 'mobx-react-lite';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
} from 'react-native';
import React, { FC } from 'react';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import Picture from '@components/common/Picture/Picture';
import getOrderCookingStatusMessage from '@components/OrderInfoBanner/getOrderCookingStatusMessage';

const s = StyleSheet.create({
  root: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: colors.primary,
    paddingTop: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'visible',
  },
  text: {
    color: colors.white,
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    paddingBottom: 15,
    textAlign: 'center',
  },
  images: {
    gap: 20,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: '100%',
  },
  white: {
    position: 'absolute',
    bottom: -1,
    backgroundColor: colors.white,
    height: 41,
    width: '100%',
  },
});

interface IProps {
  orderCookingStatus: string,
  productImagesUrl: string[],
  orderId: string,
}

const OrderInfoBanner: FC<IProps> = (
  {
    orderCookingStatus,
    productImagesUrl,
    orderId,
  },
) => (
  <View style={s.root}>
    <Text style={s.text}>
      {getOrderCookingStatusMessage(orderId, orderCookingStatus)}
    </Text>
    <View style={s.white} />
    <ScrollView
      contentContainerStyle={s.images}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      {productImagesUrl.map((url) => (
        <Picture
          key={url}
          image={url}
          size={80}
        />
      ))}
    </ScrollView>
  </View>
);
export default observer(OrderInfoBanner);
