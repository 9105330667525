import { observer } from 'mobx-react-lite';
import { StyleSheet, Text, View } from 'react-native';
import OpenModifiersSheetButton from '@screens/MenuItemScreen/includes/Modifier/OpenModifiersSheetButton';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import colors from '@constants/colors';
import React, { FC } from 'react';
import { GroupModifierInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/GroupModifier';
import useStore from '@app/domain/store/useStore';
import { useModifiersState } from '@screens/MenuItemScreen/model/ModifiersStateModelContext';
import ubuntuFont from '@constants/ubuntuFont';
import generalConfig from '@constants/generalConfig';

const s = StyleSheet.create({
  categoryTitleSelected: {
    fontFamily: ubuntuFont.regular,
    color: colors.white,
    fontSize: 14,
  },
  categoryTitle: {
    fontFamily: ubuntuFont.regular,
    color: colors.grey,
    fontSize: 14,
  },
  categorySmallText: {
    fontSize: 12,
  },
  gmRoot: {
    gap: 10,
  },
  itemRootWithSelected: {
    borderRadius: 10,
    backgroundColor: colors.primary,
    padding: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
  },
  itemRoot: {
    borderRadius: 10,
    borderColor: colors.primary,
    borderWidth: 2,
    padding: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
  },
  modAmount: {
    fontFamily: ubuntuFont.medium,
  },
  modText: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    paddingLeft: 5,
  },
  pb10: {
    paddingBottom: 10,
    gap: 5,
  },
});

interface IProps {
  gm: GroupModifierInstance,
  mode: 'switch' | 'add',
}

const ModifierRow: FC<IProps> = (
  {
    gm,
    mode,
  },
) => {
  const {
    appStore: {
      pricesByModifierId,
    },
  } = useStore();

  const modifiersStateModel = useModifiersState();

  const countOfSelectedModifiersInGroup = gm.childModifiers.reduce((sum, { modifierId }) => {
    const modifierState = modifiersStateModel.state.get(modifierId);

    if (!modifierState) {
      return sum;
    }

    return sum + modifierState.amount;
  }, 0);

  const hasSelected = countOfSelectedModifiersInGroup > 0;

  return (
    <View style={s.gmRoot}>
      <OpenModifiersSheetButton
        gm={gm}
        style={hasSelected ? s.itemRootWithSelected : s.itemRoot}
      >
        <Text style={hasSelected ? s.categoryTitleSelected : s.categoryTitle}>
          {mode === 'switch' && (
            <Text style={s.categorySmallText}>
              {gm.modifierCategory?.title}
            </Text>
          )}
          {mode === 'add' && gm.modifierCategory?.title}
          {mode === 'switch' && '\n'}
          {/* TODO: Это требуется доделать.  */}
          {mode === 'switch' && (countOfSelectedModifiersInGroup > 0 && (
            gm.childModifiers.reduce((title, cm) => {
              if (title) {
                return title;
              }

              const modifierState = modifiersStateModel.state.get(cm.modifierId);

              if (!modifierState) {
                return '';
              }

              if (modifierState.amount === 0) {
                return '';
              }

              return cm.modifier?.title || '';
            }, '')
          ))}
          {mode === 'add' && countOfSelectedModifiersInGroup > 0 && (
            <Text style={s.modAmount}>
              {'\u00A0'}
              (
              {countOfSelectedModifiersInGroup}
              )
            </Text>
          )}
        </Text>
        <ArrowRightSvg color={hasSelected ? colors.white : colors.text} />
      </OpenModifiersSheetButton>

      {mode === 'add' && countOfSelectedModifiersInGroup > 0 && (
        <View style={s.pb10}>
          {gm.childModifiers.map((cm) => {
            const price = pricesByModifierId.get(cm.modifierId) || 0;
            const amount = modifiersStateModel.state.get(cm.modifierId)?.amount || 0;

            if (amount === 0) {
              return null;
            }

            return (
              <Text style={s.modText} key={cm.id}>
                {`+ ${cm.modifier?.title}`}
                {price > 0 && ` ${price} ${generalConfig.currencyLabel}`}
                {amount > 1 && ` x ${amount}`}
              </Text>
            );
          })}
        </View>
      )}
    </View>
  );
};

export default observer(ModifierRow);
