import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
  getParent,
  destroy,
} from 'mobx-state-tree';
import generalConfig from '@constants/generalConfig';
import type { AppStoreInstance } from '@app/domain/store/CoreStore/AppStore/AppStore';
import type { MenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import { CartItemModifier } from '@app/domain/store/CoreStore/AppStore/entities/Cart/CartItemModifier';
import { v4 as uuidv4 } from 'uuid';
import type { ProductInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Product';

export const getCartItemKey = (
  {
    productId,
    modifiers,
  }: {
    productId: string,
    modifiers?: {
      [key: string]: number
    };
  },
): string => {
  if (!modifiers || Object.keys(modifiers).length === 0) {
    return productId;
  }

  const modifiersKeys = Object.keys(modifiers)
    .sort();

  const modifiersKeyHash = modifiersKeys.map((key) => `${key}:${modifiers[key]}`)
    .join('|');

  return `${productId}_${modifiersKeyHash}`;
};

export const CartItem = t
  .model('CartItem', {
    id: t.identifier,
    productId: t.string,
    amount: t.refinement(t.number, (value) => value > 0),
    modifiers: t.optional(t.array(CartItemModifier), []),
  })
  .views((self) => ({
    get menuItem() {
      const parent: AppStoreInstance = getParent(self, 2);

      if (!parent.activeMenu) {
        return undefined;
      }

      const activeMenuItems: MenuItemInstance[] = [...parent.activeMenu.menuItems.values()];

      return activeMenuItems
        .find((item) => item.product?.id === self.productId);
    },
    get product(): ProductInstance | undefined {
      // any стоит т.к. если прописать AppStoreInstance то TS сходит с ума
      const parent: any = getParent(self, 2);

      return parent.products.get(self.productId);
    },
  }))
  .views((self) => ({
    get haveNotExistedModifiers() {
      if (!self.modifiers.length) {
        return false;
      }

      // Когда модификатора нет, но есть элемент в корзине
      return self.modifiers.some((m) => !m.modifier);
    },
    get haveNotAvailableModifiers() {
      // Когда нет модификатора на витрине или он обозначен как недоступны, но есть элемент в корзине
      return self.modifiers.some((m) => !m.menuModifierItem?.isAvailable);
    },
  }))
  .actions((self) => ({
    addModifier: (modifierId: string) => {
      const cartItemModifier = CartItemModifier.create({
        id: uuidv4(),
        modifierId,
        amount: 1,
      });

      self.modifiers.push(cartItemModifier);
    },
    increaseAmount: () => {
      if (self.amount === generalConfig.maxAmount) {
        return;
      }

      self.amount += 1;
    },
    reduceAmount: () => {
      if (self.amount === 1) {
        // const parent: AppStoreInstance['cart'] = getParent(self);
        // parent.delete(self.id);
        // // detach(self);
        destroy(self);

        return;
      }

      self.amount -= 1;
    },
  }));

export interface CartItemInstance extends Instance<typeof CartItem> {
}

export interface CartItemSnapshotIn extends SnapshotIn<typeof CartItem> {
}

export interface CartItemSnapshotOut extends SnapshotOut<typeof CartItem> {
}
