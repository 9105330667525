import React, {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  StyleSheet,
  View,
  Text,
  // Image,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef, SheetManager,
  SheetProps,
} from 'react-native-actions-sheet';
import { observer } from 'mobx-react-lite';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import {
  AuthViaOtpResultCode,
  RequestOtpCodeResultCode,
  useAuthViaOtpMutation, useFastAddOrderMutation,
  useRequestOtpCodeMutation,
} from '@app/infrastructureLayer/generated/graphql';
import crossAlert from '@utils/crossAlert';
import { absurd } from 'fp-ts/function';
import useStore from '@app/domain/store/useStore';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import images from '@constants/images';
import secondsToHumanFormat from '@utils/secondsToHumanFormat';
import Button from '@components/common/Button';
import OtpInput from '@components/form/OtpInput';
import ButtonOutline from '@components/common/ButtonOutline';
import useProfileSync from '@hooks/useProfileSync/useProfileSync';

const s = StyleSheet.create({
  container: {
    // flex: 1,
    paddingHorizontal: 25,
    paddingBottom: 30,
    backgroundColor: colors.white,
  },
  // scroll: {
  //   flexGrow: 1,
  // },
  containerTop: {
    // flex: 1,
    alignItems: 'center',
    marginTop: 30,
  },
  flex1: {
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  // logo: {
  //   width: 195,
  //   height: 195,
  // },
  title: {
    fontSize: 14,
    marginTop: 15,
    fontFamily: ubuntuFont.light,
  },
  link: {
    textDecorationLine: 'underline',
    marginTop: 5,
    fontSize: 12,
    fontFamily: ubuntuFont.regular,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
  },
  optWrapper: {
    marginVertical: 20,
  },
  messageDuration: {
    textAlign: 'center',
    fontSize: 14,
    fontFamily: ubuntuFont.light,
    color: colors.error,
  },
  error: {
    color: colors.error,
    textAlign: 'center',
    marginTop: 3,
    fontSize: 10,
  },
});

const snapPoints = [100];

const SmsLoginSheet: FC<SheetProps<{ phone: string, }>> = (
  {
    sheetId,
    payload,
  },
) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const { refetchProfile } = useProfileSync();

  const {
    authStore: {
      auth: {
        setToken,
      },
    },
  } = useStore();

  const phone = payload?.phone || '';

  const [secondsToRetry, setSecondsToRetry] = useState<number>(0);
  const [code, setCode] = useState('');

  const [requestOtpCode, { loading: requestOtpCodeLoading }] = useRequestOtpCodeMutation();
  const [authViaOtp, { loading: authViaOtpLoading }] = useAuthViaOtpMutation();

  // TODO: вывести ошибку и при нахождении на другой странице возвращать на эту
  const hasError = false;

  const navigateToLoginScreen = useCallback(async () => {
    await SheetManager.hide(sheetId);
    SheetManager.show('login-sheet');
  }, [sheetId]);

  const [addOrder, { loading: addOrderPending }] = useFastAddOrderMutation();

  const { profileStore: { profile } } = useStore();

  const {
    appStore: {
      activeCartState,
      addOrderInput,
      clearCart,
    },
  } = useStore();

  const canMakeOrder = activeCartState?.canMakeOrder || false;

  const onMakeOrder = useCallback(async () => {
    if (!profile || !activeCartState || !addOrderInput || !canMakeOrder) {
      return;
    }

    const userId = profile.id;

    const addOrderResponse = await addOrder({
      variables: {
        data: {
          userId: Number(userId),
          request: addOrderInput,
        },
      },
    });

    if (!addOrderResponse.data?.createOrderRequest?.data?.id) {
      crossAlert('Ошибка при создании заказа');
    } else {
      clearCart();
      await SheetManager.show('order-created-sheet');
    }
  }, [profile, activeCartState, addOrder, clearCart, addOrderInput, canMakeOrder]);

  useEffect(() => {
    if (addOrderPending) {
      SheetManager.show('order-create-pending-sheet');

      return;
    }

    SheetManager.hide('order-create-pending-sheet');
  }, [addOrderPending]);

  const onAuthViaOtp = useCallback(async () => {
    const res = await authViaOtp({
      variables: {
        input: {
          phone,
          code,
        },
      },
    });

    if (!res.data) {
      crossAlert('Ошибка при авторизации, попробуйте позже.');
      setCode('');
      return;
    }

    const { resultCode } = res.data.authViaOtpMutation;

    switch (resultCode) {
      case AuthViaOtpResultCode.Success: {
        const { jwt } = res.data.authViaOtpMutation;
        if (jwt) {
          setToken(jwt);
        } else {
          crossAlert('Ошибка при авторизации, попробуйте позже.');
        }
        setCode('');
        await refetchProfile();
        await onMakeOrder();
        actionSheetRef.current?.hide();
        return;
      }
      case AuthViaOtpResultCode.InvalidOtpCode:
        crossAlert('Указан неверный код.');
        break;
      case AuthViaOtpResultCode.Fail:
        crossAlert('Ошибка при авторизации, попробуйте позже.');
        break;
      case AuthViaOtpResultCode.UserBlocked:
        crossAlert('Пользователь заблокирован.');
        break;
      default:
        absurd(resultCode);
        crossAlert('Ошибка при авторизации, попробуйте позже.');
        break;
    }

    setCode('');
  }, [authViaOtp, code, setToken, onMakeOrder, phone, refetchProfile]);

  useEffect(() => {
    if (secondsToRetry > 0) {
      const timeout = setTimeout(() => {
        setSecondsToRetry(secondsToRetry - 1);
      }, 1000);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }

    return undefined;
  }, [secondsToRetry]);

  const [pending, setPending] = useState(false);

  useEffect(() => {
    (async () => {
      if (code.length === 4 && !pending) {
        setPending(true);
        try {
          await onAuthViaOtp();
        } catch (e) {
          console.error(e);
        }
        setPending(false);
      }
    })();
  }, [authViaOtpLoading, code, onAuthViaOtp, pending]);

  const subtitle = useMemo(() => `Ожидайте смс на номер ${phone}`, [phone]);

  const onRequestSmsCode = useCallback(async () => {
    const res = await requestOtpCode({
      variables: {
        input: {
          phone,
        },
      },
    });

    setCode('');

    if (!res.data) {
      crossAlert('Ошибка при авторизации, попробуйте позже.');
      return;
    }

    const { resultCode } = res.data.requestOtpCodeMutation;

    switch (resultCode) {
      case RequestOtpCodeResultCode.Fail:
        crossAlert('Ошибка при запросе смс кода, попробуйте позже.');
        break;
      case RequestOtpCodeResultCode.RateLimited:
        setSecondsToRetry(res.data.requestOtpCodeMutation.seconds);
        break;
      case RequestOtpCodeResultCode.Success:
        break;
      default:
        absurd(resultCode);
        crossAlert('Ошибка при запросе смс кода, попробуйте позже.');
        break;
    }
  }, [phone, requestOtpCode]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.container}>
        <View style={s.containerTop}>
          {/* <Image source={images.companyLogoSource} style={s.logo} /> */}
          <Text style={s.header}>
            Введите код
          </Text>
          <Text style={s.title}>{subtitle}</Text>

          <Text style={s.link} onPress={navigateToLoginScreen}>
            Изменить номер
          </Text>

          <View style={s.optWrapper}>
            <OtpInput
              value={code}
              setValue={setCode}
              hasError={hasError}
            />
            {hasError && (
              <Text style={s.error}>
                Указан неверный код. Попробуйте еще раз
              </Text>
            )}
          </View>
        </View>
        <View style={s.flex1}>
          {secondsToRetry > 0 && (
            <Text style={s.messageDuration}>
              {`Повторно отправить смс можно через ${secondsToHumanFormat(secondsToRetry)}`}
            </Text>
          )}
          <ButtonOutline
            disabled={authViaOtpLoading || requestOtpCodeLoading || secondsToRetry > 0}
            loading={requestOtpCodeLoading}
            title="Отправить код повторно"
            onPress={onRequestSmsCode}
          />
          <Button
            loading={authViaOtpLoading}
            title="Продолжить"
            disabled={code.length < 4}
            onPress={onAuthViaOtp}
          />
        </View>
      </View>
    </ActionSheet>
  );
};

export default observer(SmsLoginSheet);
