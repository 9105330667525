import {
  // useEffect,
  useMemo,
} from 'react';
import {
  // useCheckoutLazyQuery,
  useCheckoutQuery,
} from '@app/infrastructureLayer/generated/graphql';
import useStore from '@app/domain/store/useStore';
import { absurd } from 'fp-ts/function';

export type CheckoutData = {
  bonuses: number,
  itemsDiscount: number,
  orderTotal: number,
  totalItemCostForPay: number,
  totalItemsCost: number,
  deliveryInfo?: {
    cityId: string,
    shopId: string,
    deliveryZone: {
      id: string,
      deliveryDurationInMinutes: number,
      deliveryPrice: number,
      minSum: number,
      name: string
    }
  }
};

const useCheckout = () => {
  const {
    appStore: {
      activeMenu,
      checkoutOrderInput,
    },
  } = useStore();

  const isMenuReady = activeMenu?.ready || false;

  const {
    data: rawCheckoutData,
    loading,
  } = useCheckoutQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      input: checkoutOrderInput,
    },
    fetchPolicy: 'cache-and-network',
    skip: !checkoutOrderInput || !isMenuReady,
  });

  const data: CheckoutData | null = useMemo(() => {
    if (!checkoutOrderInput) {
      return null;
    }

    if (!rawCheckoutData?.checkout) {
      return null;
    }
    const type = rawCheckoutData.checkout.__typename;

    switch (type) {
      case 'AddOrderResponseError':
      case 'AddOrderResponseInputValidationError':
        //  TODO: Может ошибку checkout надо как-то показывать?
        // setCheckoutData(null);
        return null;
      case 'CheckoutResponseSuccess':
        // TODO: Типизировать нормально...
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return rawCheckoutData.checkout as CheckoutData;
      default:
        absurd(type);
        return null;
    }
  }, [rawCheckoutData, checkoutOrderInput]);

  const result = useMemo(() => ({
    data,
    loading,
  }), [data, loading]);

  return result;
};

export default useCheckout;
