import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { City } from '@app/domain/store/CoreStore/AppStore/entities/City';
import { Coordinates } from '@app/domain/store/CoreStore/AppStore/entities/Coordinates';
import { PaymentType } from '@app/domain/store/CoreStore/AppStore/entities/PaymentType';
import compact from 'lodash/compact';
import { OrderType } from '@app/domain/store/CoreStore/AppStore/entities/OrderType';
import { ShopWorkTime } from '@app/domain/store/CoreStore/AppStore/entities/ShopWorkTime/ShopWorkTime';

export const Shop = t
  .model('Shop', {
    id: t.identifier,
    title: t.string,
    address: t.string,
    timezone: t.string,
    phoneForClients: t.string,
    workTimeTitle: t.string,
    city: t.safeReference(City),
    coordinates: t.maybeNull(Coordinates),

    useBonuses: t.boolean,
    useCutlery: t.boolean,
    usePromoCodes: t.boolean,

    allowedPaymentTypes: t.array(t.safeReference(PaymentType)),
    allowedOrderTypes: t.array(t.safeReference(OrderType)),
    workTime: ShopWorkTime,
  }).views((self) => ({
    get allowedPaymentTypesIds() {
      return compact(self.allowedPaymentTypes.map((pa) => pa?.id));
    },
  }));

export interface ShopInstance extends Instance<typeof Shop> {
}

export interface ShopSnapshotIn extends SnapshotIn<typeof Shop> {
}

export interface ShopSnapshotOut extends SnapshotOut<typeof Shop> {
}
