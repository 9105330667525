import {
  addDisposer,
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import moment from 'moment-timezone';
import currency from 'currency.js';
import { City, CitySnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/City';
import { Shop, ShopSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Shop';
import { comparer, reaction } from 'mobx';
import { OrderType, OrderTypeSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/OrderType';
import { Category, CategorySnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Category';
import { MenuForShop } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuForShop';
import type { MenuItemInstance, MenuItemSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import compact from 'lodash/compact';
import { Product, ProductSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Product';
import { CartItem, getCartItemKey } from '@app/domain/store/CoreStore/AppStore/entities/Cart/CartItem';
import { CartState } from '@app/domain/store/CoreStore/AppStore/entities/CartState';
import { PaymentType, PaymentTypeSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/PaymentType';
import {
  AddOrderInput,
  CheckoutOrderInput,
  Enum_Ordertype_Ordertypecode,
  Enum_Paymenttype_Paymenttypecode,
} from '@app/infrastructureLayer/generated/graphql';
import { GlobalSettings, GlobalSettingsSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/GlobalSettings';
import { ProfileStore } from '@app/domain/store/CoreStore/ProfileStore/ProfileStore';
import {
  ModifierCategory,
  ModifierCategorySnapshotIn,
} from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierCategory';
import { Modifier, ModifierSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/Modifier';
import {
  ModifierMenuItemSnapshotIn,
} from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierMenuItem';
import { CartItemModifierSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Cart/CartItemModifier';
import { v4 as uuidv4 } from 'uuid';
import { DeviceVarsStore } from '@app/domain/store/CoreStore/DeviceVarsStore/DeviceVarsStore';
import ShopData from './entities/ShopData';

type CartItemProps = {
  productId: string;
  modifiers?: {
    [key: string]: number;
  }
};

export const AppStore = t
  .model('AppStore', {
    id: t.optional(t.identifier, 'AppStore'),
    profileStore: t.optional(t.reference(ProfileStore), 'ProfileStore'),
    deviceVarsStore: t.optional(t.reference(DeviceVarsStore), 'DeviceVarsStore'),
    cities: t.optional(t.map(City), {}),
    shops: t.optional(t.map(Shop), {}),
    orderTypes: t.optional(t.map(OrderType), {}),
    categories: t.optional(t.map(Category), {}),
    modifierCategories: t.optional(t.map(ModifierCategory), {}),
    products: t.optional(t.map(Product), {}),
    modifiers: t.optional(t.map(Modifier), {}),
    paymentTypes: t.optional(t.map(PaymentType), {}),

    selectedCityId: t.maybeNull(t.string),
    selectedShopId: t.maybeNull(t.string),
    selectedOrderTypeId: t.maybeNull(t.string),

    menus: t.optional(t.map(MenuForShop), {}),
    cart: t.optional(t.map(CartItem), {}),
    cartStates: t.optional(t.map(CartState), {}),

    globalSettings: t.maybeNull(GlobalSettings),

    selectedDeliveryAddressId: t.maybeNull(t.string),
    searchQuery: t.optional(t.string, ''),
  })
  .views((self) => ({
    get hasSearchQuery() {
      return self.searchQuery.length > 0;
    },
    get activeMenu() {
      if (!self.selectedShopId) {
        return undefined;
      }

      return self.menus.get(self.selectedShopId);
    },
    get selectedDeliveryAddressForCurrentCity() {
      if (!self.selectedDeliveryAddressId || !self.selectedCityId || !self.profileStore.profile) {
        return undefined;
      }

      const addressForCurrentCity = self.profileStore.profile.deliveryAddressesByCityId[self.selectedCityId];

      if (!addressForCurrentCity) {
        return undefined;
      }

      return addressForCurrentCity.find((a) => a.id === self.selectedDeliveryAddressId);
    },
    get deliveryOrderType() {
      return [...self.orderTypes.values()].find((ot) => ot && ot.type === Enum_Ordertype_Ordertypecode.Delivery);
    },
    get citiesSetWithDelivery() {
      const citiesIds: Set<string> = new Set();

      self.shops.forEach((shop) => {
        if (!shop.city?.id) {
          return;
        }

        if (shop.allowedOrderTypes.some((ot) => ot && ot.type === Enum_Ordertype_Ordertypecode.Delivery)) {
          citiesIds.add(shop.city.id);
        }
      });

      return citiesIds;
    },
    get citiesWithShops() {
      const citiesIds: Set<string> = new Set();

      self.shops.forEach((shop) => {
        if (!shop.city?.id) {
          return;
        }

        citiesIds.add(shop.city.id);
      });

      return compact([...citiesIds.values()].map((cityId) => self.cities.get(cityId)));
    },
    get shopsDataForMap() {
      const shopsDataArr: Array<ShopData> = [];
      self.shops.forEach((shop) => {
        if (shop.coordinates) {
          const data = {
            latitude: shop.coordinates.latitude,
            longitude: shop.coordinates.longitude,
            address: shop.address,
            id: shop.id,
          };
          shopsDataArr.push(data);
        }
      });
      return shopsDataArr;
    },
    get shopsDataForCurrentCityForMap() {
      const shopsDataArr: Array<ShopData> = [];
      self.shops.forEach((shop) => {
        if (shop.coordinates && self.selectedCityId === shop.city?.id) {
          const data = {
            latitude: shop.coordinates.latitude,
            longitude: shop.coordinates.longitude,
            address: shop.address,
            id: shop.id,
          };
          shopsDataArr.push(data);
        }
      });
      return shopsDataArr;
    },
    findCartItem: (
      {
        productId,
        modifiers,
      }: CartItemProps,
    ) => {
      const cartItemKey = getCartItemKey({
        productId,
        modifiers,
      });

      return self.cart.get(cartItemKey);
    },
  }))
  .views((self) => ({
    get pricesByModifierId() {
      const map = new Map<string, number>();

      if (!self.activeMenu) {
        return map;
      }

      [...self.activeMenu.modifierMenuItems.values()].forEach((mmi) => {
        map.set(mmi.modifierId, mmi.price);
      });

      return map;
    },
  }))
  .views((self) => ({
    get activeCartItems() {
      if (!self.activeMenu) {
        return [];
      }

      return [...self.cart.values()].filter((cartItem) => {
        const notExistedModifiers = cartItem
          .modifiers
          .filter((m) => !m.modifier).length > 0;

        return !notExistedModifiers && !!cartItem.product;
      });
    },
    getModifierIsAvailableInActiveMenuByModifierId: (modifierId: string): boolean => {
      if (!self.activeMenu) {
        return false;
      }

      let isAvailable: boolean = false;

      self.activeMenu.modifierMenuItems.forEach((mmi) => {
        if (mmi.modifierId === modifierId) {
          isAvailable = mmi.isAvailable;
        }
      });

      return isAvailable;
    },
    get isSelectedCitySupportDelivery() {
      if (!self.selectedCityId) {
        return false;
      }

      return self.citiesSetWithDelivery.has(self.selectedCityId);
    },
    get activeCartState() {
      if (!self.selectedShopId) {
        return undefined;
      }

      return self.cartStates.get(self.selectedShopId);
    },
  }))
  .actions((self) => ({
    setSearchQuery: (value: string) => {
      self.searchQuery = value;
    },
    setGlobalSettings: (value: GlobalSettingsSnapshotIn) => {
      if (!self.globalSettings) {
        self.globalSettings = GlobalSettings.create(value);

        return;
      }

      applySnapshot(self.globalSettings, value);
    },
    increaseCartItemAmount: (
      {
        productId,
        modifiers,
      }: CartItemProps,
    ) => {
      const cartItemKey = getCartItemKey({
        productId,
        modifiers,
      });

      let cartItem = self.cart.get(cartItemKey);

      if (!cartItem) {
        let modifiersToCartItem: CartItemModifierSnapshotIn[] | undefined;

        if (modifiers) {
          modifiersToCartItem = Object.keys(modifiers)
            .map((modifierId) => ({
              id: uuidv4(),
              modifierId,
              amount: modifiers[modifierId],
            }));
        }

        cartItem = CartItem.create({
          id: cartItemKey,
          productId,
          amount: 1,
          modifiers: modifiersToCartItem,
        });

        self.cart.set(cartItem.id, cartItem);

        return;
      }

      cartItem.increaseAmount();
    },
    reduceCartItemAmountById: (cartItemId: string) => {
      self.cart.get(cartItemId)
        ?.reduceAmount();
    },
    removeCartItemById: (cartItemId: string) => {
      self.cart.delete(cartItemId);
    },
    reduceCartItemAmount: (
      {
        productId,
        modifiers,
      }: CartItemProps,
    ) => {
      if (!self.selectedShopId) {
        console.error('Пробуют убавить в корзине когда не выбран магазин');

        return;
      }

      const cartItemKey = getCartItemKey({
        productId,
        modifiers,
      });

      const cartItem = self.cart.get(cartItemKey);

      if (!cartItem) {
        return;
      }

      cartItem.reduceAmount();
    },
    menuItemByProductId: (productId: string) => {
      if (!self.activeMenu) {
        return undefined;
      }

      const menuItems = [...self.activeMenu.menuItems.values()];

      return menuItems.find((mi) => mi.product?.id === productId);
    },
    menuModifierItemByModifierId: (modifierId: string) => {
      if (!self.activeMenu) {
        return undefined;
      }

      const modifierMenuItems = [...self.activeMenu.modifierMenuItems.values()];

      return modifierMenuItems.find((mmi) => mmi.modifier?.id === modifierId);
    },
  }))
  .views((self) => ({
    get activeCartItemsHaveNotAvailableItems() {
      if (!self.activeCartItems) {
        return false;
      }

      return self.activeCartItems.some((c) => c.haveNotAvailableModifiers || !c.menuItem?.isAvailable);
    },
    get ready() {
      return (
        self.cities.size > 0
        && self.shops.size > 0
        && self.orderTypes.size > 0
        && self.categories.size > 0
        && !!self.globalSettings
      );
    },
    get city() {
      if (!self.selectedCityId) {
        return null;
      }

      return self.cities.get(self.selectedCityId);
    },
    get shop() {
      if (!self.selectedShopId) {
        return null;
      }

      return self.shops.get(self.selectedShopId);
    },
    get orderType() {
      if (!self.selectedOrderTypeId) {
        return null;
      }

      return self.orderTypes.get(self.selectedOrderTypeId);
    },
    shopsForCity(cityId: string) {
      return [...self.shops.values()].filter((shop) => shop.city?.id === cityId);
    },
    get totalAmount() {
      return [...self.cart.values()]
        .reduce(
          (sum, cartItem) => {
            if (!cartItem.menuItem) {
              return sum;
            }

            const notExistedModifiers = cartItem
              .modifiers
              .filter((m) => !m.menuModifierItem).length > 0;

            if (notExistedModifiers) {
              return sum;
            }

            return sum.add(cartItem.amount);
          },
          currency(0),
        )
        .value;
    },
    get totalItemsCost() {
      if (!self.activeMenu) {
        return 0;
      }

      return [...self.cart.values()].reduce(
        (sum, cartItem) => {
          if (!cartItem.menuItem) {
            return sum;
          }

          const { menuItem } = cartItem;

          let modifiersSum = currency(0);
          if (cartItem.modifiers.length > 0) {
            const notExistedModifiers = cartItem.modifiers.filter((m) => !m.menuModifierItem).length > 0;

            if (notExistedModifiers) {
              return sum;
            }

            modifiersSum = cartItem.modifiers.reduce((xSum, m) => {
              if (!m.menuModifierItem) {
                return xSum;
              }

              return xSum.add(currency(m.menuModifierItem.price)
                .multiply(m.amount));
            }, currency(0));
          }

          const positionPrice = currency(menuItem.price)
            .add(modifiersSum)
            .multiply(cartItem.amount);

          return sum.add(positionPrice);
        },
        currency(0),
      ).value;
    },
  }))
  .views((self) => ({
    get shopsForSelectedCity() {
      if (!self.selectedCityId) {
        return [];
      }

      return self.shopsForCity(self.selectedCityId);
    },
    getFirstShopForCity(cityId: string) {
      return self.shopsForCity(cityId)[0];
    },
  }))
  .views((self) => ({
    getAmountOfCartItemsWithSameProduct: (productId: string, withModifiers?: boolean) => (
      [...self.cart.values()]
        .filter((cart) => {
          if (withModifiers || typeof withModifiers === 'undefined') {
            // Если для товара в корзине указаны не существующие модификаторы то считать его не надо
            if (cart.haveNotExistedModifiers) {
              return false;
            }

            return cart.productId === productId;
          }

          return cart.productId === productId && cart.modifiers.length === 0;
        })
        .reduce((sum, cartItem) => sum + cartItem.amount, 0)
    ),
    getCartItemTotal: (cartItemId: string): {
      single: number, // цена 1 позиции
      all: number, // цена множенное на кол-во позиций
    } => {
      const cartItem = self.cart.get(cartItemId);

      if (!cartItem) {
        return {
          single: 0,
          all: 0,
        };
      }

      const menuItem = self.menuItemByProductId(cartItem.productId);

      if (!menuItem) {
        return {
          single: 0,
          all: 0,
        };
      }

      if (cartItem.modifiers.length === 0) {
        return {
          single: menuItem.price,
          all: currency(menuItem.price)
            .multiply(cartItem.amount).value,
        };
      }

      const priceForModifiersInOneItem = cartItem.modifiers
        .reduce((sum, modifier) => {
          const modifierCartItem = self.menuModifierItemByModifierId(modifier.modifierId);

          if (!modifierCartItem) {
            return sum;
          }

          return sum.add(currency(modifierCartItem.price)
            .multiply(modifier.amount));
        }, currency(0))
        .value;

      const single = currency(menuItem.price)
        .add(priceForModifiersInOneItem).value;

      return {
        single,
        all: currency(single)
          .multiply(cartItem.amount).value,
      };
    },
    get categoriesForActiveMenu() {
      const menuForShop = self.activeMenu;

      if (!menuForShop) {
        return [];
      }

      return [...self.categories.values()].filter((category) => menuForShop.categoriesIds.includes(category.id));
    },
    menuItemsForActiveMenuByCategory: (categoryId: string): MenuItemInstance[] => {
      if (!self.activeMenu) {
        return [];
      }

      return [...self.activeMenu.menuItems.values()].filter((menuItem) => {
        const sameCategory = menuItem.product?.category?.id === categoryId;

        return sameCategory && menuItem.isAvailable && menuItem.groupModifiersIsCorrect;
      });
    },
    getCartItemById: (cartItemId: string) => self.cart.get(cartItemId),
  }))
  .views((self) => ({
    // Используется для SectionList
    get activeMenuSections() {
      if (!self.categoriesForActiveMenu) {
        return [];
      }

      return compact(self.categoriesForActiveMenu.map((category) => {
        let data: MenuItemInstance[];
        if (!self.searchQuery.length) {
          data = self.menuItemsForActiveMenuByCategory(category.id);
        } else {
          data = self.menuItemsForActiveMenuByCategory(category.id)
            .filter((item) => {
              if (!self.searchQuery.length) {
                return true;
              }

              return item.product?.title.toLowerCase()
                .includes(self.searchQuery.toLowerCase());
            });
        }

        if (!data || !data.length) {
          return null;
        }

        return ({
          title: category.title,
          categoryId: category.id,
          data,
        });
      }));
    },
    get checkoutOrderInput(): CheckoutOrderInput | undefined {
      const {
        activeCartState,
        activeCartItems,
      } = self;

      if (
        !activeCartState
        || !activeCartItems.length
        || !self.selectedOrderTypeId
        || !self.activeMenu
        || !self.activeMenu.ready
        || !self.orderType
      ) {
        return undefined;
      }

      // Так мы отсекаем товаров которых вообще нет в ответе апи больше, их показывать не будем.
      let haveItemsNotPresentedInApiResponse = false;
      // Недоступные товары
      let haveNotAvailableItems = false;

      const orderItems = compact(activeCartItems.map((cartItem) => {
        const { menuItem } = cartItem;

        if (
          menuItem
          && (
            !menuItem.isAvailable
            || cartItem.haveNotAvailableModifiers
          )
        ) {
          haveNotAvailableItems = true;
        }

        if (!menuItem) {
          haveItemsNotPresentedInApiResponse = true;

          return null;
        }

        const modifiers = compact(cartItem.modifiers.map((modifier) => {
          let modifierCategoryId: string | undefined;
          let modifierMenuItemId: string | undefined;

          menuItem.groupModifiers.forEach((gm) => {
            if (modifierCategoryId && modifierMenuItemId) {
              return;
            }

            if (!modifier.menuModifierItem) {
              return;
            }

            const item = gm.childModifiers.find((cm) => cm.modifierId === modifier.modifierId);

            if (item) {
              modifierCategoryId = gm.modifierCategoryId;
              modifierMenuItemId = modifier.menuModifierItem.id;
            }
          });

          if (!modifierCategoryId || !modifierMenuItemId) {
            return null;
          }

          return ({
            modifierCategoryId,
            modifierMenuItemId,
            amount: modifier.amount,
          });
        }));

        return ({
          menuItemId: menuItem.id,
          amount: cartItem.amount,
          modifiers: modifiers.length > 0 ? modifiers : undefined,
        });
      }));

      // TODO: Что-то надо делать с недоступными товарами?
      // eslint-disable-next-line no-console
      console.log('haveItemsNotPresentedInApiResponse', haveItemsNotPresentedInApiResponse);

      if (orderItems.length === 0 || haveNotAvailableItems) {
        return undefined;
      }

      return {
        orderTypeId: self.selectedOrderTypeId,
        shopId: activeCartState.shopId,
        orderItems,
        addressId: self.orderType.type === 'DELIVERY' ? self.selectedDeliveryAddressId : null,
        bonuses: activeCartState.bonusesToPayAmount,
        promoCode: activeCartState.promoCode,
      };
    },
    get addOrderInput(): AddOrderInput | undefined {
      const {
        activeCartState,
        activeCartItems,
      } = self;

      if (
        !activeCartState
        || !activeCartItems.length
        || !self.selectedOrderTypeId
        || !self.activeMenu
        || !self.activeMenu.ready
        || !self.orderType
      ) {
        return undefined;
      }

      // Так мы отсекаем товаров которых вообще нет в ответе апи больше, их показывать не будем.
      let haveItemsNotPresentedInApiResponse = false;
      // Недоступные товары
      let haveNotAvailableItems = false;

      const orderItems = compact(activeCartItems.map((cartItem) => {
        const { menuItem } = cartItem;

        if (
          menuItem
          && (
            !menuItem.isAvailable
            || cartItem.haveNotAvailableModifiers
          )
        ) {
          haveNotAvailableItems = true;
        }

        if (!menuItem) {
          haveItemsNotPresentedInApiResponse = true;

          return null;
        }

        const modifiers = compact(cartItem.modifiers.map((modifier) => {
          let modifierCategoryId: string | undefined;
          let modifierMenuItemId: string | undefined;

          menuItem.groupModifiers.forEach((gm) => {
            if (modifierCategoryId && modifierMenuItemId) {
              return;
            }

            if (!modifier.menuModifierItem) {
              return;
            }

            const item = gm.childModifiers.find((cm) => cm.modifierId === modifier.modifierId);

            if (item) {
              modifierCategoryId = gm.modifierCategoryId;
              modifierMenuItemId = modifier.menuModifierItem.id;
            }
          });

          if (!modifierCategoryId || !modifierMenuItemId) {
            return null;
          }

          return ({
            modifierCategoryId,
            modifierMenuItemId,
            amount: modifier.amount,
          });
        }));

        return ({
          menuItemId: menuItem.id,
          amount: cartItem.amount,
          modifiers: modifiers.length > 0 ? modifiers : undefined,
        });
      }));

      // TODO: Что-то надо делать с недоступными товарами?
      // eslint-disable-next-line no-console
      console.log('haveItemsNotPresentedInApiResponse', haveItemsNotPresentedInApiResponse);

      if (orderItems.length === 0 || haveNotAvailableItems) {
        return undefined;
      }

      const isOrderInRoom = self.orderType.type === Enum_Ordertype_Ordertypecode.InRoom;

      let date: string | undefined;
      let time: string | undefined;
      if (activeCartState.isAsap && activeCartState.selectedAsapOption) {
        const dateMoment = moment.unix(activeCartState.selectedAsapOption.value);
        date = dateMoment.format('YYYY-MM-DD');
        time = dateMoment.format('HH:mm');
      }

      if (!activeCartState.isAsap && activeCartState.date && activeCartState.time) {
        date = moment(activeCartState.date, 'DD.MM.YYYY')
          .format('YYYY-MM-DD');
        time = activeCartState.time;
      }

      if (!date || !time) {
        return undefined;
      }

      return {
        orderTypeId: self.selectedOrderTypeId,
        shopId: activeCartState.shopId,
        paymentTypeId: activeCartState.paymentTypeId!,
        date,
        time,
        orderItems,
        comment: activeCartState.comment,
        addressId: self.orderType.type === 'DELIVERY' ? self.selectedDeliveryAddressId : null,
        bonuses: activeCartState.bonusesToPayAmount,
        promoCode: activeCartState.promoCode,
        roomNumber: isOrderInRoom ? self.deviceVarsStore.roomNumber : undefined,
        cardId: (
          activeCartState.paymentType?.type === Enum_Paymenttype_Paymenttypecode.Card
          && activeCartState.cardId
        ) ? activeCartState.cardId : undefined,
      };
    },
  }))
  .actions((self) => ({
    clearCart: () => {
      self.cart.clear();
    },
    setCities: (values: CitySnapshotIn[]) => {
      self.cities.clear();
      values.forEach((city) => {
        self.cities.set(city.id, city);
      });
    },
    setPaymentTypes: (values: PaymentTypeSnapshotIn[]) => {
      self.paymentTypes.clear();
      values.forEach((paymentType) => {
        self.paymentTypes.set(paymentType.id, paymentType);
      });
    },
    setShops: (values: ShopSnapshotIn[]) => {
      self.shops.clear();
      values.forEach((shop) => {
        self.shops.set(shop.id, shop);
      });
    },
    setOrderTypes: (values: OrderTypeSnapshotIn[]) => {
      self.orderTypes.clear();
      values.forEach((orderType) => {
        self.orderTypes.set(orderType.id, orderType);
      });
    },
    setCategories: (values: CategorySnapshotIn[]) => {
      self.categories.clear();
      values.forEach((category) => {
        self.categories.set(category.id, category);
      });
    },
    setModifierCategories: (values: ModifierCategorySnapshotIn[]) => {
      self.modifierCategories.clear();
      values.forEach((modifierCategory) => {
        self.modifierCategories.set(modifierCategory.id, modifierCategory);
      });
    },
    setProducts: (values: ProductSnapshotIn[]) => {
      // @ts-ignore
      applySnapshot(self.products, values);
    },
    setModifiers: (values: ModifierSnapshotIn[]) => {
      // @ts-ignore
      applySnapshot(self.modifiers, values);
    },
    setSelectedCityId: (cityId: string | null) => {
      self.selectedCityId = cityId;
    },
    setSelectedShopId: (shopId: string | null) => {
      self.selectedShopId = shopId;
    },
    setSelectedDeliveryAddressId: (addressId: string | null) => {
      self.selectedDeliveryAddressId = addressId;
    },
    setSelectedOrderTypeId: (orderTypeId: string | null) => {
      self.selectedOrderTypeId = orderTypeId;
    },
  }))
  .views((self) => ({
    get selectedShopOrderTypes() {
      if (!self.shop) {
        return [];
      }

      let computedOptionsHasDelivery = false;

      const computedOptions = compact(self.shop?.allowedOrderTypes.map((op) => {
        if (!op) {
          return null;
        }

        if (self.deliveryOrderType && op.id === self.deliveryOrderType.id) {
          computedOptionsHasDelivery = true;
        }

        return op;
      }));

      if (!computedOptionsHasDelivery && self.isSelectedCitySupportDelivery && self.deliveryOrderType) {
        computedOptions.push(self.deliveryOrderType);
      }

      return computedOptions.sort((a, b) => a.id.localeCompare(b.id));
    },
  }))
  .actions((self) => ({
    setMenuItemsForShop: (
      {
        shopId,
        menuItems,
        modifierMenuItems,
        categoriesIds,
      }: {
        shopId: string,
        menuItems: MenuItemSnapshotIn[],
        modifierMenuItems: ModifierMenuItemSnapshotIn[],
        categoriesIds: string[],
      },
    ) => {
      let menu = self.menus.get(shopId);

      if (!menu) {
        menu = MenuForShop.create({
          shopId,
          menuItems: {},
          modifierMenuItems: {},
        });
      }

      self.menus.set(shopId, menu);
      // @ts-ignore
      applySnapshot(menu.menuItems, menuItems);
      // @ts-ignore
      applySnapshot(menu.modifierMenuItems, modifierMenuItems);
      // @ts-ignore
      applySnapshot(menu.categoriesIds, categoriesIds);
      menu.setReady(true);
    },
  }))
  .views((self) => ({
    get totalItemsInCart() {
      return [...self.cart.values()]
        .reduce((sum, cartItem) => {
          const modifiesCount = cartItem.modifiers.reduce((modSum, modifier) => modSum + modifier.amount, 0);

          return sum + modifiesCount + cartItem.amount;
        }, 0);
    },
  }))
  .views((self) => ({
    productTimeAvailability(menuItemId: string | undefined | null, cartTimeState: string | undefined | null) {
      if (!menuItemId) {
        return false;
      }

      const menuItem = self.activeMenu?.menuItems?.get(menuItemId);

      if (!menuItem || !cartTimeState) {
        return false;
      }

      if (menuItem.timeLimit) {
        const isOrderBeforeEnd = moment(cartTimeState, 'HH:mm')
          .isBefore(moment(menuItem.timeLimit.endTime, 'HH:mm:ss'));
        const isOrderAfterStart = moment(cartTimeState, 'HH:mm')
          .isAfter(moment(menuItem.timeLimit.startTime, 'HH:mm:ss'));

        if (!isOrderBeforeEnd || !isOrderAfterStart) {
          return false;
        }
      }

      return true;
    },
    categoryTimeAvailability(menuItemId: string | undefined | null, cartTimeState: string | undefined | null) {
      if (!menuItemId) {
        return false;
      }
      const menuItem = self.activeMenu?.menuItems?.get(menuItemId);
      const category = menuItem?.product?.category;

      if (!category || !cartTimeState) {
        return false;
      }

      if (category.timeLimit) {
        const isOrderBeforeEnd = moment(cartTimeState, 'HH:mm')
          .isBefore(moment(category.timeLimit.endTime, 'HH:mm:ss'));
        const isOrderAfterStart = moment(cartTimeState, 'HH:mm')
          .isAfter(moment(category.timeLimit.startTime, 'HH:mm:ss'));

        if (!isOrderBeforeEnd || !isOrderAfterStart) {
          return false;
        }
      }

      return true;
    },
  }))
  .actions((self) => ({
    afterCreate: () => {
      /**
       * Если выбран город или магазин которого нет в списке, то очищаем значение выбранного
       */
      addDisposer(
        self,
        reaction(
          () => ({
            selectedCityId: self.selectedCityId,
            selectedShopId: self.selectedShopId,
            citiesIds: [...self.cities.values()].map((x) => x.id),
            shopsIds: [...self.shops.values()].map((x) => x.id),
          }),
          (
            {
              selectedCityId,
              citiesIds,
              selectedShopId,
              shopsIds,
            },
          ) => {
            if (shopsIds.length > 0 && selectedShopId) {
              if (!shopsIds.includes(selectedShopId)) {
                self.setSelectedShopId(shopsIds[0]);
              }
            }

            if (citiesIds.length > 0 && selectedCityId) {
              if (!citiesIds.includes(selectedCityId)) {
                self.setSelectedCityId(null);
              }
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Если не выбран город, а города загружены, то по дефолту выбираем первый попавшийся город
       */
      addDisposer(
        self,
        reaction(
          () => ({
            hydrated: self.deviceVarsStore.hydrated,
            cityNotSelected: !self.selectedCityId,
            citiesCount: self.cities.size,
          }),
          (
            {
              hydrated,
              cityNotSelected,
              citiesCount,
            },
          ) => {
            if (!hydrated) {
              return;
            }

            if (cityNotSelected && citiesCount) {
              self.setSelectedCityId([...self.cities.values()][0].id);
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Если не выбран магазин, а магазины и города загружены, то по дефолту выбираем первый попавшийся магазин
       */
      addDisposer(
        self,
        reaction(
          () => ({
            hydrated: self.deviceVarsStore.hydrated,
            lastShopId: self.deviceVarsStore.lastShopId,
            city: self.city,
            selectedShopId: self.selectedShopId,
            shopsIds: [...self.shops.values()].map((x) => x.id),
          }),
          (
            {
              city,
              selectedShopId,
              shopsIds,
              hydrated,
              lastShopId,
            },
          ) => {
            if (!hydrated) {
              return;
            }

            if (lastShopId && shopsIds.length) {
              if (shopsIds.includes(lastShopId) && selectedShopId !== lastShopId) {
                self.setSelectedShopId(lastShopId);
                return;
              }

              if (!shopsIds.includes(lastShopId)) {
                self.deviceVarsStore.setLastShopId(undefined);
              }
            }

            if (city && !selectedShopId && shopsIds.length > 0) {
              let isDone = false;
              self.shops.forEach((shop) => {
                if (isDone) {
                  return;
                }

                if (shop.city?.id === city.id) {
                  self.setSelectedShopId(shop.id);
                  isDone = true;
                }
              });
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Если не выбран тип заказа, а типы загружены, то по дефолту выбираем первый попавшийся тип заказа
       */
      addDisposer(
        self,
        reaction(
          () => ({
            orderTypeNotSelected: !self.selectedOrderTypeId,
            orderTypesCount: self.orderTypes.size,
            orderTypes: self.orderTypes,
          }),
          (
            {
              orderTypeNotSelected,
              orderTypesCount,
              orderTypes,
            },
          ) => {
            if (orderTypeNotSelected && orderTypesCount) {
              self.setSelectedOrderTypeId([...orderTypes.values()][0].id);
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Если выбран магазин, то надо проверить что выбран правильный город
       */
      addDisposer(
        self,
        reaction(
          () => ({
            shop: self.shop,
            shopCityId: self.shop?.city?.id,
            selectedCityId: self.selectedCityId,
          }),
          (
            {
              shop,
              shopCityId,
              selectedCityId,
            },
          ) => {
            if (shop && shopCityId) {
              if (!selectedCityId) {
                self.setSelectedCityId(shopCityId);
                return;
              }

              if (selectedCityId !== shopCityId) {
                self.setSelectedCityId(shopCityId);
              }
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Если не выбрана активная категория в активном меню, то берём первую попавшуюся
       */
      addDisposer(
        self,
        reaction(
          () => ({
            hasActiveMenu: !!self.activeMenu,
            selectedCategoryId: self.activeMenu?.selectedCategoryId,
            hasCategoriesForActiveMenu: self.categoriesForActiveMenu.length > 0,
            categoriesForActiveMenu: self.categoriesForActiveMenu,
          }),
          (
            {
              hasActiveMenu,
              selectedCategoryId,
              hasCategoriesForActiveMenu,
              categoriesForActiveMenu,
            },
          ) => {
            if (hasActiveMenu && !selectedCategoryId && hasCategoriesForActiveMenu) {
              self.activeMenu?.setSelectedCategoryId(categoriesForActiveMenu[0].id);
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Если выбрана активная категория но она не доступа в меню, то выбираем первую попавшуюся
       */
      addDisposer(
        self,
        reaction(
          () => ({
            hasActiveMenu: !!self.activeMenu,
            selectedCategoryId: self.activeMenu?.selectedCategoryId,
            hasCategoriesForActiveMenu: self.categoriesForActiveMenu.length > 0,
          }),
          (
            {
              hasActiveMenu,
              selectedCategoryId,
              hasCategoriesForActiveMenu,
            },
          ) => {
            if (hasActiveMenu && !!selectedCategoryId && hasCategoriesForActiveMenu) {
              const isOk = self.categoriesForActiveMenu
                .some((category) => category.id === selectedCategoryId);
              if (!isOk) {
                self.activeMenu?.setSelectedCategoryId(self.categoriesForActiveMenu[0].id);
              }
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Если в выбранном магазине нет выбранного типа заказа, то выбираем первый попавшийся тип заказа
       */
      addDisposer(
        self,
        reaction(
          () => ({
            selectedShopOrderTypesIds: self.selectedShopOrderTypes.map((o) => o.id),
            selectedOrderTypeId: self.selectedOrderTypeId,
          }),
          (
            {
              selectedOrderTypeId,
              selectedShopOrderTypesIds,
            },
          ) => {
            if (!selectedShopOrderTypesIds.length) {
              return;
            }

            if (selectedOrderTypeId && !selectedShopOrderTypesIds.includes(selectedOrderTypeId)) {
              self.setSelectedOrderTypeId(selectedShopOrderTypesIds[0]);
            }

            if (!selectedOrderTypeId) {
              self.setSelectedOrderTypeId(selectedShopOrderTypesIds[0]);
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );

      /**
       * Указываем актуальную timezone для текущего магазина
       */
      addDisposer(
        self,
        reaction(
          () => ({
            shopTimeZone: self.shop?.timezone,
          }),
          ({ shopTimeZone }) => {
            if (shopTimeZone) {
              moment.tz.setDefault(shopTimeZone);
            } else {
              moment.tz.setDefault();
            }
          },
        ),
      );

      /**
       * Для все продуктов указываем сколько такого товара в корзине,
       * чтобы показывать на главном экране меню общее кол-во
       *
       * Используется для оптимизации отображения кол-ва товара в корзине на главном экране меню, по сути это костыль для оптимизации
       *
       * Причина оптимизации функция getAmountOfCartItemsWithSameProduct ранее используемая в компоненте MenuItem мутирует и вызывает рендер каждого элемента в меню.
       */
      addDisposer(
        self,
        reaction(
          () => ({
            totalItemsInCart: self.totalItemsInCart,
            activeMenuItems: self.activeMenu ? [...self.activeMenu.menuItems.values()] : undefined,
          }),
          (
            {
              activeMenuItems,
            },
          ) => {
            if (!activeMenuItems) {
              return;
            }

            activeMenuItems.forEach((menuItem) => {
              const { product } = menuItem;

              if (!product) {
                return;
              }
              const productId = product?.id;

              const inCartCount = self.getAmountOfCartItemsWithSameProduct(productId, undefined);

              product.setInCartCount(inCartCount);
            });
          },
          {
            equals: comparer.shallow,
          },
        ),
      );

      /**
       * Если есть cartItem для магазина, то однозначно должен быть cartState!
       */
      addDisposer(
        self,
        reaction(
          () => ({
            cartSize: self.cart.size,
            selectedShopId: self.selectedShopId,
          }),
          (
            {
              cartSize,
              selectedShopId,
            },
          ) => {
            if (!cartSize || !selectedShopId) {
              return;
            }

            if (!self.cartStates.has(selectedShopId)) {
              self.cartStates.set(selectedShopId, CartState.create({
                shopId: selectedShopId,
              }));
            }
          },
          {
            equals: comparer.structural,
          },
        ),
      );
    },
  }));

export interface AppStoreInstance extends Instance<typeof AppStore> {
}

export interface AppStoreSnapshotIn extends SnapshotIn<typeof AppStore> {
}

export interface AppStoreSnapshotOut extends SnapshotOut<typeof AppStore> {
}
