import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View,
  Text,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import DeliveryDayVariants from '@components/sheets/SelectOrderDateTimeSheet/DeliveryDayVariants';
import WheelTimePicker from '@components/WheelTimePicker/WheelTimePicker';
import { HOURS, MINUTES, WheelTimePickerValue } from '@components/WheelTimePicker/types';
import Segmented from '@components/common/Segmented/Segmented';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  scrollView: {
    width: '100%',
  },
  contentRoot: {
    gap: 10,
    flexDirection: 'column',
    marginBottom: 15,
    width: '100%',
    justifyContent: 'center',
  },
  radioContainer: {
    width: '100%',
    backgroundColor: colors.lightGrey,
    padding: 5,
    borderRadius: 10,
  },
  timePickerRoot: {
    // marginHorizontal: 25,
  },
});

const snapPoints = [100];

const timeTypeOptions = [
  {
    value: true,
    title: 'Ближайшее время',
  },
  {
    value: false,
    title: 'Предзаказ',
  },
];

const SelectOrderDateTimeSheet: FC<SheetProps> = ({ sheetId }) => {
  const { height } = useWindowDimensions();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<ScrollView>(sheetId, actionSheetRef);

  const {
    appStore: {
      selectedShopId,
      cartStates,
      shop,
    },
  } = useStore();

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;

  const [isAsap, setIsAsap] = useState<boolean>(cartState?.isAsap || false);
  const [selectedAsapTimeIndex, setSelectedAsapTimeIndex] = useState<number>(cartState?.selectedAsapTimeIndex ?? 0);
  const [deliveryDate, setDeliveryDate] = useState<string | null>(cartState?.date || null);
  const [time, setTime] = useState<string | null>(cartState?.time || null);

  useEffect(() => {
    if (cartState) {
      setIsAsap(cartState.isAsap);
    }
  }, [cartState, cartState?.isAsap]);

  useEffect(() => {
    if (cartState) {
      setSelectedAsapTimeIndex(cartState.selectedAsapTimeIndex ?? undefined);
    }
  }, [cartState, cartState?.selectedAsapTimeIndex]);

  useEffect(() => {
    if (cartState) {
      setDeliveryDate(cartState.date);
    }
  }, [cartState, cartState?.date]);

  useEffect(() => {
    if (cartState) {
      setTime(cartState.time);
    }
  }, [cartState, cartState?.time]);

  const onConfirm = useCallback(() => {
    if (cartState) {
      cartState.setIsAsap(isAsap);
      cartState.setDate(deliveryDate);
      cartState.setTime(time);
      cartState.setSelectedAsapTimeIndex(selectedAsapTimeIndex);
    }
    actionSheetRef.current?.hide();
  }, [cartState, isAsap, deliveryDate, time, selectedAsapTimeIndex]);

  const onTimeChange = useCallback((value: WheelTimePickerValue) => {
    setTime(`${value.hour}:${value.minutes}`);
  }, []);

  const currentShopWorkTime = shop?.workTime;

  const timeParts = cartState?.timeParts;
  const allTimeSlots = shop?.workTime.timeSlots;
  const timeSlots = (allTimeSlots && deliveryDate) ? allTimeSlots.get(deliveryDate) : undefined;

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled={false} // На вебе селект времени при скроле вниз закрывает модалку, поэтому отключены жесты
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        {(!cartState || !currentShopWorkTime) && (
          <ActivityIndicator color={colors.primary} />
        )}
        {(!!cartState && !!currentShopWorkTime) && (
          <>
            <ScrollView
              {...scrollHandlers}
              style={StyleSheet.flatten([s.scrollView, { maxHeight: height * 0.5 }])}
              alwaysBounceVertical={false}
            >
              <View style={s.contentRoot}>
                <View style={s.radioContainer}>
                  <Segmented
                    value={isAsap}
                    options={timeTypeOptions}
                    onChange={setIsAsap}
                  />
                </View>
                {!isAsap && (
                  <>
                    <DeliveryDayVariants
                      value={deliveryDate}
                      onChange={setDeliveryDate}
                    />
                    <View style={s.timePickerRoot}>
                      {timeSlots && timeSlots.size > 0 && (
                        <WheelTimePicker
                          timeSlots={timeSlots}
                          initialHours={timeParts?.hours as typeof HOURS[number] ?? undefined}
                          initialMinutes={timeParts?.minutes as typeof MINUTES[number] ?? undefined}
                          onChange={onTimeChange}
                        />
                      )}
                    </View>
                  </>
                )}
                {
                  isAsap && (
                    <>
                      {!!currentShopWorkTime.asapTimeOptions && currentShopWorkTime.asapTimeOptions.length > 0 && (
                        <View style={s.radioContainer}>
                          <Segmented
                            value={selectedAsapTimeIndex}
                            options={
                              currentShopWorkTime
                                .asapTimeOptions
                                .map((item) => ({
                                  value: item.index,
                                  title: item.title,
                                }))
                            }
                            onChange={setSelectedAsapTimeIndex}
                          />
                        </View>
                      )}
                      {(!currentShopWorkTime.asapTimeOptions || currentShopWorkTime.asapTimeOptions.length === 0) && (
                        <View style={s.radioContainer}>
                          <Text>Нет доступных слотов на сегодня</Text>
                        </View>
                      )}
                    </>
                  )
                }
              </View>
            </ScrollView>
            <Button
              onPress={onConfirm}
              title="Подтвердить"
            />
          </>
        )}
      </View>
    </ActionSheet>
  );
};

export default observer(SelectOrderDateTimeSheet);
