import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import GreyBlock from '@components/layout/GreyBlock';
import {
  View,
  Text,
  StyleSheet, Pressable,
} from 'react-native';
import useStore from '@app/domain/store/useStore';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import { SheetManager } from 'react-native-actions-sheet';

const s = StyleSheet.create({
  root: {},
  row: {
    flexDirection: 'row',
  },
  text: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    flex: 1,
    lineHeight: 20,
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
  right: {
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const DateSelector: FC = () => {
  const {
    appStore: {
      cartStates,
      selectedShopId,
      paymentTypes,
      shop,
    },
  } = useStore();

  const openPaymentTypeSelectorSheet = useCallback(() => {
    SheetManager.show('select-payment-type-sheet');
  }, []);

  const isSinglePaymentType = !!shop?.allowedPaymentTypes?.length && shop.allowedPaymentTypes.length === 1;

  if (!selectedShopId) {
    return null;
  }

  const cartState = cartStates.get(selectedShopId);

  if (!cartState) {
    return null;
  }

  const paymentType = cartState.paymentTypeId ? paymentTypes.get(cartState.paymentTypeId) : undefined;

  if (isSinglePaymentType && paymentType) {
    return (
      <GreyBlock style={s.root}>
        <Text style={s.title}>
          Оплата
        </Text>
        <Text
          style={s.text}
        >
          {paymentType.title}
        </Text>
      </GreyBlock>
    );
  }

  const isEmpty = !paymentType;

  return (
    <Pressable onPress={openPaymentTypeSelectorSheet}>
      <GreyBlock
        style={s.root}
        focusUserAttention={isEmpty}
      >
        <Text style={s.title}>
          Оплата
        </Text>
        <View style={s.row}>
          <Text style={s.text}>
            {!paymentType && 'Укажите способ оплаты'}
            {!!paymentType && paymentType.title}
          </Text>
          <View style={s.right}>
            <ArrowRightSvg
              color={colors.text}
            />
          </View>
        </View>
      </GreyBlock>
    </Pressable>
  );
};

export default observer(DateSelector);
